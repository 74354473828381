@font-face {
  font-family: 'CSIconFont';
  src:
    url('fonts/CSIconFont.ttf?9sxkck') format('truetype'),
    url('fonts/CSIconFont.woff?9sxkck') format('woff'),
    url('fonts/CSIconFont.svg?9sxkck#CSIconFont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="i"], [class*=" i"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'CSIconFont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ics-circle-info:before {
  content: "\e900";
}
.ics-clipboard-list:before {
  content: "\e901";
}
.ics-edit:before {
  content: "\e902";
}
.ics-eraser:before {
  content: "\e903";
}
.ics-grip-lines-vertical:before {
  content: "\e904";
}
.ics-grip-vertical:before {
  content: "\e905";
}
.ics-map-marked .path1:before {
  content: "\e906";
  color: rgb(0, 0, 0);
}
.ics-map-marked .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ics-map-marked .path3:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ics-map-marked .path4:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ics-map-marked .path5:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ics-map-marked .path6:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ics-marker:before {
  content: "\e90c";
}
.ics-plug:before {
  content: "\e90d";
}
.ics-receipt:before {
  content: "\e90e";
}
.ics-unlink:before {
  content: "\e90f";
}
.iArtboard-205:before {
  content: "\e910";
}
.iArtboard-223:before {
  content: "\e911";
}
.iArtboard-236:before {
  content: "\e912";
}
.iArtboard-237:before {
  content: "\e913";
}
.iArtboard-238:before {
  content: "\e914";
}
.iArtboard-239:before {
  content: "\e915";
}
.ics-accessible-icon:before {
  content: "\e916";
}
.ics-address-book:before {
  content: "\e917";
}
.ics-align-center:before {
  content: "\e918";
}
.ics-align-justify:before {
  content: "\e919";
}
.ics-align-left:before {
  content: "\e91a";
}
.ics-align-right:before {
  content: "\e91b";
}
.ics-android:before {
  content: "\e91c";
}
.ics-angle-double-down:before {
  content: "\e91d";
}
.ics-angle-double-left:before {
  content: "\e91e";
}
.ics-angle-double-right:before {
  content: "\e91f";
}
.ics-angle-double-up:before {
  content: "\e920";
}
.ics-angle-down:before {
  content: "\e921";
}
.ics-angle-left:before {
  content: "\e922";
}
.ics-angle-right:before {
  content: "\e923";
}
.ics-angle-up:before {
  content: "\e924";
}
.ics-apple:before {
  content: "\e925";
}
.ics-arrow-circle-left:before {
  content: "\e926";
}
.ics-arrow-circle-right:before {
  content: "\e927";
}
.ics-arrow-circle-up:before {
  content: "\e928";
}
.ics-arrow-down:before {
  content: "\e929";
}
.ics-arrow-downloft:before {
  content: "\e92a";
}
.ics-arrow-down-right:before {
  content: "\e92b";
}
.ics-arrow-left:before {
  content: "\e92c";
}
.ics-arrow-right:before {
  content: "\e92d";
}
.ics-arrow-up:before {
  content: "\e92e";
}
.ics-asterisk:before {
  content: "\e92f";
}
.ics-at:before {
  content: "\e930";
}
.ics-backward:before {
  content: "\e931";
}
.ics-ban:before {
  content: "\e932";
}
.ics-barcode:before {
  content: "\e933";
}
.ics-bars:before {
  content: "\e934";
}
.ics-bell:before {
  content: "\e935";
}
.ics-bell_1:before {
  content: "\e936";
}
.ics-bezier-curve:before {
  content: "\e937";
}
.ics-bin:before {
  content: "\e938";
}
.ics-bookmark:before {
  content: "\e939";
}
.ics-briefcase:before {
  content: "\e93a";
}
.ics-building-3:before {
  content: "\e93b";
}
.ics-building-4:before {
  content: "\e93c";
}
.ics-building-columns:before {
  content: "\e93d";
}
.ics-building-o:before {
  content: "\e93e";
}
.ics-calendar:before {
  content: "\e93f";
}
.ics-calendar-minus:before {
  content: "\e940";
}
.ics-calendar-plus:before {
  content: "\e941";
}
.ics-calendar-times:before {
  content: "\e942";
}
.ics-camera:before {
  content: "\e943";
}
.ics-cancel:before {
  content: "\e944";
}
.ics-caret-down:before {
  content: "\e945";
}
.ics-caret-down_1:before {
  content: "\e946";
}
.ics-caret-left:before {
  content: "\e947";
}
.ics-caret-right:before {
  content: "\e948";
}
.ics-caret-up:before {
  content: "\e949";
}
.ics-caret-up_1:before {
  content: "\e94a";
}
.ics-chalkboard-teacher:before {
  content: "\e94b";
}
.ics-chart-bar:before {
  content: "\e94c";
}
.ics-chart-line:before {
  content: "\e94d";
}
.ics-check:before {
  content: "\e94e";
}
.ics-check_1:before {
  content: "\e94f";
}
.ics-check-circle:before {
  content: "\e950";
}
.ics-check-square:before {
  content: "\e951";
}
.ics-chevron-circle-down:before {
  content: "\e952";
}
.ics-chevron-circle-left:before {
  content: "\e953";
}
.ics-chevron-circle-right:before {
  content: "\e954";
}
.ics-chevron-circle-up:before {
  content: "\e955";
}
.ics-chevron-down:before {
  content: "\e956";
}
.ics-chevron-left:before {
  content: "\e957";
}
.ics-chevron-right:before {
  content: "\e958";
}
.ics-chevron-up:before {
  content: "\e959";
}
.ics-circle:before {
  content: "\e95a";
}
.ics-circle-fill:before {
  content: "\e95b";
}
.ics-clock:before {
  content: "\e95c";
}
.ics-clone:before {
  content: "\e95d";
}
.ics-cloud:before {
  content: "\e95e";
}
.ics-cloud-download:before {
  content: "\e95f";
}
.ics-cloud-upload:before {
  content: "\e960";
}
.ics-cog:before {
  content: "\e961";
}
.ics-cogs:before {
  content: "\e962";
}
.ics-comment:before {
  content: "\e963";
}
.ics-comments:before {
  content: "\e964";
}
.ics-compass:before {
  content: "\e965";
}
.ics-contact:before {
  content: "\e966";
}
.ics-desktop:before {
  content: "\e967";
}
.ics-direction-line:before {
  content: "\e968";
}
.ics-directions:before {
  content: "\e969";
}
.ics-directions_1:before {
  content: "\e96a";
}
.ics-directions-alt:before {
  content: "\e96b";
}
.ics-dislike:before {
  content: "\e96c";
}
.ics-documents:before {
  content: "\e96d";
}
.ics-dollar:before {
  content: "\e96e";
}
.ics-download:before {
  content: "\e96f";
}
.ics-download_1:before {
  content: "\e970";
}
.ics-eject:before {
  content: "\e971";
}
.ics-ellipsis-h:before {
  content: "\e972";
}
.ics-ellipsis-v:before {
  content: "\e973";
}
.ics-envelope:before {
  content: "\e974";
}
.ics-exclamation-circle:before {
  content: "\e975";
}
.ics-exclamation-triangle:before {
  content: "\e976";
}
.ics-eye:before {
  content: "\e977";
}
.ics-eye-slash:before {
  content: "\e978";
}
.ics-fast-backward:before {
  content: "\e979";
}
.ics-fast-forward:before {
  content: "\e97a";
}
.ics-file:before {
  content: "\e97b";
}
.ics-file3:before {
  content: "\e97c";
}
.ics-file-excel:before {
  content: "\e97d";
}
.ics-file-invoice:before {
  content: "\e97e";
}
.ics-file-pdf:before {
  content: "\e97f";
}
.ics-filter:before {
  content: "\e980";
}
.ics-filter2:before {
  content: "\e981";
}
.ics-finance1:before {
  content: "\e982";
}
.ics-flag:before {
  content: "\e983";
}
.ics-folder:before {
  content: "\e984";
}
.ics-folder-open:before {
  content: "\e985";
}
.ics-forward:before {
  content: "\e986";
}
.ics-globe:before {
  content: "\e987";
}
.ics-google:before {
  content: "\e988";
}
.ics-grip-horizontal:before {
  content: "\e989";
}
.ics-grip-lines:before {
  content: "\e98a";
}
.ics-heart:before {
  content: "\e98b";
}
.ics-home:before {
  content: "\e98c";
}
.ics-hourglass:before {
  content: "\e98d";
}
.ics-icon:before {
  content: "\e98e";
}
.ics-icon2:before {
  content: "\e98f";
}
.ics-icon3:before {
  content: "\e990";
}
.ics-icon4:before {
  content: "\e991";
}
.ics-icon5:before {
  content: "\e992";
}
.ics-id-card:before {
  content: "\e993";
}
.ics-image:before {
  content: "\e994";
}
.ics-image_1:before {
  content: "\e995";
}
.ics-images:before {
  content: "\e996";
}
.ics-inbox:before {
  content: "\e997";
}
.ics-info:before {
  content: "\e998";
}
.ics-key:before {
  content: "\e999";
}
.ics-lightbulb:before {
  content: "\e99a";
}
.ics-like:before {
  content: "\e99b";
}
.ics-list:before {
  content: "\e99c";
}
.ics-list-check:before {
  content: "\e99d";
}
.ics-lock:before {
  content: "\e99e";
}
.ics-lock-open:before {
  content: "\e99f";
}
.ics-luggage-cart:before {
  content: "\e9a0";
}
.ics-map:before {
  content: "\e9a1";
}
.ics-map-marker .path1:before {
  content: "\e9a2";
  color: rgb(0, 0, 0);
}
.ics-map-marker .path2:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ics-map-marker .path3:before {
  content: "\e9a4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ics-maximum:before {
  content: "\e9a5";
}
.ics-minus-circle:before {
  content: "\e9a6";
}
.ics-mobile:before {
  content: "\e9a7";
}
.ics-money-bill:before {
  content: "\e9a8";
}
.ics-newspaper:before {
  content: "\e9a9";
}
.ics-palette:before {
  content: "\e9aa";
}
.ics-paperclip:before {
  content: "\e9ab";
}
.ics-pause:before {
  content: "\e9ac";
}
.ics-pencil:before {
  content: "\e9ad";
}
.ics-play:before {
  content: "\e9ae";
}
.ics-plus:before {
  content: "\e9af";
}
.ics-plus_1:before {
  content: "\e9b0";
}
.ics-power-off:before {
  content: "\e9b1";
}
.ics-print:before {
  content: "\e9b2";
}
.ics-qr:before {
  content: "\e9b3";
}
.ics-question:before {
  content: "\e9b4";
}
.ics-question_1:before {
  content: "\e9b5";
}
.ics-question-circle:before {
  content: "\e9b6";
}
.ics-readme:before {
  content: "\e9b7";
}
.ics-recycle:before {
  content: "\e9b8";
}
.ics-refresh:before {
  content: "\e9b9";
}
.ics-replay:before {
  content: "\e9ba";
}
.ics-rupee2:before {
  content: "\e9bb";
}
.ics-rupee2_1:before {
  content: "\e9bc";
}
.ics-save:before {
  content: "\e9bd";
}
.ics-screwdriver:before {
  content: "\e9be";
}
.ics-search:before {
  content: "\e9bf";
}
.ics-search-minus:before {
  content: "\e9c0";
}
.ics-search-plus:before {
  content: "\e9c1";
}
.ics-settings:before {
  content: "\e9c2";
}
.ics-share-alt:before {
  content: "\e9c3";
}
.ics-shopping-cart:before {
  content: "\e9c4";
}
.ics-sign-in:before {
  content: "\e9c5";
}
.ics-sign-out:before {
  content: "\e9c6";
}
.ics-sitemap:before {
  content: "\e9c7";
}
.ics-sliders:before {
  content: "\e9c8";
}
.ics-sliders-h:before {
  content: "\e9c9";
}
.ics-sort:before {
  content: "\e9ca";
}
.ics-sort-alpha-down:before {
  content: "\e9cb";
}
.ics-sort-alpha-down-alt:before {
  content: "\e9cc";
}
.ics-sort-alpha-upalt:before {
  content: "\e9cd";
}
.ics-sort-alpha-up-alt:before {
  content: "\e9ce";
}
.ics-sort-alt:before {
  content: "\e9cf";
}
.ics-sort-amount-down:before {
  content: "\e9d0";
}
.ics-sort-amount-down-alt:before {
  content: "\e9d1";
}
.ics-sort-amount-up:before {
  content: "\e9d2";
}
.ics-sort-amount-up-alt:before {
  content: "\e9d3";
}
.ics-sort-numeric-down:before {
  content: "\e9d4";
}
.ics-sort-numeric-down-alt:before {
  content: "\e9d5";
}
.ics-sort-numeric-up:before {
  content: "\e9d6";
}
.ics-sort-numeric-up-alt:before {
  content: "\e9d7";
}
.ics-spinner:before {
  content: "\e9d8";
}
.ics-star:before {
  content: "\e9d9";
}
.ics-step-backward:before {
  content: "\e9da";
}
.ics-step-backward_1:before {
  content: "\e9db";
}
.ics-step-forward:before {
  content: "\e9dc";
}
.ics-step-forward_1:before {
  content: "\e9dd";
}
.ics-stop-circle-o:before {
  content: "\e9de";
}
.ics-table:before {
  content: "\e9df";
}
.ics-tablet:before {
  content: "\e9e0";
}
.ics-tag:before {
  content: "\e9e1";
}
.ics-tags:before {
  content: "\e9e2";
}
.ics-tasks:before {
  content: "\e9e3";
}
.ics-th-large:before {
  content: "\e9e4";
}
.ics-ticket:before {
  content: "\e9e5";
}
.ics-times:before {
  content: "\e9e6";
}
.ics-times-circle:before {
  content: "\e9e7";
}
.ics-undo:before {
  content: "\e9e8";
}
.ics-unlock:before {
  content: "\e9e9";
}
.ics-upload:before {
  content: "\e9ea";
}
.ics-user:before {
  content: "\e9eb";
}
.ics-user-edit:before {
  content: "\e9ec";
}
.ics-user-minus:before {
  content: "\e9ed";
}
.ics-user-plus:before {
  content: "\e9ee";
}
.ics-users:before {
  content: "\e9ef";
}
.ics-video:before {
  content: "\e9f0";
}
.ics-volume-down:before {
  content: "\e9f1";
}
.ics-volume-off:before {
  content: "\e9f2";
}
.ics-volume-up:before {
  content: "\e9f3";
}
.ics-warehouse:before {
  content: "\e9f4";
}
.ics-warehouse2:before {
  content: "\e9f5";
}
.ics-wifi:before {
  content: "\e9f6";
}
.ics-window-maximize:before {
  content: "\e9f7";
}
.ics-window-minimize:before {
  content: "\e9f8";
}
.ics-windows:before {
  content: "\e9f9";
}
.ics-wpforms:before {
  content: "\e9fa";
}
.iuser-x-mark:before {
  content: "\e9fb";
}
