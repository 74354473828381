:root {
    --primary-color: hsl(210, 84%, 81%);
    --primary-light: hsl(200, 67%, 96%);
    --clr-text: hsl(209 50% 15%);
    --clr-heading: hsl(209 50% 25%);
    --primary-color-text: #ffffff;
    --surface-ground: #eff3f8;
    --surface-section: #ffffff;
    --surface-card: #ffffff;
    --surface-overlay: #ffffff;
    --surface-border: #dfe7ef;
    --surface-hover: #f6f9fc;
    --surface-0: #ffffff;
    --surface-50: #FAFAFA;
    --surface-100: #F5F5F5;
    --surface-200: #EEEEEE;
    --surface-300: #E0E0E0;
    --surface-400: #BDBDBD;
    --surface-500: #9E9E9E;
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-800: #424242;
    --surface-900: #212121;
    --blue-50: #f5f9ff;
    --blue-100: #d0e1fd;
    --blue-200: #abc9fb;
    --blue-300: #85b2f9;
    --blue-400: #609af8;
    --blue-500: #3b82f6;
    --blue-600: #326fd1;
    --blue-700: #295bac;
    --blue-800: #204887;
    --blue-900: #183462;
    --green-50: #f4fcf7;
    --green-100: #caf1d8;
    --green-200: #a0e6ba;
    --green-300: #76db9b;
    --green-400: #4cd07d;
    --green-500: #22c55e;
    --green-600: #1da750;
    --green-700: #188a42;
    --green-800: #136c34;
    --green-900: #0e4f26;
    --yellow-50: #fefbf3;
    --yellow-100: #faedc4;
    --yellow-200: #f6de95;
    --yellow-300: #f2d066;
    --yellow-400: #eec137;
    --yellow-500: #eab308;
    --yellow-600: #c79807;
    --yellow-700: #a47d06;
    --yellow-800: #816204;
    --yellow-900: #5e4803;
    --cyan-50: #f3fbfd;
    --cyan-100: #c3edf5;
    --cyan-200: #94e0ed;
    --cyan-300: #65d2e4;
    --cyan-400: #35c4dc;
    --cyan-500: #06b6d4;
    --cyan-600: #059bb4;
    --cyan-700: #047f94;
    --cyan-800: #036475;
    --cyan-900: #024955;
    --pink-50: #fef6fa;
    --pink-100: #fad3e7;
    --pink-200: #f7b0d3;
    --pink-300: #f38ec0;
    --pink-400: #f06bac;
    --pink-500: #ec4899;
    --pink-600: #c93d82;
    --pink-700: #a5326b;
    --pink-800: #822854;
    --pink-900: #5e1d3d;
    --indigo-50: #f7f7fe;
    --indigo-100: #dadafc;
    --indigo-200: #bcbdf9;
    --indigo-300: #9ea0f6;
    --indigo-400: #8183f4;
    --indigo-500: #6366f1;
    --indigo-600: #5457cd;
    --indigo-700: #4547a9;
    --indigo-800: #363885;
    --indigo-900: #282960;
    --teal-50: #f3fbfb;
    --teal-100: #c7eeea;
    --teal-200: #9ae0d9;
    --teal-300: #6dd3c8;
    --teal-400: #41c5b7;
    --teal-500: #14b8a6;
    --teal-600: #119c8d;
    --teal-700: #0e8174;
    --teal-800: #0b655b;
    --teal-900: #084a42;
    --orange-50: #fff8f3;
    --orange-100: #feddc7;
    --orange-200: #fcc39b;
    --orange-300: #fba86f;
    --orange-400: #fa8e42;
    --orange-500: #f97316;
    --orange-600: #d46213;
    --orange-700: #ae510f;
    --orange-800: #893f0c;
    --orange-900: #642e09;
    --bluegray-50: #f7f8f9;
    --bluegray-100: #dadee3;
    --bluegray-200: #bcc3cd;
    --bluegray-300: #9fa9b7;
    --bluegray-400: #818ea1;
    --bluegray-500: #64748b;
    --bluegray-600: #556376;
    --bluegray-700: #465161;
    --bluegray-800: #37404c;
    --bluegray-900: #282e38;
    --purple-50: #fbf7ff;
    --purple-100: #ead6fd;
    --purple-200: #dab6fc;
    --purple-300: #c996fa;
    --purple-400: #b975f9;
    --purple-500: #a855f7;
    --purple-600: #8f48d2;
    --purple-700: #763cad;
    --purple-800: #5c2f88;
    --purple-900: #432263;
    --red-50: #fff5f5;
    --red-100: #ffd0ce;
    --red-200: #ffaca7;
    --red-300: #ff8780;
    --red-400: #ff6259;
    --red-500: #ff3d32;
    --red-600: #d9342b;
    --red-700: #b32b23;
    --red-800: #8c221c;
    --red-900: #661814;
    --primary-50: #f5f9ff;
    --primary-100: #d0e1fd;
    --primary-200: #abc9fb;
    --primary-300: #85b2f9;
    --primary-400: #609af8;
    --primary-500: #3b82f6;
    --primary-600: #326fd1;
    --primary-700: #295bac;
    --primary-800: #204887;
    --primary-900: #183462;
    --gray-50: #FAFAFA;
    --gray-100: #F5F5F5;
    --gray-200: #EEEEEE;
    --gray-300: #E0E0E0;
    --gray-400: #BDBDBD;
    --gray-500: #9E9E9E;
    --gray-600: #757575;
    --gray-700: #616161;
    --gray-800: #424242;
    --gray-900: #212121;
}

.visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.color-picker>fieldset {
    border: 0;
    display: flex;
    gap: 2rem;
    width: fit-content;
    background: #fff;
    padding: 1rem 3rem;
    margin-inline: auto;
    border-radius: 0 0 1rem 1rem;
}

.color-picker input[type="radio"] {
    appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    outline: 3px solid var(--radio-color, currentColor);
    outline-offset: 3px;
    border-radius: 50%;
}

.color-picker input[type="radio"]:checked {
    background-color: var(--radio-color);
}

.color-picker input[type="radio"]#light {
    --radio-color: #008080;
}

.color-picker input[type="radio"]#teal {
    --radio-color: gray;
}

.color-picker input[type="radio"]#blue {
    --radio-color: rgba(18, 32, 233, 0.651);
}

.color-picker input[type="radio"]#green {
    --radio-color: green;
}

.color-picker input[type="radio"]#dark {
    --radio-color: #232323;
}


.teal {
    --primary-color: #dde2e0;
    --primary-light: #F1FBF7;
    --clr-text: #0f0f0f;
    --clr-heading: #141414;
}

.light {
    --primary-color: #CAF2E1;
    --primary-light: #F1FBF7;
    --clr-text: #008080;
    --clr-heading: #4F7E6A;
}

.blue {
    --primary-color: hsl(210, 84%, 81%);
    --primary-light: hsl(200, 67%, 96%);
    --clr-text: hsl(209 50% 15%);
    --clr-heading: hsl(209 50% 25%);
}

.green {
    --primary-color: #BDF5BD;
    --primary-light: #f0fff0;
    --clr-text: hsl(109 50% 15%);
    --clr-heading: hsl(109 50% 25%);
}

.dark {
    --primary-color: hsl(216, 7%, 13%);
    --primary-light: hsl(220, 2%, 30%);
    --clr-text: hsl(210, 17%, 98%);
    --clr-heading: hsl(0, 0%, 97%);
}

:root:has(#teal:checked) {
    --primary-color: #dde2e0;
    --primary-light: #F1FBF7;
    --clr-text: #0f0f0f;
    --clr-heading: #141414;
}

:root:has(#light:checked) {
    --primary-color: #CAF2E1;
    --primary-light: #F1FBF7;
    --clr-text: #008080;
    --clr-heading: #4F7E6A;
}

:root:has(#blue:checked) {
    --primary-color: hsl(210, 84%, 81%);
    --primary-light: hsl(200, 67%, 96%);
    --clr-text: hsl(209 50% 15%);
    --clr-heading: hsl(209 50% 25%);
}

:root:has(#green:checked) {
    --primary-color: #BDF5BD;
    --primary-light: #f0fff0;
    --clr-text: hsl(109 50% 15%);
    --clr-heading: hsl(109 50% 25%);
}

:root:has(#dark:checked) {
    --primary-color: hsl(216, 7%, 13%);
    --primary-light: hsl(220, 2%, 30%);
    --clr-text: hsl(210, 17%, 98%);
    --clr-heading: hsl(0, 0%, 97%);
}

* {
    box-sizing: border-box;
}

.bg-primary-text {
    background-color: var(--clr-heading);
}

.p-component {
    font-size: 12.5px;
    font-weight: normal;
}

.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    transition-duration: 0.15s;
    color: var(--primary-color);
}

.p-disabled,
.p-component:disabled {
    opacity: 0.65;
}

.p-error {
    color: #f44336;
}

.p-text-secondary {
    color: #6c757d;
}

.pi {}

.p-icon {
    width: 13px;
    height: 13px;
}

.p-link {
    border-radius: 4px;
}

.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--primary-color);
}

@keyframes p-component-overlay-enter-animation {
    from {
        background-color: rgba(0, 0, 0, 0);
    }

    to {
        background-color: var(--maskbg);
    }
}

@keyframes p-component-overlay-leave-animation {
    from {
        background-color: var(--maskbg);
    }

    to {
        background-color: rgba(0, 0, 0, 0);
    }
}

.p-autocomplete .p-autocomplete-loader {
    right: 0.75rem;
}

.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 3.107rem;
}

.p-autocomplete .p-autocomplete-multiple-container {
    padding: 0.25rem 0.75rem;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
    border-color: #ced4da;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--clr-heading);
    border-color: var(--primary-color);
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: 0.25rem 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {

    color: var(--clr-text);
    padding: 0;
    margin: 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    background: var(--primary-color);
    color: var(--clr-text);
    border-radius: 4px;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
    margin-left: 0.5rem;
}

.p-autocomplete.p-invalid.p-component>.p-inputtext {
    border-color: #f44336;
}

.p-autocomplete-panel {
    background: #fff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: none;
}

.p-autocomplete-panel .p-autocomplete-items {
    padding: 0.5rem 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    margin: 0;
    padding: 0.5rem 1.5rem;
    border: 0 none;
    color: #212529;
    background: rgba(0, 0, 0, 0);
    transition: box-shadow 0.15s;
    border-radius: 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
    color: #212529;
    background: #e9ecef;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    color: #fff;
    background: var(--primary-color);
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #212529;
    background: #fff;
    font-weight: 600;
}

.p-calendar.p-invalid.p-component>.p-inputtext {
    border-color: #f44336;
}

.p-datepicker {
    padding: 0;
    background: #fff;
    color: #212529;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.p-datepicker:not(.p-datepicker-inline) {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: var(--primary-color);
    position: relative;
    padding: .1em !important;
}

.p-datepicker .p-datepicker-header {
    padding: 0.5rem;
    color: #212529;
    background: #fff;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #dee2e6;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: box-shadow 0.15s;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: #495057;
    border-color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--primary-color);
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 2rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: #212529;
    transition: box-shadow 0.15s;
    font-weight: 600;
    padding: 0.5rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
    color: var(--clr-text);
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0.5rem;
}

.p-datepicker table {
    font-size: 12.5px;
    margin: 0.5rem 0;
}

.p-datepicker table th {
    padding: 0.5rem;
}

.p-datepicker table th>span {
    width: 2.5rem;
    height: 2.5rem;
}

.p-datepicker table td {
    padding: 0.5rem;
}

.p-datepicker table td>span {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 4px;
    transition: box-shadow 0.15s;
    border: 1px solid rgba(0, 0, 0, 0);
}

.p-datepicker table td>span.p-highlight {
    color: var(--clr-text);
    background: var(--primary-color);
}

.p-datepicker table td>span:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--primary-color);
}

.p-datepicker table td.p-datepicker-today>span {
    background: var(--primary-color);
    color: #212529;
    border-color: rgba(0, 0, 0, 0);
}

.p-datepicker table td.p-datepicker-today>span.p-highlight {
    color: var(--clr-heading);
    font-weight: 900;
    /* background: var(--clr-heading); */
}

.p-datepicker .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid #dee2e6;
}

.p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto;
}

.p-datepicker .p-timepicker {
    border-top: 1px solid #dee2e6;
    padding: 0.5rem;
}

.p-datepicker .p-timepicker button {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: box-shadow 0.15s;
}

.p-datepicker .p-timepicker button:enabled:hover {
    color: #495057;
    border-color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
}

.p-datepicker .p-timepicker button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--primary-color);
}

.p-datepicker .p-timepicker button:last-child {
    margin-top: 0.2em;
}

.p-datepicker .p-timepicker span {
    font-size: 1.25rem;
}

.p-datepicker .p-timepicker>div {
    padding: 0 0.5rem;
}

.p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none;
}

.p-datepicker .p-monthpicker {
    margin: 0.5rem 0;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: 0.5rem;
    transition: box-shadow 0.15s;
    border-radius: 4px;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: var(--clr-text);
    background: var(--primary-color);
}

.p-datepicker .p-yearpicker {
    margin: 0.5rem 0;
}

.p-datepicker .p-yearpicker .p-yearpicker-year {
    padding: 0.5rem;
    transition: box-shadow 0.15s;
    border-radius: 4px;
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: var(--clr-text);
    background: var(--primary-color);
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-left: 1px solid #dee2e6;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    padding-left: 0;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    padding-right: 0;
    border-left: 0 none;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: #e9ecef;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--primary-color);
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--primary-color);
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--primary-color);
}

@media screen and (max-width: 769px) {

    .p-datepicker table th,
    .p-datepicker table td {
        padding: 0;
    }
}

.p-cascadeselect {
    background: #fff;
    border: 1px solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
}

.p-cascadeselect:not(.p-disabled):hover {
    border-color: #ced4da;
}

.p-cascadeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    /* box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); */
    border-color: var(--primary-color);
}

.p-cascadeselect .p-cascadeselect-label {
    background: rgba(0, 0, 0, 0);
    border: 0 none;
    padding: 0.5rem 0.75rem;
}

.p-cascadeselect .p-cascadeselect-label.p-placeholder {
    color: #6c757d;
}

.p-cascadeselect .p-cascadeselect-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
}

.p-cascadeselect .p-cascadeselect-trigger {
    background: rgba(0, 0, 0, 0);
    color: #495057;
    width: 2.357rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.p-cascadeselect.p-invalid.p-component {
    border-color: #f44336;
}

.p-cascadeselect-panel {
    background: #fff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: none;
}

.p-cascadeselect-panel .p-cascadeselect-items {
    padding: 0.5rem 0;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
    margin: 0;
    border: 0 none;
    color: #212529;
    background: rgba(0, 0, 0, 0);
    transition: box-shadow 0.15s;
    border-radius: 0;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
    padding: 0.5rem 1.5rem;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
    color: #fff;
    background: var(--primary-color);
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #212529;
    background: #e9ecef;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
    font-size: 0.875rem;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
}

.p-input-filled .p-cascadeselect {
    background: #efefef;
}

.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
    background-color: #efefef;
}

.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
    background-color: #efefef;
}

.p-checkbox {
    width: 20px;
    height: 20px;
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #ced4da;
    background: #fff;
    width: 20px;
    height: 20px;
    color: #212529;
    border-radius: 4px;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.15s;
    color: #fff;
    font-size: 14px;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
    width: 14px;
    height: 14px;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--clr-text);
    background: var(--clr-text);
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: var(--clr-text);
    background: var(--clr-text);
    color: #fff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #ced4da;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--clr-text);
    ;
    border-color: var(--clr-text);
    ;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--clr-text);
    background: var(--clr-text);
    color: #fff;
}

.p-checkbox.p-invalid>.p-checkbox-box {
    border-color: #f44336;
}

.p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #efefef;
}

.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: var(--clr-text);
    ;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #efefef;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #0062cc;
}

.p-chips .p-chips-multiple-container {
    padding: 0.25rem 0.75rem;
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
    border-color: #ced4da;
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    border-color: var(--primary-color);
}

.p-chips .p-chips-multiple-container .p-chips-token {
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    background: #dee2e6;
    color: #212529;
    border-radius: 16px;
}

.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: 0.25rem 0;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {

    /* font-size: 1rem; */
    color: #212529;
    padding: 0;
    margin: 0;
}

.p-chips.p-invalid.p-component>.p-inputtext {
    border-color: #f44336;
}

.p-colorpicker-preview {
    width: 2rem;
    height: 2rem;
}

.p-colorpicker-panel {
    background: #212529;
    border: 1px solid #212529;
}

.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
    border-color: #fff;
}

.p-colorpicker-overlay-panel {
    box-shadow: none;
}

.p-dropdown {
    background: #fff;
    border: 1px solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: #ced4da;
}

.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--primary-color);
    border-color: var(--primary-color);
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.75rem;
}

.p-dropdown .p-dropdown-label {
    background: rgba(0, 0, 0, 0);
    border: 0 none;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: #6c757d;
}

.p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
}

.p-dropdown .p-dropdown-trigger {
    background: rgba(0, 0, 0, 0);
    color: #495057;
    width: 2.357rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.p-dropdown .p-dropdown-clear-icon {
    color: #495057;
    right: 2.357rem;
}

.p-dropdown.p-invalid.p-component {
    border-color: #f44336;
}

.p-dropdown-panel {
    background: #fff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: none;
}

.p-dropdown-panel .p-dropdown-header {
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
    background: #efefef;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.75rem;
    margin-right: -1.75rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.75rem;
    color: #495057;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter {
    padding-right: 3.5rem;
    margin-right: -3.5rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter-clear-icon {
    right: 2.5rem;
}

.p-dropdown-panel .p-dropdown-items {
    padding: 0.5rem 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.5rem 1.5rem;
    border: 0 none;
    color: var(--clr-text);
    background: var(--primary-color-text);
    transition: box-shadow 0.15s;
    border-radius: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: var(--clr-heading);
    background: var(--primary-color);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: var(--clr-text);
    background: var(--primary-color);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #212529;
    background: #fff;
    font-weight: 600;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: 0.5rem 1.5rem;
    color: #212529;
    background: rgba(0, 0, 0, 0);
}

.p-input-filled .p-dropdown {
    background: #efefef;
}

.p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: #efefef;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    background-color: #efefef;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
    background-color: rgba(0, 0, 0, 0);
}

.p-editor-container .p-editor-toolbar {
    background: #efefef;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-editor-container .p-editor-toolbar.ql-snow {
    border: 1px solid #dee2e6;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
    stroke: #6c757d;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
    fill: #6c757d;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
    border: 0 none;
    color: #6c757d;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
    color: #212529;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
    stroke: #212529;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
    fill: #212529;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #212529;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: #212529;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: #212529;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
    border-radius: 4px;
    padding: 0.5rem 0;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
    color: #212529;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
    color: #212529;
    background: #e9ecef;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
    padding: 0.5rem 1.5rem;
}

.p-editor-container .p-editor-content {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-editor-container .p-editor-content.ql-snow {
    border: 1px solid #dee2e6;
}

.p-editor-container .p-editor-content .ql-editor {
    background: #fff;
    color: #495057;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
    color: #212529;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: #212529;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
    fill: #212529;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
    color: var(--primary-color);
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
    stroke: var(--primary-color);
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
    fill: var(--primary-color);
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
    color: var(--primary-color);
}

.p-inputgroup-addon {
    background: var(--primary-color);
    color: var(--clr-heading);
    border-top: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    padding: 0.5rem 0.75rem;
    min-width: 2.357rem;
}

.p-inputgroup-addon:last-child {
    border-right: 1px solid #ced4da;
}

.p-inputgroup>.p-component,
.p-inputgroup>.p-inputwrapper>.p-inputtext,
.p-inputgroup>.p-float-label>.p-component {
    border-radius: 0;
    margin: 0;
}

.p-inputgroup>.p-component+.p-inputgroup-addon,
.p-inputgroup>.p-inputwrapper>.p-inputtext+.p-inputgroup-addon,
.p-inputgroup>.p-float-label>.p-component+.p-inputgroup-addon {
    border-left: 0 none;
}

.p-inputgroup>.p-component:focus,
.p-inputgroup>.p-inputwrapper>.p-inputtext:focus,
.p-inputgroup>.p-float-label>.p-component:focus {
    z-index: 1;
}

.p-inputgroup>.p-component:focus~label,
.p-inputgroup>.p-inputwrapper>.p-inputtext:focus~label,
.p-inputgroup>.p-float-label>.p-component:focus~label {
    z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup>.p-inputwrapper:first-child,
.p-inputgroup>.p-inputwrapper:first-child>.p-inputtext {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup>.p-inputwrapper:last-child,
.p-inputgroup>.p-inputwrapper:last-child>.p-inputtext {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.p-fluid .p-inputgroup .p-button {
    width: auto;
}

.p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 2.357rem;
}

.p-inputnumber.p-invalid.p-component>.p-inputtext {
    border-color: #f44336;
}

.p-inputswitch {
    width: 3rem;
    height: 1.75rem;
}

.p-inputswitch .p-inputswitch-slider {
    background: #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
}

.p-inputswitch .p-inputswitch-slider:before {
    background: #fff;
    width: 1.25rem;
    height: 1.25rem;
    left: 0.25rem;
    margin-top: -0.625rem;
    border-radius: 4px;
    transition-duration: 0.15s;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.25rem);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #ced4da;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: var(--primary-color);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #fff;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: var(--primary-color);
}

.p-inputswitch.p-invalid .p-inputswitch-slider {
    border-color: #f44336;
}

.p-inputtext {
    font-size: 12.5px;
    color: #495057;
    background: #fff;
    padding: 0.5rem 0.75rem;
    border: 1px solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    appearance: none;
    border-radius: 4px;
    font-family: "Open Sans", Helvetica Neue, sans-serif;
    width: 100%;
}

.p-inputtext:enabled:hover {
    border-color: #ced4da;
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    border-color: var(--primary-color);
}

.p-inputtext.p-invalid.p-component {
    border-color: #f44336;
}

.p-inputtext.p-inputtext-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.65625rem;
}

.p-inputtext.p-inputtext-lg {
    /* font-size: 1.25rem; */
    padding: 0.625rem 0.9375rem;
}

.p-float-label>label {
    left: 0.75rem;
    width: 100%;
    color: #6c757d;
    transition-duration: 0.15s;
}

.p-float-label>label.p-error {
    color: #f44336;
}

.p-input-icon-left>i:first-of-type,
.p-input-icon-left>svg:first-of-type,
.p-input-icon-left>.p-input-prefix {
    left: 0.75rem;
    color: #495057;
}

.p-input-icon-left>.p-inputtext {
    padding-left: 2.5rem;
}

.p-input-icon-left.p-float-label>label {
    left: 2.5rem;
}

.p-input-icon-right>i:last-of-type,
.p-input-icon-right>svg:last-of-type,
.p-input-icon-right>.p-input-suffix {
    right: 0.75rem;
    color: #495057;
}

.p-input-icon-right>.p-inputtext {
    padding-right: 2.5rem;
}

::-webkit-input-placeholder {
    color: #6c757d;
}

:-moz-placeholder {
    color: #6c757d;
}

::-moz-placeholder {
    color: #6c757d;
}

:-ms-input-placeholder {
    color: #6c757d;
}

.p-input-filled .p-inputtext {
    background-color: #efefef;
}

.p-input-filled .p-inputtext:enabled:hover {
    background-color: #efefef;
}

.p-input-filled .p-inputtext:enabled:focus {
    background-color: #efefef;
}

.p-inputtext-sm .p-inputtext {
    font-size: 0.875rem;
    padding: 0.4375rem 0.65625rem;
}

.p-inputtext-lg .p-inputtext {
    font-size: 1.25rem;
    padding: 0.625rem 0.9375rem;
}

.p-listbox {
    background: #fff;
    color: #212529;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.p-listbox .p-listbox-header {
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
    background: #efefef;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-listbox .p-listbox-header .p-listbox-filter {
    padding-right: 1.75rem;
}

.p-listbox .p-listbox-header .p-listbox-filter-icon {
    right: 0.75rem;
    color: #495057;
}

.p-listbox .p-listbox-list {
    padding: 0.5rem 0;
}

.p-listbox .p-listbox-list .p-listbox-item {
    margin: 0;
    padding: 0.5rem 1.5rem;
    border: 0 none;
    color: #212529;
    transition: box-shadow 0.15s;
    border-radius: 0;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: #fff;
    background: var(--primary-color);
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-listbox .p-listbox-list .p-listbox-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #212529;
    background: #fff;
    font-weight: 600;
}

.p-listbox .p-listbox-list .p-listbox-empty-message {
    padding: 0.5rem 1.5rem;
    color: #212529;
    background: rgba(0, 0, 0, 0);
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: #212529;
    background: #e9ecef;
}

.p-listbox.p-invalid {
    border-color: #f44336;
}

.p-mention-panel {
    background: #fff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: none;
}

.p-mention-panel .p-mention-items {
    padding: 0.5rem 0;
}

.p-mention-panel .p-mention-items .p-mention-item {
    margin: 0;
    padding: 0.5rem 1.5rem;
    border: 0 none;
    color: #212529;
    background: rgba(0, 0, 0, 0);
    transition: box-shadow 0.15s;
    border-radius: 0;
}

.p-mention-panel .p-mention-items .p-mention-item:hover {
    color: #212529;
    background: #e9ecef;
}

.p-mention-panel .p-mention-items .p-mention-item.p-highlight {
    color: #fff;
    background: var(--primary-color);
}

.p-multiselect {
    background: #fff;
    border: 1px solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
}

.p-multiselect:not(.p-disabled):hover {
    border-color: #ced4da;
}

.p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    border-color: var(--primary-color);
}

.p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.75rem;
}

.p-multiselect .p-multiselect-label {
    padding: 0.5rem 0.75rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-multiselect .p-multiselect-label.p-placeholder {
    color: #6c757d;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    background: #dee2e6;
    color: #212529;
    border-radius: 16px;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    margin-left: 0.5rem;
}

.p-multiselect .p-multiselect-trigger {
    background: rgba(0, 0, 0, 0);
    color: #495057;
    width: 2.357rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.p-multiselect .p-multiselect-clear-icon {
    color: #495057;
    right: 2.357rem;
}

.p-multiselect.p-invalid.p-component {
    border-color: #f44336;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 0.25rem 0.75rem;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label.p-multiselect-items-label {
    padding: 0.5rem 0.75rem;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.75rem;
}

.p-multiselect-panel {
    background: #fff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: none;
}

.p-multiselect-panel .p-multiselect-header {
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
    background: var(--primary-color);
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
    margin: 0 0.5rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.75rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    right: 0.75rem;
    color: #495057;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: box-shadow 0.15s;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: #495057;
    border-color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-multiselect-panel .p-multiselect-items {
    padding: 0.5rem 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.5rem 1.5rem;
    border: 0 none;
    color: #212529;
    background: rgba(0, 0, 0, 0);
    transition: box-shadow 0.15s;
    border-radius: 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: var(--clr-text);
    background: var(--primary-color);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #212529;
    background: #e9ecef;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #212529;
    background: #fff;
    font-weight: 600;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    padding: 0.5rem 1.5rem;
    color: #212529;
    background: rgba(0, 0, 0, 0);
}

.p-input-filled .p-multiselect {
    background: #efefef;
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: #efefef;
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: #efefef;
}

.p-password.p-invalid.p-component>.p-inputtext {
    border-color: #f44336;
}

.p-password-panel {
    padding: 1.25rem;
    background: #fff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: none;
    border-radius: 4px;
}

.p-password-panel .p-password-meter {
    margin-bottom: 0.5rem;
    background: #e9ecef;
}

.p-password-panel .p-password-meter .p-password-strength.weak {
    background: #dc3545;
}

.p-password-panel .p-password-meter .p-password-strength.medium {
    background: #ffc107;
}

.p-password-panel .p-password-meter .p-password-strength.strong {
    background: #28a745;
}

.p-radiobutton {
    width: 20px;
    height: 20px;
}

.p-radiobutton .p-radiobutton-box {
    border: 2px solid var(--clr-text);
    background: #fff;
    width: 20px;
    height: 20px;
    color: #212529;
    border-radius: 50%;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: var(--clr-heading);
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--clr-text);
    border-color: var(--clr-text);
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 12px;
    height: 12px;
    transition-duration: 0.15s;
    background-color: #fff;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: var(--primary-color);
    background: var(--clr-text);
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: var(--clr-text);
    background: var(--clr-text);
    color: #fff;
}

.p-radiobutton.p-invalid>.p-radiobutton-box {
    border-color: #f44336;
}

.p-radiobutton:focus {
    outline: 0 none;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
    background-color: #efefef;
}

.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: #efefef;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: var(--primary-color);
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: #0062cc;
}

.p-highlight .p-radiobutton .p-radiobutton-box {
    border-color: #fff;
}

.p-rating {
    gap: 0.5rem;
}

.p-rating .p-rating-item .p-rating-icon {
    color: #495057;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    font-size: 1.143rem;
}

.p-rating .p-rating-item .p-rating-icon.p-icon {
    width: 1.143rem;
    height: 1.143rem;
}

.p-rating .p-rating-item .p-rating-icon.p-rating-cancel {
    color: #dc3545;
}

.p-rating .p-rating-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
    color: var(--primary-color);
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
    color: var(--primary-color);
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon.p-rating-cancel {
    color: #dc3545;
}

.p-highlight .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
    color: #fff;
}

.p-selectbutton .p-button {
    background: var(--primary-color);
    /* border: 1px solid #6c757d; */
    color: var(--clr-text);
    transition: background-color 0.15s, border-color 0.15s;
}

.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
    color: #fff;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: var(--clr-heading);
    border-color: var(--clr-heading);
    color: #fff;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #fff;
}

.p-selectbutton .p-button.p-highlight {
    background: var(--clr-text);
    /* border-color: #4e555b; */
    color: #fff;
}

.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: #fff;
}

.p-selectbutton .p-button.p-highlight:hover {
    background: var(--clr-heading);
    /* border-color: #4e555b; */
    color: #fff;
}

.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: #fff;
}

.p-selectbutton.p-invalid>.p-button {
    border-color: #f44336;
}

.p-slider {
    background: #e9ecef;
    border: 0 none;
    border-radius: 4px;
}

.p-slider.p-slider-horizontal {
    height: 0.286rem;
}

.p-slider.p-slider-horizontal .p-slider-handle {
    margin-top: -0.5715rem;
    margin-left: -0.5715rem;
}

.p-slider.p-slider-vertical {
    width: 0.286rem;
}

.p-slider.p-slider-vertical .p-slider-handle {
    margin-left: -0.5715rem;
    margin-bottom: -0.5715rem;
}

.p-slider .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: 4px;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-slider .p-slider-handle:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-slider .p-slider-range {
    background: var(--primary-color);
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: #0069d9;
    border-color: #0069d9;
}

.p-treeselect {
    background: #fff;
    border: 1px solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
}

.p-treeselect:not(.p-disabled):hover {
    border-color: #ced4da;
}

.p-treeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    border-color: var(--primary-color);
}

.p-treeselect .p-treeselect-label {
    padding: 0.5rem 0.75rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-treeselect .p-treeselect-label.p-placeholder {
    color: #6c757d;
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    background: #dee2e6;
    color: #212529;
    border-radius: 16px;
}

.p-treeselect .p-treeselect-trigger {
    background: rgba(0, 0, 0, 0);
    color: #495057;
    width: 2.357rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.p-treeselect.p-invalid.p-component {
    border-color: #f44336;
}

.p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label {
    padding: 0.25rem 0.75rem;
}

.p-treeselect-panel {
    background: #fff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: none;
}

.p-treeselect-panel .p-treeselect-header {
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
    background: #efefef;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
    margin-right: 0.5rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
    padding-right: 1.75rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
    right: 0.75rem;
    color: #495057;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
    padding-right: 3.5rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
    right: 2.5rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: box-shadow 0.15s;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
    color: #495057;
    border-color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
    border: 0 none;
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
    padding: 0.5rem 1.5rem;
    color: #212529;
    background: rgba(0, 0, 0, 0);
}

.p-input-filled .p-treeselect {
    background: #efefef;
}

.p-input-filled .p-treeselect:not(.p-disabled):hover {
    background-color: #efefef;
}

.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
    background-color: #efefef;
}

.p-togglebutton.p-button {
    background: #6c757d;
    border: 1px solid #6c757d;
    color: #fff;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
    color: #fff;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #5a6268;
    border-color: #545b62;
    color: #fff;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #fff;
}

.p-togglebutton.p-button.p-highlight {
    background: #545b62;
    border-color: #4e555b;
    color: #fff;
}

.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: #fff;
}

.p-togglebutton.p-button.p-highlight:hover {
    background: #545b62;
    border-color: #4e555b;
    color: #fff;
}

.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
    color: #fff;
}

.p-togglebutton.p-button.p-invalid>.p-button {
    border-color: #f44336;
}

.p-button {
    color: var(--clr-text);
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 8px;
    /* font-size: 13px; */
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
}

.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
    background: var(--clr-text);
    color: #fff;
    /* border-color: #0069d9; */
}

.p-button:enabled:active,
.p-button:not(button):not(a):not(.p-disabled):active {
    background: var(--primary-color);
    color: #fff;
    border-color: var(--primary-color);
}

.p-button.p-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: var(--primary-color);
    border: 1px solid;
}

.p-button.p-button-outlined:enabled:hover,
.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(0, 123, 255, 0.04);
    color: var(--primary-color);
    border: 1px solid;
}

.p-button.p-button-outlined:enabled:active,
.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(0, 123, 255, 0.16);
    color: var(--primary-color);
    border: 1px solid;
}

.p-button.p-button-outlined.p-button-plain {
    color: #6c757d;
    border-color: #6c757d;
}

.p-button.p-button-outlined.p-button-plain:enabled:hover,
.p-button.p-button-outlined.p-button-plain:not(button):not(a):not(.p-disabled):hover {
    background: #e9ecef;
    color: #6c757d;
}

.p-button.p-button-outlined.p-button-plain:enabled:active,
.p-button.p-button-outlined.p-button-plain:not(button):not(a):not(.p-disabled):active {
    background: #dee2e6;
    color: #6c757d;
}

.p-button.p-button-text {
    background-color: rgba(0, 0, 0, 0);
    color: var(--primary-color);
    border-color: rgba(0, 0, 0, 0);
}

.p-button.p-button-text:enabled:hover,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(0, 123, 255, 0.04);
    color: var(--primary-color);
    border-color: rgba(0, 0, 0, 0);
}

.p-button.p-button-text:enabled:active,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(0, 123, 255, 0.16);
    color: var(--primary-color);
    border-color: rgba(0, 0, 0, 0);
}

.p-button.p-button-text.p-button-plain {
    color: #6c757d;
}

.p-button.p-button-text.p-button-plain:enabled:hover,
.p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):hover {
    background: #e9ecef;
    color: #6c757d;
}

.p-button.p-button-text.p-button-plain:enabled:active,
.p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):active {
    background: #dee2e6;
    color: #6c757d;
}

.p-button:focus {
    background: var(--primary-color);
    outline: 0 none;
    outline-offset: 0;
    /* box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); */
}

.p-button .p-button-label {
    transition-duration: 0.15s;
}

.p-button .p-button-icon-left {
    margin-right: 0.5rem;
}

.p-button .p-button-icon-right {
    margin-left: 0.5rem;
}

.p-button .p-button-icon-bottom {
    margin-top: 0.5rem;
}

.p-button .p-button-icon-top {
    margin-bottom: 0.5rem;
}

.p-button .p-badge {
    margin-left: 0.5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: var(--primary-color);
    background-color: #fff;
}

.p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-button.p-button-rounded {
    border-radius: 2rem;
}

.p-button.p-button-icon-only {
    width: 2rem;
    height: 2rem;
    font-size: 13.2px;
    margin: 2px;
    /* font-weight: 300; */
    /* padding: 0.75rem ; */
}

.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
    margin: 0;
}

.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2.357rem;
}

.p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.65625rem;
}

.p-button.p-button-sm .p-button-icon {
    font-size: 0.875rem;
}

.p-button.p-button-lg {
    font-size: 1.25rem;
    padding: 0.625rem 0.9375rem;
}

.p-button.p-button-lg .p-button-icon {
    font-size: 1.25rem;
}

.p-button.p-button-loading-label-only.p-button-loading-left .p-button-label {
    margin-left: 0.5rem;
}

.p-button.p-button-loading-label-only.p-button-loading-right .p-button-label {
    margin-right: 0.5rem;
}

.p-button.p-button-loading-label-only.p-button-loading-top .p-button-label {
    margin-top: 0.5rem;
}

.p-button.p-button-loading-label-only.p-button-loading-bottom .p-button-label {
    margin-bottom: 0.5rem;
}

.p-button.p-button-loading-label-only .p-button-loading-icon {
    margin: 0;
}

.p-fluid .p-button {
    width: 100%;
}

.p-fluid .p-button-icon-only {
    width: 2.357rem;
}

.p-fluid .p-buttonset {
    display: flex;
}

.p-fluid .p-buttonset .p-button {
    flex: 1;
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary>.p-button,
.p-splitbutton.p-button-secondary>.p-button,
.p-fileupload-choose.p-button-secondary {
    color: #fff;
    background: #6c757d;
    border: 1px solid #6c757d;
}

.p-button.p-button-secondary:enabled:hover,
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary>.p-button:enabled:hover,
.p-buttonset.p-button-secondary>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary>.p-button:enabled:hover,
.p-splitbutton.p-button-secondary>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary:enabled:hover,
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):hover {
    background: #616971;
    color: #fff;
    border-color: #616971;
}

.p-button.p-button-secondary:enabled:focus,
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-secondary>.p-button:enabled:focus,
.p-buttonset.p-button-secondary>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-secondary>.p-button:enabled:focus,
.p-splitbutton.p-button-secondary>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-secondary:enabled:focus,
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #c4c8cc;
}

.p-button.p-button-secondary:enabled:active,
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary>.p-button:enabled:active,
.p-buttonset.p-button-secondary>.p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary>.p-button:enabled:active,
.p-splitbutton.p-button-secondary>.p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary:enabled:active,
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):active {
    background: #565e64;
    color: #fff;
    border-color: #565e64;
}

.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-secondary.p-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #6c757d;
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(108, 117, 125, 0.04);
    color: #6c757d;
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:enabled:active,
.p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(108, 117, 125, 0.16);
    color: #6c757d;
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary>.p-button.p-button-text,
.p-splitbutton.p-button-secondary>.p-button.p-button-text,
.p-fileupload-choose.p-button-secondary.p-button-text {
    background-color: rgba(0, 0, 0, 0);
    color: #6c757d;
    border-color: rgba(0, 0, 0, 0);
}

.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary>.p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-secondary>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(108, 117, 125, 0.04);
    border-color: rgba(0, 0, 0, 0);
    color: #6c757d;
}

.p-button.p-button-secondary.p-button-text:enabled:active,
.p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary>.p-button.p-button-text:enabled:active,
.p-buttonset.p-button-secondary>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary>.p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-secondary>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-text:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(108, 117, 125, 0.16);
    border-color: rgba(0, 0, 0, 0);
    color: #6c757d;
}

.p-button.p-button-info,
.p-buttonset.p-button-info>.p-button,
.p-splitbutton.p-button-info>.p-button,
.p-fileupload-choose.p-button-info {
    color: #fff;
    background: #17a2b8;
    border: 1px solid #17a2b8;
}

.p-button.p-button-info:enabled:hover,
.p-button.p-button-info:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info>.p-button:enabled:hover,
.p-buttonset.p-button-info>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info>.p-button:enabled:hover,
.p-splitbutton.p-button-info>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info:enabled:hover,
.p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):hover {
    background: #1592a6;
    color: #fff;
    border-color: #1592a6;
}

.p-button.p-button-info:enabled:focus,
.p-button.p-button-info:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-info>.p-button:enabled:focus,
.p-buttonset.p-button-info>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-info>.p-button:enabled:focus,
.p-splitbutton.p-button-info>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-info:enabled:focus,
.p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #93e5f2;
}

.p-button.p-button-info:enabled:active,
.p-button.p-button-info:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-info>.p-button:enabled:active,
.p-buttonset.p-button-info>.p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info>.p-button:enabled:active,
.p-splitbutton.p-button-info>.p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info:enabled:active,
.p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):active {
    background: #128293;
    color: #fff;
    border-color: #128293;
}

.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info>.p-button.p-button-outlined,
.p-splitbutton.p-button-info>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-info.p-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #17a2b8;
    border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:hover,
.p-button.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info>.p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-info>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(23, 162, 184, 0.04);
    color: #17a2b8;
    border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:active,
.p-button.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-info>.p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-info>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(23, 162, 184, 0.16);
    color: #17a2b8;
    border: 1px solid;
}

.p-button.p-button-info.p-button-text,
.p-buttonset.p-button-info>.p-button.p-button-text,
.p-splitbutton.p-button-info>.p-button.p-button-text,
.p-fileupload-choose.p-button-info.p-button-text {
    background-color: rgba(0, 0, 0, 0);
    color: #17a2b8;
    border-color: rgba(0, 0, 0, 0);
}

.p-button.p-button-info.p-button-text:enabled:hover,
.p-button.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info>.p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-info>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info>.p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-info>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(23, 162, 184, 0.04);
    border-color: rgba(0, 0, 0, 0);
    color: #17a2b8;
}

.p-button.p-button-info.p-button-text:enabled:active,
.p-button.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-info>.p-button.p-button-text:enabled:active,
.p-buttonset.p-button-info>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info>.p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-info>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info.p-button-text:enabled:active,
.p-fileupload-choose.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(23, 162, 184, 0.16);
    border-color: rgba(0, 0, 0, 0);
    color: #17a2b8;
}

.p-button.p-button-success,
.p-buttonset.p-button-success>.p-button,
.p-splitbutton.p-button-success>.p-button,
.p-fileupload-choose.p-button-success {
    color: #fff;
    background: #28a745;
    border: 1px solid #28a745;
}

.p-button.p-button-success:enabled:hover,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success>.p-button:enabled:hover,
.p-buttonset.p-button-success>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success>.p-button:enabled:hover,
.p-splitbutton.p-button-success>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success:enabled:hover,
.p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):hover {
    background: #24963e;
    color: #fff;
    border-color: #24963e;
}

.p-button.p-button-success:enabled:focus,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-success>.p-button:enabled:focus,
.p-buttonset.p-button-success>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-success>.p-button:enabled:focus,
.p-splitbutton.p-button-success>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-success:enabled:focus,
.p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #9de8ae;
}

.p-button.p-button-success:enabled:active,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-success>.p-button:enabled:active,
.p-buttonset.p-button-success>.p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success>.p-button:enabled:active,
.p-splitbutton.p-button-success>.p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success:enabled:active,
.p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):active {
    background: #208637;
    color: #fff;
    border-color: #208637;
}

.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success>.p-button.p-button-outlined,
.p-splitbutton.p-button-success>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-success.p-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #28a745;
    border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:hover,
.p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success>.p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-success>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success>.p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-success>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(40, 167, 69, 0.04);
    color: #28a745;
    border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:active,
.p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-success>.p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-success>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(40, 167, 69, 0.16);
    color: #28a745;
    border: 1px solid;
}

.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success>.p-button.p-button-text,
.p-splitbutton.p-button-success>.p-button.p-button-text,
.p-fileupload-choose.p-button-success.p-button-text {
    background-color: rgba(0, 0, 0, 0);
    color: #28a745;
    border-color: rgba(0, 0, 0, 0);
}

.p-button.p-button-success.p-button-text:enabled:hover,
.p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success>.p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-success>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success>.p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-success>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(40, 167, 69, 0.04);
    border-color: rgba(0, 0, 0, 0);
    color: #28a745;
}

.p-button.p-button-success.p-button-text:enabled:active,
.p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-success>.p-button.p-button-text:enabled:active,
.p-buttonset.p-button-success>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success>.p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-text:enabled:active,
.p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(40, 167, 69, 0.16);
    border-color: rgba(0, 0, 0, 0);
    color: #28a745;
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning>.p-button,
.p-splitbutton.p-button-warning>.p-button,
.p-fileupload-choose.p-button-warning {
    color: #212529;
    background: #ffc107;
    border: 1px solid #ffc107;
}

.p-button.p-button-warning:enabled:hover,
.p-button.p-button-warning:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning>.p-button:enabled:hover,
.p-buttonset.p-button-warning>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning>.p-button:enabled:hover,
.p-splitbutton.p-button-warning>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning:enabled:hover,
.p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):hover {
    background: #ecb100;
    color: #212529;
    border-color: #ecb100;
}

.p-button.p-button-warning:enabled:focus,
.p-button.p-button-warning:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-warning>.p-button:enabled:focus,
.p-buttonset.p-button-warning>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-warning>.p-button:enabled:focus,
.p-splitbutton.p-button-warning>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-warning:enabled:focus,
.p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #ffe69c;
}

.p-button.p-button-warning:enabled:active,
.p-button.p-button-warning:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-warning>.p-button:enabled:active,
.p-buttonset.p-button-warning>.p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning>.p-button:enabled:active,
.p-splitbutton.p-button-warning>.p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning:enabled:active,
.p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):active {
    background: #d29d00;
    color: #212529;
    border-color: #d29d00;
}

.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning>.p-button.p-button-outlined,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-warning.p-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #ffc107;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:hover,
.p-button.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning>.p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-warning>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(255, 193, 7, 0.04);
    color: #ffc107;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:active,
.p-button.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-warning>.p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-warning>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(255, 193, 7, 0.16);
    color: #ffc107;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-text,
.p-buttonset.p-button-warning>.p-button.p-button-text,
.p-splitbutton.p-button-warning>.p-button.p-button-text,
.p-fileupload-choose.p-button-warning.p-button-text {
    background-color: rgba(0, 0, 0, 0);
    color: #ffc107;
    border-color: rgba(0, 0, 0, 0);
}

.p-button.p-button-warning.p-button-text:enabled:hover,
.p-button.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning>.p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-warning>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning>.p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-warning>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(255, 193, 7, 0.04);
    border-color: rgba(0, 0, 0, 0);
    color: #ffc107;
}

.p-button.p-button-warning.p-button-text:enabled:active,
.p-button.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-warning>.p-button.p-button-text:enabled:active,
.p-buttonset.p-button-warning>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning>.p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-warning>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning.p-button-text:enabled:active,
.p-fileupload-choose.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(255, 193, 7, 0.16);
    border-color: rgba(0, 0, 0, 0);
    color: #ffc107;
}

.p-button.p-button-help,
.p-buttonset.p-button-help>.p-button,
.p-splitbutton.p-button-help>.p-button,
.p-fileupload-choose.p-button-help {
    color: #fff;
    background: #6f42c1;
    border: 1px solid #6f42c1;
}

.p-button.p-button-help:enabled:hover,
.p-button.p-button-help:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help>.p-button:enabled:hover,
.p-buttonset.p-button-help>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help>.p-button:enabled:hover,
.p-splitbutton.p-button-help>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help:enabled:hover,
.p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):hover {
    background: #633ab0;
    color: #fff;
    border-color: #633ab0;
}

.p-button.p-button-help:enabled:focus,
.p-button.p-button-help:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-help>.p-button:enabled:focus,
.p-buttonset.p-button-help>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-help>.p-button:enabled:focus,
.p-splitbutton.p-button-help>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-help:enabled:focus,
.p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #c5b3e6;
}

.p-button.p-button-help:enabled:active,
.p-button.p-button-help:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-help>.p-button:enabled:active,
.p-buttonset.p-button-help>.p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help>.p-button:enabled:active,
.p-splitbutton.p-button-help>.p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help:enabled:active,
.p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):active {
    background: #58339c;
    color: #fff;
    border-color: #58339c;
}

.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help>.p-button.p-button-outlined,
.p-splitbutton.p-button-help>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-help.p-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #6f42c1;
    border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:hover,
.p-button.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help>.p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-help>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(111, 66, 193, 0.04);
    color: #6f42c1;
    border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:active,
.p-button.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-help>.p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-help>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(111, 66, 193, 0.16);
    color: #6f42c1;
    border: 1px solid;
}

.p-button.p-button-help.p-button-text,
.p-buttonset.p-button-help>.p-button.p-button-text,
.p-splitbutton.p-button-help>.p-button.p-button-text,
.p-fileupload-choose.p-button-help.p-button-text {
    background-color: rgba(0, 0, 0, 0);
    color: #6f42c1;
    border-color: rgba(0, 0, 0, 0);
}

.p-button.p-button-help.p-button-text:enabled:hover,
.p-button.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help>.p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-help>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help>.p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-help>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(111, 66, 193, 0.04);
    border-color: rgba(0, 0, 0, 0);
    color: #6f42c1;
}

.p-button.p-button-help.p-button-text:enabled:active,
.p-button.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-help>.p-button.p-button-text:enabled:active,
.p-buttonset.p-button-help>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help>.p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-help>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help.p-button-text:enabled:active,
.p-fileupload-choose.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(111, 66, 193, 0.16);
    border-color: rgba(0, 0, 0, 0);
    color: #6f42c1;
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger>.p-button,
.p-splitbutton.p-button-danger>.p-button,
.p-fileupload-choose.p-button-danger {
    color: #fff;
    background: #dc3545;
    border: 1px solid #dc3545;
}

.p-button.p-button-danger:enabled:hover,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger>.p-button:enabled:hover,
.p-buttonset.p-button-danger>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger>.p-button:enabled:hover,
.p-splitbutton.p-button-danger>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger:enabled:hover,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):hover {
    background: #d12435;
    color: #fff;
    border-color: #d12435;
}

.p-button.p-button-danger:enabled:focus,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-danger>.p-button:enabled:focus,
.p-buttonset.p-button-danger>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-danger>.p-button:enabled:focus,
.p-splitbutton.p-button-danger>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-danger:enabled:focus,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #f1aeb5;
}

.p-button.p-button-danger:enabled:active,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger>.p-button:enabled:active,
.p-buttonset.p-button-danger>.p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger>.p-button:enabled:active,
.p-splitbutton.p-button-danger>.p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger:enabled:active,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):active {
    background: #ba202f;
    color: #fff;
    border-color: #ba202f;
}

.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-danger.p-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #dc3545;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger>.p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(220, 53, 69, 0.04);
    color: #dc3545;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger>.p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(220, 53, 69, 0.16);
    color: #dc3545;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger>.p-button.p-button-text,
.p-splitbutton.p-button-danger>.p-button.p-button-text,
.p-fileupload-choose.p-button-danger.p-button-text {
    background-color: rgba(0, 0, 0, 0);
    color: #dc3545;
    border-color: rgba(0, 0, 0, 0);
}

.p-button.p-button-danger.p-button-text:enabled:hover,
.p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger>.p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-danger>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger>.p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(220, 53, 69, 0.04);
    border-color: rgba(0, 0, 0, 0);
    color: #dc3545;
}

.p-button.p-button-danger.p-button-text:enabled:active,
.p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger>.p-button.p-button-text:enabled:active,
.p-buttonset.p-button-danger>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger>.p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-text:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(220, 53, 69, 0.16);
    border-color: rgba(0, 0, 0, 0);
    color: #dc3545;
}

.p-button.p-button-link {
    color: var(--primary-color);
    background: rgba(0, 0, 0, 0);
    border: rgba(0, 0, 0, 0);
}

.p-button.p-button-link:enabled:hover,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):hover {
    background: rgba(0, 0, 0, 0);
    color: #0069d9;
    border-color: rgba(0, 0, 0, 0);
}

.p-button.p-button-link:enabled:hover .p-button-label,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):hover .p-button-label {
    text-decoration: underline;
}

.p-button.p-button-link:enabled:focus,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):focus {
    background: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    border-color: rgba(0, 0, 0, 0);
}

.p-button.p-button-link:enabled:active,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):active {
    background: rgba(0, 0, 0, 0);
    color: var(--primary-color);
    border-color: rgba(0, 0, 0, 0);
}

.p-splitbutton {
    border-radius: 4px;
}

.p-splitbutton.p-button-outlined>.p-button {
    background-color: rgba(0, 0, 0, 0);
    color: var(--primary-color);
    border: 1px solid;
}

.p-splitbutton.p-button-outlined>.p-button:enabled:hover,
.p-splitbutton.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(0, 123, 255, 0.04);
    color: var(--primary-color);
}

.p-splitbutton.p-button-outlined>.p-button:enabled:active,
.p-splitbutton.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(0, 123, 255, 0.16);
    color: var(--primary-color);
}

.p-splitbutton.p-button-outlined.p-button-plain>.p-button {
    color: #6c757d;
    border-color: #6c757d;
}

.p-splitbutton.p-button-outlined.p-button-plain>.p-button:enabled:hover,
.p-splitbutton.p-button-outlined.p-button-plain>.p-button:not(button):not(a):not(.p-disabled):hover {
    background: #e9ecef;
    color: #6c757d;
}

.p-splitbutton.p-button-outlined.p-button-plain>.p-button:enabled:active,
.p-splitbutton.p-button-outlined.p-button-plain>.p-button:not(button):not(a):not(.p-disabled):active {
    background: #dee2e6;
    color: #6c757d;
}

.p-splitbutton.p-button-text>.p-button {
    background-color: rgba(0, 0, 0, 0);
    color: var(--primary-color);
    border-color: rgba(0, 0, 0, 0);
}

.p-splitbutton.p-button-text>.p-button:enabled:hover,
.p-splitbutton.p-button-text>.p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(0, 123, 255, 0.04);
    color: var(--primary-color);
    border-color: rgba(0, 0, 0, 0);
}

.p-splitbutton.p-button-text>.p-button:enabled:active,
.p-splitbutton.p-button-text>.p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(0, 123, 255, 0.16);
    color: var(--primary-color);
    border-color: rgba(0, 0, 0, 0);
}

.p-splitbutton.p-button-text.p-button-plain>.p-button {
    color: #6c757d;
}

.p-splitbutton.p-button-text.p-button-plain>.p-button:enabled:hover,
.p-splitbutton.p-button-text.p-button-plain>.p-button:not(button):not(a):not(.p-disabled):hover {
    background: #e9ecef;
    color: #6c757d;
}

.p-splitbutton.p-button-text.p-button-plain>.p-button:enabled:active,
.p-splitbutton.p-button-text.p-button-plain>.p-button:not(button):not(a):not(.p-disabled):active {
    background: #dee2e6;
    color: #6c757d;
}

.p-splitbutton.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-splitbutton.p-button-rounded {
    border-radius: 2rem;
}

.p-splitbutton.p-button-rounded>.p-button {
    border-radius: 2rem;
}

.p-splitbutton.p-button-sm>.p-button {
    font-size: 0.875rem;
    padding: 0.4375rem 0.65625rem;
}

.p-splitbutton.p-button-sm>.p-button .p-button-icon {
    font-size: 0.875rem;
}

.p-splitbutton.p-button-lg>.p-button {
    font-size: 1.25rem;
    padding: 0.625rem 0.9375rem;
}

.p-splitbutton.p-button-lg>.p-button .p-button-icon {
    font-size: 1.25rem;
}

.p-splitbutton.p-button-secondary.p-button-outlined>.p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #6c757d;
    border: 1px solid;
}

.p-splitbutton.p-button-secondary.p-button-outlined>.p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(108, 117, 125, 0.04);
    color: #6c757d;
}

.p-splitbutton.p-button-secondary.p-button-outlined>.p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(108, 117, 125, 0.16);
    color: #6c757d;
}

.p-splitbutton.p-button-secondary.p-button-text>.p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #6c757d;
    border-color: rgba(0, 0, 0, 0);
}

.p-splitbutton.p-button-secondary.p-button-text>.p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-text>.p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(108, 117, 125, 0.04);
    border-color: rgba(0, 0, 0, 0);
    color: #6c757d;
}

.p-splitbutton.p-button-secondary.p-button-text>.p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-text>.p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(108, 117, 125, 0.16);
    border-color: rgba(0, 0, 0, 0);
    color: #6c757d;
}

.p-splitbutton.p-button-info.p-button-outlined>.p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #17a2b8;
    border: 1px solid;
}

.p-splitbutton.p-button-info.p-button-outlined>.p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(23, 162, 184, 0.04);
    color: #17a2b8;
}

.p-splitbutton.p-button-info.p-button-outlined>.p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(23, 162, 184, 0.16);
    color: #17a2b8;
}

.p-splitbutton.p-button-info.p-button-text>.p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #17a2b8;
    border-color: rgba(0, 0, 0, 0);
}

.p-splitbutton.p-button-info.p-button-text>.p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-text>.p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(23, 162, 184, 0.04);
    border-color: rgba(0, 0, 0, 0);
    color: #17a2b8;
}

.p-splitbutton.p-button-info.p-button-text>.p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-text>.p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(23, 162, 184, 0.16);
    border-color: rgba(0, 0, 0, 0);
    color: #17a2b8;
}

.p-splitbutton.p-button-success.p-button-outlined>.p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #28a745;
    border: 1px solid;
}

.p-splitbutton.p-button-success.p-button-outlined>.p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(40, 167, 69, 0.04);
    color: #28a745;
}

.p-splitbutton.p-button-success.p-button-outlined>.p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(40, 167, 69, 0.16);
    color: #28a745;
}

.p-splitbutton.p-button-success.p-button-text>.p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #28a745;
    border-color: rgba(0, 0, 0, 0);
}

.p-splitbutton.p-button-success.p-button-text>.p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-text>.p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(40, 167, 69, 0.04);
    border-color: rgba(0, 0, 0, 0);
    color: #28a745;
}

.p-splitbutton.p-button-success.p-button-text>.p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-text>.p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(40, 167, 69, 0.16);
    border-color: rgba(0, 0, 0, 0);
    color: #28a745;
}

.p-splitbutton.p-button-warning.p-button-outlined>.p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #ffc107;
    border: 1px solid;
}

.p-splitbutton.p-button-warning.p-button-outlined>.p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(255, 193, 7, 0.04);
    color: #ffc107;
}

.p-splitbutton.p-button-warning.p-button-outlined>.p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(255, 193, 7, 0.16);
    color: #ffc107;
}

.p-splitbutton.p-button-warning.p-button-text>.p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #ffc107;
    border-color: rgba(0, 0, 0, 0);
}

.p-splitbutton.p-button-warning.p-button-text>.p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-text>.p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(255, 193, 7, 0.04);
    border-color: rgba(0, 0, 0, 0);
    color: #ffc107;
}

.p-splitbutton.p-button-warning.p-button-text>.p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-text>.p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(255, 193, 7, 0.16);
    border-color: rgba(0, 0, 0, 0);
    color: #ffc107;
}

.p-splitbutton.p-button-help.p-button-outlined>.p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #6f42c1;
    border: 1px solid;
}

.p-splitbutton.p-button-help.p-button-outlined>.p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(111, 66, 193, 0.04);
    color: #6f42c1;
}

.p-splitbutton.p-button-help.p-button-outlined>.p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(111, 66, 193, 0.16);
    color: #6f42c1;
}

.p-splitbutton.p-button-help.p-button-text>.p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #6f42c1;
    border-color: rgba(0, 0, 0, 0);
}

.p-splitbutton.p-button-help.p-button-text>.p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-text>.p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(111, 66, 193, 0.04);
    border-color: rgba(0, 0, 0, 0);
    color: #6f42c1;
}

.p-splitbutton.p-button-help.p-button-text>.p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-text>.p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(111, 66, 193, 0.16);
    border-color: rgba(0, 0, 0, 0);
    color: #6f42c1;
}

.p-splitbutton.p-button-danger.p-button-outlined>.p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #dc3545;
    border: 1px solid;
}

.p-splitbutton.p-button-danger.p-button-outlined>.p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(220, 53, 69, 0.04);
    color: #dc3545;
}

.p-splitbutton.p-button-danger.p-button-outlined>.p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(220, 53, 69, 0.16);
    color: #dc3545;
}

.p-splitbutton.p-button-danger.p-button-text>.p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #dc3545;
    border-color: rgba(0, 0, 0, 0);
}

.p-splitbutton.p-button-danger.p-button-text>.p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-text>.p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(220, 53, 69, 0.04);
    border-color: rgba(0, 0, 0, 0);
    color: #dc3545;
}

.p-splitbutton.p-button-danger.p-button-text>.p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-text>.p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(220, 53, 69, 0.16);
    border-color: rgba(0, 0, 0, 0);
    color: #dc3545;
}

.p-speeddial-button.p-button.p-button-icon-only {
    width: 4rem;
    height: 4rem;
}

.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
    font-size: 1.3rem;
}

.p-speeddial-button.p-button.p-button-icon-only .p-button-icon.p-icon {
    width: 1.3rem;
    height: 1.3rem;
}

.p-speeddial-action {
    width: 3rem;
    height: 3rem;
    background: #495057;
    color: #fff;
}

.p-speeddial-action:hover {
    background: #343a40;
    color: #fff;
}

.p-speeddial-direction-up .p-speeddial-item {
    margin: 0.25rem;
}

.p-speeddial-direction-up .p-speeddial-item:first-child {
    margin-bottom: 0.5rem;
}

.p-speeddial-direction-down .p-speeddial-item {
    margin: 0.25rem;
}

.p-speeddial-direction-down .p-speeddial-item:first-child {
    margin-top: 0.5rem;
}

.p-speeddial-direction-left .p-speeddial-item {
    margin: 0 0.25rem;
}

.p-speeddial-direction-left .p-speeddial-item:first-child {
    margin-right: 0.5rem;
}

.p-speeddial-direction-right .p-speeddial-item {
    margin: 0 0.25rem;
}

.p-speeddial-direction-right .p-speeddial-item:first-child {
    margin-left: 0.5rem;
}

.p-speeddial-circle .p-speeddial-item,
.p-speeddial-semi-circle .p-speeddial-item,
.p-speeddial-quarter-circle .p-speeddial-item {
    margin: 0;
}

.p-speeddial-circle .p-speeddial-item:first-child,
.p-speeddial-circle .p-speeddial-item:last-child,
.p-speeddial-semi-circle .p-speeddial-item:first-child,
.p-speeddial-semi-circle .p-speeddial-item:last-child,
.p-speeddial-quarter-circle .p-speeddial-item:first-child,
.p-speeddial-quarter-circle .p-speeddial-item:last-child {
    margin: 0;
}

.p-speeddial-mask {
    background-color: rgba(0, 0, 0, 0.4);
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: box-shadow 0.15s;
    margin: 0.5rem;
}

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: #495057;
    border-color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
}

.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-carousel .p-carousel-indicators {
    padding: 30 px !important
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #e9ecef;
    width: 2rem;
    height: 0.5rem;
    transition: box-shadow 0.15s;
    border-radius: 0;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
    background: #dee2e6;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background: var(--primary-color);
    color: #fff;
}

.p-datatable .p-paginator-top {
    border-width: 1px 0 0 0;
    border-radius: 0;
}

.p-datatable .p-paginator-bottom {
    border-width: 1px 0 0 0;
    border-radius: 0;
}

.p-datatable .p-datatable-header {
    background: var(--primary-light);
    color: #212529;
    border: solid var(--primary-light);
    border-width: 4px;
    padding: 1rem 1rem;
    font-weight: 600;
}

.p-datatable .p-datatable-footer {
    background: #efefef;
    color: #212529;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}

.p-datatable .p-datatable-thead>tr>th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid var(--primary-light);
    border-width: 1px 0 2px 0;
    font-weight: 600;
    color: #212529;
    background: #ffff;
    transition: box-shadow 0.15s;
}

.p-datatable .p-datatable-tfoot>tr>td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    /* border-width: 1px 0 1px 0; */
    font-weight: 600;
    color: #212529;
    background: #fff;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #6c757d;
    margin-left: 0.5rem;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #ffff;
    background: var(--primary-color);
    margin-left: 0.5rem;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: #e9ecef;
    color: #212529;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #6c757d;
}

.p-datatable .p-sortable-column.p-highlight {
    background: #fff;
    color: var(--primary-color);
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: var(--primary-color);
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: #e9ecef;
    color: var(--primary-color);
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: var(--primary-color);
}

.p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.15rem var(--primary-color);
    outline: 0 none;
}

.p-datatable .p-datatable-tbody>tr {
    background: var(--primary-color);
    color: #212529;
    transition: box-shadow 0.15s;
}

.p-datatable .p-datatable-tbody>tr>td {
    text-align: left;
    border: .25px solid var(--primary-color);
    /* border-width: 1px 1px 1px 1px; */
    padding: 1rem 1rem;
}

.p-datatable .p-datatable-tbody>tr>td .p-row-toggler,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel {
    width: 1.2rem;
    height: 1.2rem;
    color: #6c757d;
    border: 0 none;
    background: var(--primary-color);
    border-radius: 50%;
    transition: box-shadow 0.15s;
    margin-right: 10px;
}

.p-datatable .p-datatable-tbody>tr>td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:enabled:hover {
    color: #495057;
    border-color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
}

.p-datatable .p-datatable-tbody>tr>td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--primary-color);
}

.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save {
    margin-right: 0.5rem;
}

.p-datatable .p-datatable-tbody>tr>td>.p-column-title {
    font-weight: 600;
}

.p-datatable .p-datatable-tbody>tr>td.p-highlight {
    background: var(--primary-color);
    color: var(--clr-text);
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
    background: var(--primary-color);
    color: #fff;
    ;
}

.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-top>td {
    box-shadow: inset 0 2px 0 0 var(--primary-color);
}

.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-bottom>td {
    box-shadow: inset 0 -2px 0 0 var(--primary-color);
}

.p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    background: #e9ecef;
    color: #212529;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-selectable-row:focus {
    outline: 0.15rem solid var(--primary-color);
    outline-offset: -0.15rem;
}

.p-datatable.p-datatable-selectable-cell .p-datatable-tbody>tr.p-selectable-row>td.p-selectable-cell:not(.p-highlight):hover {
    background: #e9ecef;
    color: #212529;
}

.p-datatable.p-datatable-selectable-cell .p-datatable-tbody>tr.p-selectable-row>td.p-selectable-cell:focus {
    outline: 0.15rem solid rgba(38, 143, 255, 0.5);
    outline-offset: -0.15rem;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    background: #e9ecef;
    color: #212529;
}

.p-datatable .p-column-resizer-helper {
    background: var(--primary-color);
}

.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
    background: #efefef;
}

.p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-thead,
.p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-tfoot,
.p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-virtualscroller>.p-datatable-table>.p-datatable-thead,
.p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-virtualscroller>.p-datatable-table>.p-datatable-tfoot {
    background-color: #fff;
}

.p-datatable .p-datatable-loading-icon {
    /* font-size: 2rem; */
}

.p-datatable .p-datatable-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
    border-width: 1px 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th:last-child {
    border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
    border-width: 1px 0 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td:last-child {
    border-width: 1px 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr:last-child>td {
    border-width: 1px 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr:last-child>td:last-child {
    border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot>tr>td {
    border-width: 1px 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot>tr>td:last-child {
    border-width: 1px 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead+.p-datatable-tfoot>tr>td {
    border-width: 0 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead+.p-datatable-tfoot>tr>td:last-child {
    border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td {
    border-width: 0 0 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td:last-child {
    border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody>tr:last-child>td {
    border-width: 0 0 0 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody>tr:last-child>td:last-child {
    border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd {
    background: var(--primary-light);

}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd.p-highlight {
    background: var(--primary-color);
    ;
    color: #fff;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd.p-highlight .p-row-toggler {
    color: #fff;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd.p-highlight .p-row-toggler:hover {
    color: #fff;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd+.p-row-expanded {
    background: #0d1a2c;
}

.p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0px;
}

.p-datatable.p-datatable-sm .p-datatable-thead>tr>th {
    padding: 0.5rem 0.5rem;
    text-align: center;
    border-right: .5px solid;
    font-weight: normal;
    color: #ffffff;
    background-color: var(--primary-color) !important;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
    padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tfoot>tr>td {
    padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-footer {
    padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-lg .p-datatable-header {
    padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-thead>tr>th {
    padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tbody>tr>td {
    padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tfoot>tr>td {
    padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 1.25rem 1.25rem;
}

.p-datatable-drag-selection-helper {
    background: rgba(0, 123, 255, 0.16);
}

.p-dataview .p-paginator-top {
    border-width: 1px 0 0 0;
    border-radius: 0;
}

.p-dataview .p-paginator-bottom {
    border-width: 1px 0 0 0;
    border-radius: 0;
}

.p-dataview .p-dataview-header {
    background: var(--primary-light);
    color: #212529;
    border: solid #dee2e6;
    border-width: 1px 0 0 0;
    padding: 0.5rem 0.5rem;
    font-weight: 600;
}

.p-dataview .p-dataview-content {
    background: #fff;
    color: #212529;
    border: 0 none;
    padding: 0;
}

.p-dataview.p-dataview-list .p-dataview-content>.p-grid>div {
    border: 1px solid #dee2e6;
    border-width: 1px 0 0 0;
}

.p-dataview .p-dataview-footer {
    background: #efefef;
    color: #212529;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.p-dataview .p-dataview-loading-icon {
    /* font-size: 2rem; */
}

.p-dataview .p-dataview-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
}

.p-datascroller .p-paginator-top {
    border-width: 1px 0 0 0;
    border-radius: 0;
}

.p-datascroller .p-paginator-bottom {
    border-width: 1px 0 0 0;
    border-radius: 0;
}

.p-datascroller .p-datascroller-header {
    background: #efefef;
    color: #212529;
    border: solid #dee2e6;
    border-width: 1px 0 0 0;
    padding: 1rem 1rem;
    font-weight: 600;
}

.p-datascroller .p-datascroller-content {
    background: #fff;
    color: #212529;
    border: 0 none;
    padding: 0;
}

.p-datascroller.p-datascroller-inline .p-datascroller-list>li {
    border: 1px solid #dee2e6;
    border-width: 1px 0 0 0;
}

.p-datascroller .p-datascroller-footer {
    background: #efefef;
    color: #212529;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
    margin-left: 0.5rem;
}

.p-column-filter-menu-button {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: box-shadow 0.15s;
}

.p-column-filter-menu-button:hover {
    color: #495057;
    border-color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
}

.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    background: rgba(0, 0, 0, 0);
    color: #495057;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: var(--primary-color);
    color: #fff;
}

.p-column-filter-menu-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-column-filter-clear-button {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: box-shadow 0.15s;
}

.p-column-filter-clear-button:hover {
    color: #495057;
    border-color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
}

.p-column-filter-clear-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-column-filter-overlay {
    background: #fff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: none;
    min-width: 12.5rem;
}

.p-column-filter-overlay .p-column-filter-row-items {
    padding: 0.5rem 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
    margin: 0;
    padding: 0.5rem 1.5rem;
    border: 0 none;
    color: #212529;
    background: rgba(0, 0, 0, 0);
    transition: box-shadow 0.15s;
    border-radius: 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
    color: #fff;
    background: var(--primary-color);
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
    color: #212529;
    background: #e9ecef;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
    background: #efefef;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: 1.25rem;
    border-bottom: 1px solid #dee2e6;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
    margin-bottom: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
    margin-top: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0 none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: 0.5rem 1.25rem;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: 1.25rem;
}

.fc.fc-unthemed .fc-view-container th {
    background: #efefef;
    border: 1px solid #dee2e6;
    color: #212529;
}

.fc.fc-unthemed .fc-view-container td.fc-widget-content {
    border: 1px solid #dee2e6;
    color: #212529;
}

.fc.fc-unthemed .fc-view-container td.fc-head-container {
    border: 1px solid #dee2e6;
}

.fc.fc-unthemed .fc-view-container .fc-view {
    background: #fff;
}

.fc.fc-unthemed .fc-view-container .fc-row {
    border-right: 1px solid #dee2e6;
}

.fc.fc-unthemed .fc-view-container .fc-event {
    background: #0069d9;
    border: 1px solid #0069d9;
    color: #fff;
}

.fc.fc-unthemed .fc-view-container .fc-divider {
    background: #efefef;
    border: 1px solid #dee2e6;
}

.fc.fc-unthemed .fc-toolbar .fc-button {
    color: #fff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    font-size: 1rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
    background: #0069d9;
    color: #fff;
    border-color: #0069d9;
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
    background: #0062cc;
    color: #fff;
    border-color: #0062cc;
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
    content: "";
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
    content: "";
}

.fc.fc-unthemed .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
    background: #6c757d;
    border: 1px solid #6c757d;
    color: #fff;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
    background: #5a6268;
    border-color: #545b62;
    color: #fff;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
    background: #545b62;
    border-color: #4e555b;
    color: #fff;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
    background: #545b62;
    border-color: #4e555b;
    color: #fff;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    z-index: 1;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
    border-radius: 0;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
    border-color: #dee2e6;
}

.fc.fc-theme-standard .fc-view-harness th {
    background: #efefef;
    border-color: #dee2e6;
    color: #212529;
}

.fc.fc-theme-standard .fc-view-harness td {
    color: #212529;
    border-color: #dee2e6;
}

.fc.fc-theme-standard .fc-view-harness .fc-view {
    background: #fff;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover {
    background: none;
    border: 0 none;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
    border: 1px solid #dee2e6;
    padding: 1rem 1.25rem;
    background: #efefef;
    color: #212529;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close {
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: "PrimeIcons" !important;
    font-size: 1rem;
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: box-shadow 0.15s;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:before {
    content: "";
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:hover {
    color: #495057;
    border-color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
    padding: 1.25rem;
    border: 1px solid #dee2e6;
    background: #fff;
    color: #212529;
    border-top: 0 none;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
    color: #fff;
    background: #0069d9;
    border-color: #0069d9;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main {
    color: #fff;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
    background: #0069d9;
    border-color: #0069d9;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
    background: #e9ecef;
    color: #212529;
}

.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
    background: #efefef;
}

.fc.fc-theme-standard .fc-toolbar .fc-button {
    color: #fff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    font-size: 1rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
    background: #0069d9;
    color: #fff;
    border-color: #0069d9;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
    background: #0062cc;
    color: #fff;
    border-color: #0062cc;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
    opacity: 0.65;
    color: #fff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
    content: "";
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
    content: "";
}

.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
    background: #6c757d;
    border: 1px solid #6c757d;
    color: #fff;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
    background: #5a6268;
    border-color: #545b62;
    color: #fff;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
    background: #545b62;
    border-color: #4e555b;
    color: #fff;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
    background: #545b62;
    border-color: #4e555b;
    color: #fff;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    z-index: 1;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
    border-radius: 0;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.fc.fc-theme-standard .fc-highlight {
    color: var(--clr-text);
    background: var(--primary-color);
}

.p-orderlist .p-orderlist-controls {
    padding: 1.25rem;
}

.p-orderlist .p-orderlist-controls .p-button {
    margin-bottom: 0.5rem;
}

.p-orderlist .p-orderlist-header {
    background: #efefef;
    color: #212529;
    border: 1px solid #dee2e6;
    padding: 1rem 1.25rem;
    font-weight: 600;
    border-bottom: 0 none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-orderlist .p-orderlist-filter-container {
    padding: 1rem 1.25rem;
    background: #fff;
    border: 1px solid #dee2e6;
    border-bottom: 0 none;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
    padding-right: 1.75rem;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
    right: 0.75rem;
    color: #495057;
}

.p-orderlist .p-orderlist-list {
    border: 1px solid #dee2e6;
    background: #fff;
    color: #212529;
    padding: 0.5rem 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0.5rem 1.5rem;
    margin: 0;
    border: 0 none;
    color: #212529;
    background: rgba(0, 0, 0, 0);
    transition: transform 0.15s, box-shadow 0.15s;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
    background: #e9ecef;
    color: #212529;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    color: #fff;
    background: var(--primary-color);
}

.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even) {
    background: rgba(0, 0, 0, 0.05);
}

.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even):hover {
    background: #e9ecef;
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
    background: #e9ecef;
    color: #212529;
}

.p-organizationchart .p-organizationchart-node-content.p-highlight {
    background: var(--primary-color);
    color: #fff;
}

.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: #003e80;
}

.p-organizationchart .p-organizationchart-line-down {
    background: #dee2e6;
}

.p-organizationchart .p-organizationchart-line-left {
    border-right: 1px solid #dee2e6;
    border-color: #dee2e6;
}

.p-organizationchart .p-organizationchart-line-top {
    border-top: 1px solid #dee2e6;
    border-color: #dee2e6;
}

.p-organizationchart .p-organizationchart-node-content {
    border: 1px solid #dee2e6;
    background: #fff;
    color: #212529;
    padding: 1.25rem;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    color: inherit;
    border-radius: 50%;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-paginator {
    background: var(--primary-light);
    color: var(--clr-heading);
    border: solid #dee2e6;
    border-width: 0;
    padding: 5px;
    border-radius: 4px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    background-color: var(--primary-light);
    /* border: 1px solid var(--clr-text); */
    color: var(--clr-text);
    min-width: 1.5rem;
    height: 1.5rem;
    margin: 0 0 0 -1px;
    /* transition: box-shadow 0.15s; */
    border-radius: 0;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: var(--clr-heading);
    /* border-color: var(--clr-text); */
    color: var(--primary-light);
    border-radius: 5px;

}

.p-paginator .p-paginator-first {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.p-paginator .p-paginator-last {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.p-paginator .p-dropdown {
    margin-left: 0.5rem;
    height: 1.5rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
}

.p-paginator .p-paginator-page-input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.p-paginator .p-paginator-page-input .p-inputtext {
    max-width: 1.5rem;
}

.p-paginator .p-paginator-current {
    background-color: #fff;
    /* border: 1px solid #dee2e6; */
    color: var(--primary-color);
    min-width: 1.5rem;
    height: 1.5rem;
    margin: 0 0 0 -1px;
    /* padding: 0 0.5rem; */
}

.p-paginator .p-paginator-pages .p-paginator-page {
    /* background-color: var(--primary-color); */
    /* border: 1px solid var(--clr-text); */
    color: var(--clr-text);
    min-width: 1.5rem;
    height: 1.5rem;
    margin: 0 0 0 -1px;
    /* transition: box-shadow 0.15s; */
    border-radius: 5px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--clr-heading);
    border-color: var(--clr-text);
    color: var(--primary-color);
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: var(--clr-heading);
    /* border-color: var(--clr-text); */
    color: var(--primary-light);
}

.p-picklist .p-picklist-buttons {
    padding: 1.25rem;
}

.p-picklist .p-picklist-buttons .p-button {
    margin-bottom: 0.5rem;
}

.p-picklist .p-picklist-header {
    background: var(--primary-color);
    color: #212529;
    border: 1px solid #dee2e6;
    padding: 0.5rem 1.25rem;
    font-weight: 700 !important;
    border-bottom: 0 none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    text-align: center;
}

.p-picklist .p-picklist-filter-container {
    padding: 1rem 1.25rem;
    background: #fff;
    border: 1px solid #dee2e6;
    border-bottom: 0 none;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
    padding-right: 1.75rem;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
    right: 0.75rem;
    color: #495057;
}

.p-picklist .p-picklist-list {
    border: 1px solid #dee2e6;
    background: #fff;
    color: #212529;
    padding: 0.5rem 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.5rem 1.5rem;
    margin: 0;
    border: 0 none;
    color: #212529;
    background: rgba(0, 0, 0, 0);
    transition: transform 0.15s, box-shadow 0.15s;
}

.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
    background: #e9ecef;
    color: #212529;
}

.p-picklist .p-picklist-list .p-picklist-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: #fff;
    background: var(--primary-color);
}

.p-picklist .p-picklist-caption {
    text-align: center;
}

body .p-picklist .p-picklist-caption {
    font-weight: 700 !important;
}

.p-tree {
    border: 1px solid #dee2e6;
    background: #fff;
    color: #212529;
    padding: 1.25rem;
    border-radius: 4px;
}

.p-tree .p-tree-container .p-treenode {
    padding: 0.143rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: 4px;
    transition: box-shadow 0.15s;
    padding: 0.286rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: box-shadow 0.15s;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    color: #495057;
    border-color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    margin-right: 0.5rem;
    color: #6c757d;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin-right: 0.5rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #212529;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: var(--primary-color);
    color: #fff;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: #fff;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
    color: #fff;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #e9ecef;
    color: #212529;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
    background: #e9ecef;
    color: #212529;
}

.p-tree .p-tree-filter-container {
    margin-bottom: 0.5rem;
}

.p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 1.75rem;
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
    right: 0.75rem;
    color: #495057;
}

.p-tree .p-treenode-children {
    padding: 0 0 0 1rem;
}

.p-tree .p-tree-loading-icon {
    font-size: 2rem;
}

.p-tree .p-tree-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
}

.p-tree .p-treenode-droppoint {
    height: 0.5rem;
}

.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
    background: #0062cc;
}

.p-treetable .p-paginator-top {
    border-width: 1px 0 0 0;
    border-radius: 0;
}

.p-treetable .p-paginator-bottom {
    border-width: 1px 0 0 0;
    border-radius: 0;
}

.p-treetable .p-treetable-header {
    background: var(--primary-light);
    color: var(--clr-text);
    border: solid var(--primary-light);
    border-width: 1px 0 0 0;
    padding: 5px 5px;
    font-weight: 600;
}

.p-treetable .p-treetable-footer {
    background: var(--primary-color);
    color: var(--clr-text);
    border: 1px solid var(--primary-color);
    padding: 0.1rem 0.2rem 0.35rem 0.25rem;
    font-weight: 600;
}

.p-treetable .p-treetable-thead>tr>th {
    text-align: center;
    padding: .5rem .5rem;
    border-right: .5px solid var(--primary-light);
    font-weight: 600;
    color: var(--clr-text);
    background: var(--primary-color);
    transition: box-shadow 0.15s;
}

.p-treetable .p-treetable-tfoot>tr>td {
    text-align: center;
    padding: .5rem .5rem;
    border: 1px solid var(--primary-color);
    border-width: 1px 0 1px 0;
    font-weight: 600;
    color: #212529;
    background: #fff;
}

.p-treetable .p-sortable-column {
    outline-color: var(--primary-color);
}

.p-treetable .p-sortable-column .p-sortable-column-icon {
    color: #6c757d;
    margin-left: 0.5rem;
}

.p-treetable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #fff;
    background: var(--primary-color);
    margin-left: 0.5rem;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover {
    background: var(--primary-light);
    color: #212529;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: var(--primary-color);
}

.p-treetable .p-sortable-column.p-highlight {
    background: var(--primary-light);
    color: var(--primary-color);
}

.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: var(--primary-color);
}

.p-treetable .p-treetable-tbody>tr {
    /* background: var(--clr-heading); */
    color: var(--clr-text);
    transition: box-shadow 0.15s;
}

.p-treetable .p-treetable-tbody>tr>td {
    text-align: left;
    border: .25px solid var(--primary-color) !important;
    padding: .5rem .5rem;
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler {
    /* width: 2rem;
  height: 2rem; */
    color: #6c757d;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: box-shadow 0.15s;
    margin-right: 0.5rem;
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:enabled:hover {
    color: #495057;
    border-color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    /* box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); */
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler+.p-checkbox {
    margin-right: 0.5rem;
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler+.p-checkbox .p-indeterminate .p-checkbox-icon {
    color: var(--clr-heading);
}

.p-treetable .p-treetable-tbody>tr:focus {
    /* outline: 0.15rem solid rgba(38, 143, 255, 0.5); */
    outline-offset: -0.15rem;
}

.p-treetable .p-treetable-tbody>tr.p-highlight {
    background: var(--primary-color);
    color: var(--clr-text);
}

.p-treetable .p-treetable-tbody>tr.p-highlight .p-treetable-toggler {
    color: #fff;
}

.p-treetable .p-treetable-tbody>tr.p-highlight .p-treetable-toggler:hover {
    color: #fff;
}

.p-treetable.p-treetable-selectable .p-treetable-tbody>tr:not(.p-highlight):hover,
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover {
    background: var(--primary-color);
    color: #212529;
}

.p-treetable.p-treetable-selectable .p-treetable-tbody>tr:not(.p-highlight):hover .p-treetable-toggler,
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover .p-treetable-toggler {
    color: #212529;
}

.p-treetable .p-column-resizer-helper {
    background: var(--primary-color);
}

.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
    background: #efefef;
}

.p-treetable .p-treetable-loading-icon {
    font-size: 2rem;
}

.p-treetable .p-treetable-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
}

.p-treetable.p-treetable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-footer {
    border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-top {
    border-width: 0 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-bottom {
    border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-thead>tr>th {
    border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tbody>tr>td {
    border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tfoot>tr>td {
    border-width: 1px;
}

.p-treetable.p-treetable-sm .p-treetable-header {
    padding: 0.875rem 0.875rem;
}

.p-treetable.p-treetable-sm .p-treetable-thead>tr>th {
    padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-tbody>tr>td {
    padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-tfoot>tr>td {
    padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-footer {
    padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-lg .p-treetable-header {
    padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-thead>tr>th {
    padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-tbody>tr>td {
    padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-tfoot>tr>td {
    padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-footer {
    padding: 1.25rem 1.25rem;
}

.p-treetable .p-treetable-tbody>tr>td.p-cell-editing .p-component {
    width: 100%;
}

.p-timeline .p-timeline-event-marker {
    border: 0 none;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: var(--primary-color);
}

.p-timeline .p-timeline-event-connector {
    background-color: #dee2e6;
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
    padding: 0 1rem;
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 2px;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
    padding: 1rem 0;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
    height: 2px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem 1.25rem;
    border: 1px solid #dee2e6;
    color: #212529;
    background: #efefef;
    font-weight: 600;
    border-radius: 4px;
    transition: box-shadow 0.15s;
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    margin-right: 0.5rem;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #212529;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #efefef;
    border-color: #dee2e6;
    color: #212529;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-color: #dee2e6;
    background: #e9ecef;
    color: #212529;
}

.p-accordion .p-accordion-content {
    padding: 1.25rem;
    border: 1px solid #dee2e6;
    background: #fff;
    color: #212529;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-accordion .p-accordion-tab {
    margin-bottom: 0;
}

.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    border-radius: 0;
}

.p-accordion .p-accordion-tab .p-accordion-content {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link {
    border-top: 0 none;
}

.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-top: 0 none;
}

.p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-content {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-card {
    background: #fff;
    color: #212529;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.p-card .p-card-body {
    padding: 1.5rem;
}

.p-card .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.p-card .p-card-subtitle {
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: #6c757d;
}

.p-card .p-card-content {
    padding: 1rem 0;
}

.p-card .p-card-footer {
    padding: 1rem 0 0 0;
}

.p-fieldset {
    border: 1px solid #dee2e6;
    background: #fff;
    color: #212529;
    border-radius: 4px;
}

.p-fieldset .p-fieldset-legend {
    padding: 1rem 1.25rem;
    border: 1px solid #dee2e6;
    color: #212529;
    background: #efefef;
    font-weight: 600;
    border-radius: 4px;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: box-shadow 0.15s;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 1rem 1.25rem;
    color: #212529;
    border-radius: 4px;
    transition: box-shadow 0.15s;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    margin-right: 0.5rem;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #212529;
}

.p-fieldset .p-fieldset-content {
    padding: 1.25rem;
}

.p-divider .p-divider-content {
    background-color: #fff;
}

.p-divider.p-divider-horizontal {
    margin: 1rem 0;
    padding: 0 1rem;
}

.p-divider.p-divider-horizontal:before {
    border-top: 1px #dee2e6;
}

.p-divider.p-divider-horizontal .p-divider-content {
    padding: 0 0.5rem;
}

.p-divider.p-divider-vertical {
    margin: 0 1rem;
    padding: 1rem 0;
}

.p-divider.p-divider-vertical:before {
    border-left: 1px #dee2e6;
}

.p-divider.p-divider-vertical .p-divider-content {
    padding: 0.5rem 0;
}

.p-panel .p-panel-header {
    border: 1px solid #dee2e6;
    padding: 1rem 1.25rem;
    background: #efefef;
    color: #212529;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-panel .p-panel-header .p-panel-title {
    font-weight: 600;
}

.p-panel .p-panel-header .p-panel-header-icon {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: box-shadow 0.15s;
}

.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    color: #495057;
    border-color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-panel.p-panel-toggleable .p-panel-header {
    padding: 0.5rem 1.25rem;
}

.p-panel .p-panel-content {
    padding: 1.25rem;
    border: 1px solid #dee2e6;
    background: #fff;
    color: #212529;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top: 0 none;
}

.p-panel .p-panel-footer {
    padding: 0.5rem 1.25rem;
    border: 1px solid #dee2e6;
    background: #fff;
    color: #212529;
    border-top: 0 none;
}

.p-splitter {
    border: 1px solid #dee2e6;
    background: #fff;
    border-radius: 4px;
    color: #212529;
}

.p-splitter .p-splitter-gutter {
    transition: box-shadow 0.15s;
    background: #efefef;
}

.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: #dee2e6;
}

.p-splitter .p-splitter-gutter-resizing {
    background: #dee2e6;
}

.p-scrollpanel .p-scrollpanel-bar {
    background: #efefef;
    border: 0 none;
}

.p-tabview .p-tabview-nav {
    background: rgba(0, 0, 0, 0);
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
}

.p-tabview .p-tabview-nav li {
    margin-right: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid;
    border-width: 1px;
    border-color: #fff #fff #dee2e6 #fff;
    background: #fff;
    color: #6c757d;
    padding: 0.75rem 1rem;
    font-weight: 600;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    transition: box-shadow 0.15s;
    margin: 0 0 -1px 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: #fff;
    border-color: #dee2e6;
    color: #6c757d;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #fff;
    border-color: #dee2e6 #dee2e6 #fff #dee2e6;
    color: #495057;
}

.p-tabview .p-tabview-close {
    margin-left: 0.5rem;
}

.p-tabview .p-tabview-nav-btn.p-link {
    background: #fff;
    color: #495057;
    width: 2.357rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;
}

.p-tabview .p-tabview-nav-btn.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-tabview .p-tabview-panels {
    background: #fff;
    padding: 1.25rem;
    border: 0 none;
    color: #212529;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}


.p-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap !important;
    padding: 5px 5px;
}


.p-confirm-popup {
    background: #fff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    box-shadow: none;
}

.p-confirm-popup .p-confirm-popup-content {
    padding: 1.25rem;
}

.p-confirm-popup .p-confirm-popup-footer {
    text-align: right;
    padding: 0 1.25rem 1.25rem 1.25rem;
}

.p-confirm-popup .p-confirm-popup-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
}

.p-confirm-popup .p-confirm-popup-footer button:last-child {
    margin: 0;
}

.p-confirm-popup:after {
    border: solid rgba(0, 0, 0, 0);
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
}

.p-confirm-popup:before {
    border: solid rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0.2);
}

.p-confirm-popup.p-confirm-popup-flipped:after {
    border-top-color: #fff;
}

.p-confirm-popup.p-confirm-popup-flipped:before {
    border-top-color: rgba(0, 0, 0, 0.2);
}

.p-confirm-popup .p-confirm-popup-icon {
    font-size: 1.5rem;
}

.p-confirm-popup .p-confirm-popup-icon.p-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.p-confirm-popup .p-confirm-popup-message {
    margin-left: 1rem;
}

.p-dialog {
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.p-dialog .p-dialog-header {
    border-bottom: 1px solid #e9ecef;
    background: #fff;
    color: #212529;
    padding: .4rem;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 16px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    /* transition: box-shadow 0.15s; */
    margin-right: 0.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: #495057;
    border-color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
}

.p-dialog .p-dialog-content {
    background: #fff;
    color: #212529;
    padding: 1rem;
}

.p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-dialog .p-dialog-footer {
    border-top: 1px solid #e9ecef;
    background: #fff;
    color: #212529;
    padding: 1rem;
    text-align: right;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
    width: 2rem;
    height: 2rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem;
}

.p-overlaypanel {
    background: #fff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    box-shadow: none;
}

.p-overlaypanel .p-overlaypanel-content {
    padding: 1.25rem;
}

.p-overlaypanel .p-overlaypanel-close {
    background: var(--primary-color);
    color: #fff;
    width: 2rem;
    height: 2rem;
    transition: box-shadow 0.15s;
    border-radius: 50%;
    position: absolute;
    top: -1rem;
    right: -1rem;
}

.p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: #0069d9;
    color: #fff;
}

.p-overlaypanel:after {
    border: solid rgba(0, 0, 0, 0);
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
}

.p-overlaypanel:before {
    border: solid rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0.2);
}

.p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: #fff;
}

.p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: rgba(0, 0, 0, 0.2);
}

.p-sidebar {
    background: #fff;
    color: var(--clr-text);
    /* border-left: 1px solid rgba(0, 0, 0, 0.2); */
    box-shadow: none;
    transition: box-shadow 0.15s;
}

.p-sidebar .p-sidebar-header {
    padding: .5rem;
    align-items: center;
    flex-direction: column;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
    width: 2rem;
    height: 2rem;
    font-size: 15px;
    color: grey;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: box-shadow 0.15s;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: var(--clr-text);
    border-color: rgba(0, 0, 0, 0);
    /* background:var(--clr-text); */
    font-weight: bolder;

}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    font-weight: bolder;
}

.p-sidebar .p-sidebar-header+.p-sidebar-content {
    padding-top: 0;
    height: auto;
}

.p-sidebar .p-sidebar-content {
    /* padding: 10px; */

}

.p-tooltip .p-tooltip-text {
    background: var(--clr-text);
    color: #fff;
    padding: 0.5rem 0.75rem;
    box-shadow: none;
    border-radius: 4px;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #212529;
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #212529;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #212529;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #212529;
}

.p-fileupload .p-fileupload-buttonbar {
    background: #efefef;
    padding: 1rem 1.25rem;
    border: 1px solid #dee2e6;
    color: #212529;
    border-bottom: 0 none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    gap: 0.5rem;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: 0.5rem;
}

.p-fileupload .p-fileupload-content {
    background: #fff;
    padding: 2rem 1rem;
    border: 1px solid #dee2e6;
    color: #212529;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-fileupload .p-progressbar {
    height: 0.25rem;
}

.p-fileupload .p-fileupload-row>div {
    padding: 1rem 1rem;
}

.p-fileupload.p-fileupload-advanced .p-message {
    margin-top: 0;
}

.p-breadcrumb {
    background: var(--primary-color);
    border: 0 none;
    border-radius: 4px;
    padding: 1rem;
}

.p-breadcrumb ul li .p-menuitem-link {
    transition: box-shadow 0.15s;
    border-radius: 4px;
}

.p-breadcrumb ul li .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
    color: var(--clr-text);
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
    color: var(--clr-text);
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
    margin: 0 0.5rem 0 0.5rem;
    color: #6c757d;
}

.p-breadcrumb ul li:last-child .p-menuitem-text {
    color: #6c757d;
}

.p-breadcrumb ul li:last-child .p-menuitem-icon {
    color: #6c757d;
}

.p-contextmenu {
    padding: 0.5rem 0;
    background: #fff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
    border-radius: 4px;
    width: 12.5rem;
}

.p-contextmenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
}

.p-contextmenu .p-menuitem-link .p-menuitem-text {
    color: #212529;
}

.p-contextmenu .p-menuitem-link .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
    color: #212529;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #212529;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #212529;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #212529;
}

.p-contextmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-contextmenu .p-submenu-list {
    padding: 0.5rem 0;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
    border-radius: 4px;
}

.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background: #e9ecef;
}

.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text {
    color: #212529;
}

.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
    color: #212529;
}

.p-contextmenu .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
}

.p-contextmenu .p-submenu-icon {
    font-size: 0.875rem;
    transition: transform 0.15s;
}

.p-contextmenu .p-submenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
}

.p-dock .p-dock-list {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
}

.p-dock .p-dock-item {
    padding: 0.5rem;
}

.p-dock .p-dock-action {
    width: 4rem;
    height: 4rem;
}

.p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-prev,
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-next,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-prev,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-next {
    margin: 0 0.9rem;
}

.p-dock.p-dock-magnification.p-dock-top .p-dock-item-prev,
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-next,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-prev,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-next {
    margin: 0 1.3rem;
}

.p-dock.p-dock-magnification.p-dock-top .p-dock-item-current,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-current {
    margin: 0 1.5rem;
}

.p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-prev,
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-next,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-prev,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-next {
    margin: 0.9rem 0;
}

.p-dock.p-dock-magnification.p-dock-left .p-dock-item-prev,
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-next,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-prev,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-next {
    margin: 1.3rem 0;
}

.p-dock.p-dock-magnification.p-dock-left .p-dock-item-current,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-current {
    margin: 1.5rem 0;
}

@media screen and (max-width: 960px) {

    .p-dock.p-dock-top .p-dock-container,
    .p-dock.p-dock-bottom .p-dock-container {
        overflow-x: auto;
        width: 100%;
    }

    .p-dock.p-dock-top .p-dock-container .p-dock-list,
    .p-dock.p-dock-bottom .p-dock-container .p-dock-list {
        margin: 0 auto;
    }

    .p-dock.p-dock-left .p-dock-container,
    .p-dock.p-dock-right .p-dock-container {
        overflow-y: auto;
        height: 100%;
    }

    .p-dock.p-dock-left .p-dock-container .p-dock-list,
    .p-dock.p-dock-right .p-dock-container .p-dock-list {
        margin: auto 0;
    }

    .p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-prev,
    .p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-next,
    .p-dock.p-dock-magnification.p-dock-top .p-dock-item-prev,
    .p-dock.p-dock-magnification.p-dock-top .p-dock-item-next,
    .p-dock.p-dock-magnification.p-dock-top .p-dock-item-current,
    .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-prev,
    .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-next,
    .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-prev,
    .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-next,
    .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-current,
    .p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-prev,
    .p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-next,
    .p-dock.p-dock-magnification.p-dock-left .p-dock-item-prev,
    .p-dock.p-dock-magnification.p-dock-left .p-dock-item-next,
    .p-dock.p-dock-magnification.p-dock-left .p-dock-item-current,
    .p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-prev,
    .p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-next,
    .p-dock.p-dock-magnification.p-dock-right .p-dock-item-prev,
    .p-dock.p-dock-magnification.p-dock-right .p-dock-item-next,
    .p-dock.p-dock-magnification.p-dock-right .p-dock-item-current {
        transform: none;
        margin: 0;
    }
}

.p-megamenu {
    padding: 0.5rem 1rem;
    background: #efefef;
    color: rgba(0, 0, 0, 0.9);
    border: 0 none;
    border-radius: 4px;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link {
    padding: 1rem;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    transition: box-shadow 0.15s;
    user-select: none;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text {
    color: rgba(0, 0, 0, 0.5);
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon {
    color: rgba(0, 0, 0, 0.5);
    margin-right: 0.5rem;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-submenu-icon {
    color: rgba(0, 0, 0, 0.5);
    margin-left: 0.5rem;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover {
    background: rgba(0, 0, 0, 0);
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: rgba(0, 0, 0, 0.7);
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: rgba(0, 0, 0, 0.7);
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: rgba(0, 0, 0, 0.7);
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover {
    background: rgba(0, 0, 0, 0);
}

.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: rgba(0, 0, 0, 0.9);
}

.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: rgba(0, 0, 0, 0.9);
}

.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: rgba(0, 0, 0, 0.9);
}

.p-megamenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
}

.p-megamenu .p-menuitem-link .p-menuitem-text {
    color: #212529;
}

.p-megamenu .p-menuitem-link .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
}

.p-megamenu .p-menuitem-link .p-submenu-icon {
    color: #212529;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #212529;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #212529;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #212529;
}

.p-megamenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-megamenu .p-megamenu-panel {
    background: #fff;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
}

.p-megamenu .p-megamenu-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #212529;
    background: #fff;
    font-weight: 600;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-megamenu .p-megamenu-submenu {
    padding: 0.5rem 0;
    width: 12.5rem;
}

.p-megamenu .p-megamenu-submenu .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
}

.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background: #e9ecef;
}

.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text {
    color: #212529;
}

.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
    color: #212529;
}

.p-megamenu.p-megamenu-vertical {
    width: 12.5rem;
    padding: 0.5rem 0;
}

.p-megamenu .p-megamenu-button {
    width: 2rem;
    height: 2rem;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transition: box-shadow 0.15s;
}

.p-megamenu .p-megamenu-button:hover {
    color: rgba(0, 0, 0, 0.7);
    background: rgba(0, 0, 0, 0);
}

.p-megamenu .p-megamenu-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-megamenu .p-submenu-icon {
    transition: transform 0.15s;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list {
    padding: 0.5rem 0;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-icon {
    font-size: 0.875rem;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list>.p-menuitem {
    width: 100%;
    position: static;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list>.p-menuitem>.p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text {
    color: #212529;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-submenu-icon {
    color: #212529;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #212529;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #212529;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #212529;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list>.p-menuitem>.p-menuitem-link>.p-submenu-icon {
    margin-left: auto;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link>.p-submenu-icon {
    transform: rotate(-180deg);
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.15s;
    transform: rotate(90deg);
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-list .p-menuitem-active>.p-menuitem-link>.p-submenu-icon {
    transform: rotate(-90deg);
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-menuitem {
    width: 100%;
    position: static;
}

.p-menu {
    padding: 0.5rem 0;
    background: #fff;
    color: #212529;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    width: 12.5rem;
}

.p-menu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
}

.p-menu .p-menuitem-link .p-menuitem-text {
    color: #212529;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
}

.p-menu .p-menuitem-link .p-submenu-icon {
    color: #212529;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #212529;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #212529;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #212529;
}

.p-menu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-menu.p-menu-overlay {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
    margin-top: 10px;
    max-width: 160px;
}

.p-menu .p-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #212529;
    background: #fff;
    font-weight: 600;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.p-menu .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
}

.p-menubar {
    padding: 0.5rem 1rem;
    background: #efefef;
    color: rgba(0, 0, 0, 0.9);
    border: 0 none;
    border-radius: 4px;
}

.p-menubar .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
}

.p-menubar .p-menuitem-link .p-menuitem-text {
    color: #212529;
}

.p-menubar .p-menuitem-link .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
}

.p-menubar .p-menuitem-link .p-submenu-icon {
    color: #212529;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #212529;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #212529;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #212529;
}

.p-menubar .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link {
    padding: 1rem;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    transition: box-shadow 0.15s;
    user-select: none;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text {
    color: rgba(0, 0, 0, 0.5);
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon {
    color: rgba(0, 0, 0, 0.5);
    margin-right: 0.5rem;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-submenu-icon {
    color: rgba(0, 0, 0, 0.5);
    margin-left: 0.5rem;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover {
    background: rgba(0, 0, 0, 0);
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: rgba(0, 0, 0, 0.7);
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: rgba(0, 0, 0, 0.7);
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: rgba(0, 0, 0, 0.7);
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link,
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover {
    background: rgba(0, 0, 0, 0);
}

.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: rgba(0, 0, 0, 0.9);
}

.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: rgba(0, 0, 0, 0.9);
}

.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: rgba(0, 0, 0, 0.9);
}

.p-menubar .p-submenu-list {
    padding: 0.5rem 0;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
    width: 12.5rem;
}

.p-menubar .p-submenu-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
}

.p-menubar .p-submenu-list .p-submenu-icon {
    font-size: 0.875rem;
}

.p-menubar .p-submenu-list .p-submenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
}

.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background: #e9ecef;
}

.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text {
    color: #212529;
}

.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
    color: #212529;
}

@media screen and (max-width: 960px) {
    .p-menubar {
        position: relative;
    }

    .p-menubar .p-menubar-button {
        display: flex;
        width: 2rem;
        height: 2rem;
        color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        transition: box-shadow 0.15s;
    }

    .p-menubar .p-menubar-button:hover {
        color: rgba(0, 0, 0, 0.7);
        background: rgba(0, 0, 0, 0);
    }

    .p-menubar .p-menubar-button:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    }

    .p-menubar .p-menubar-root-list {
        position: absolute;
        display: none;
        padding: 0.5rem 0;
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-shadow: none;
        width: 100%;
    }

    .p-menubar .p-menubar-root-list .p-menu-separator {
        border-top: 1px solid #dee2e6;
        margin: 0.5rem 0;
    }

    .p-menubar .p-menubar-root-list .p-submenu-icon {
        font-size: 0.875rem;
    }

    .p-menubar .p-menubar-root-list .p-submenu-icon.p-icon {
        width: 0.875rem;
        height: 0.875rem;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem {
        width: 100%;
        position: static;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link {
        padding: 0.75rem 1rem;
        color: #212529;
        border-radius: 0;
        transition: box-shadow 0.15s;
        user-select: none;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text {
        color: #212529;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon {
        color: #212529;
        margin-right: 0.5rem;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-submenu-icon {
        color: #212529;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover {
        background: #e9ecef;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: #212529;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: #212529;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: #212529;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link>.p-submenu-icon {
        margin-left: auto;
        transition: transform 0.15s;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link>.p-submenu-icon {
        transform: rotate(-180deg);
    }

    .p-menubar .p-menubar-root-list .p-submenu-list {
        width: 100%;
        position: static;
        box-shadow: none;
        border: 0 none;
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
        transition: transform 0.15s;
        transform: rotate(90deg);
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active>.p-menuitem-link>.p-submenu-icon {
        transform: rotate(-90deg);
    }

    .p-menubar .p-menubar-root-list .p-menuitem {
        width: 100%;
        position: static;
    }

    .p-menubar .p-menubar-root-list ul li a {
        padding-left: 2.25rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li a {
        padding-left: 3.75rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li a {
        padding-left: 5.25rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
        padding-left: 6.75rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
        padding-left: 8.25rem;
    }

    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
        display: flex;
        flex-direction: column;
        top: 100%;
        left: 0;
        z-index: 1;
    }
}

.p-slidemenu {
    padding: 0.5rem 0;
    background: #fff;
    color: #212529;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    width: 12.5rem;
}

.p-slidemenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
}

.p-slidemenu .p-menuitem-link .p-menuitem-text {
    color: #212529;
}

.p-slidemenu .p-menuitem-link .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
    color: #212529;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #212529;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #212529;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #212529;
}

.p-slidemenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-slidemenu.p-slidemenu-overlay {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
}

.p-slidemenu .p-slidemenu-list {
    padding: 0.5rem 0;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
}

.p-slidemenu .p-slidemenu.p-slidemenu-active>.p-slidemenu-link {
    background: #e9ecef;
}

.p-slidemenu .p-slidemenu.p-slidemenu-active>.p-slidemenu-link .p-slidemenu-text {
    color: #212529;
}

.p-slidemenu .p-slidemenu.p-slidemenu-active>.p-slidemenu-link .p-slidemenu-icon,
.p-slidemenu .p-slidemenu.p-slidemenu-active>.p-slidemenu-link .p-slidemenu-icon {
    color: #212529;
}

.p-slidemenu .p-slidemenu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
}

.p-slidemenu .p-slidemenu-icon {
    font-size: 0.875rem;
}

.p-slidemenu .p-slidemenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
}

.p-slidemenu .p-slidemenu-backward {
    padding: 0.75rem 1rem;
    color: #212529;
}

.p-steps .p-steps-item .p-menuitem-link {
    background: rgba(0, 0, 0, 0);
    transition: box-shadow 0.15s;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0);
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: #212529;
    border: 1px solid #dee2e6;
    background: var(--primary-color-text);
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 4px;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: 0.5rem;
    color: #6c757d;
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: var(--primary-color);
    color: var(--clr-text);
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 600;
    color: #212529;
}

.p-steps .p-steps-item:before {
    content: " ";
    border-top: 1px solid #dee2e6;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
    background: rgba(0, 0, 0, 0);
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border: solid;
    border-width: 1px;
    border-color: #fff #fff #dee2e6 #fff;
    background: #fff;
    color: #6c757d;
    padding: 0.75rem 1rem;
    font-weight: 600;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    transition: box-shadow 0.15s;
    margin: 0 0 -1px 0;
    height: calc(100% + 1px);
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: 0.5rem;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background: #fff;
    border-color: #dee2e6;
    color: #6c757d;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: var(--primary-color);
    border-color: #dee2e6 #dee2e6 #fff #dee2e6;
    color: var(--clr-text);
}

.p-tieredmenu {
    padding: 0.5rem 0;
    background: #fff;
    color: #212529;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    width: 12.5rem;
}

.p-tieredmenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
    color: #212529;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
    color: #212529;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #212529;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #212529;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #212529;
}

.p-tieredmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-tieredmenu.p-tieredmenu-overlay {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
}

.p-tieredmenu .p-submenu-list {
    padding: 0.5rem 0;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
}

.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background: #e9ecef;

}

.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text {
    color: #212529;
}

.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
    color: #212529;
}

.p-tieredmenu .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
}

.p-tieredmenu .p-submenu-icon {
    font-size: 0.875rem;
    transition: transform 0.15s;
}

.p-tieredmenu .p-submenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
}

.p-inline-message {
    padding: 0.5rem 0.75rem;
    margin: 0;
    border-radius: 4px;
}

.p-inline-message.p-inline-message-info {
    background: #cce5ff;
    border: solid #b8daff;
    border-width: 0px;
    color: #004085;
}

.p-inline-message.p-inline-message-info .p-inline-message-icon {
    color: #004085;
}

.p-inline-message.p-inline-message-success {
    background: #d4edda;
    border: solid #c3e6cb;
    border-width: 0px;
    color: #155724;
}

.p-inline-message.p-inline-message-success .p-inline-message-icon {
    color: #155724;
}

.p-inline-message.p-inline-message-warn {
    background: #fff3cd;
    border: solid #ffeeba;
    border-width: 0px;
    color: #856404;
}

.p-inline-message.p-inline-message-warn .p-inline-message-icon {
    color: #856404;
}

.p-inline-message.p-inline-message-error {
    background: #f8d7da;
    border: solid #f5c6cb;
    border-width: 0px;
    color: #721c24;
}

.p-inline-message.p-inline-message-error .p-inline-message-icon {
    color: #721c24;
}

.p-inline-message .p-inline-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
}

.p-inline-message .p-inline-message-icon.p-icon {
    width: 1rem;
    height: 1rem;
}

.p-inline-message .p-inline-message-text {
    font-size: 1rem;
}

.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0;
}

.p-message {
    margin: 1rem 0;
    border-radius: 4px;
}

.p-message .p-message-wrapper {
    padding: 1rem 1.25rem;
}

.p-message .p-message-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0);
    transition: box-shadow 0.15s;
}

.p-message .p-message-close:hover {
    background: rgba(255, 255, 255, 0.3);
}

.p-message .p-message-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-message.p-message-info {
    background: #cce5ff;
    border: solid #b8daff;
    border-width: 1px;
    color: #004085;
}

.p-message.p-message-info .p-message-icon {
    color: #004085;
}

.p-message.p-message-info .p-message-close {
    color: #004085;
}

.p-message.p-message-success {
    background: #d4edda;
    border: solid #c3e6cb;
    border-width: 1px;
    color: #155724;
}

.p-message.p-message-success .p-message-icon {
    color: #155724;
}

.p-message.p-message-success .p-message-close {
    color: #155724;
}

.p-message.p-message-warn {
    background: #fff3cd;
    border: solid #ffeeba;
    border-width: 1px;
    color: #856404;
}

.p-message.p-message-warn .p-message-icon {
    color: #856404;
}

.p-message.p-message-warn .p-message-close {
    color: #856404;
}

.p-message.p-message-error {
    background: #f8d7da;
    border: solid #f5c6cb;
    border-width: 1px;
    color: #721c24;
}

.p-message.p-message-error .p-message-icon {
    color: #721c24;
}

.p-message.p-message-error .p-message-close {
    color: #721c24;
}

.p-message .p-message-text {
    font-size: 1rem;
    font-weight: 500;
}

.p-message .p-message-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
}

.p-message .p-message-icon.p-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.p-toast {
    opacity: 1;
}

.p-toast .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.p-toast .p-toast-message .p-toast-message-content {
    padding: 1rem;
    border-width: 0;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 0 0 1rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 2rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon.p-icon {
    width: 2rem;
    height: 2rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-weight: 700;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: 0.5rem 0 0 0;
}

.p-toast .p-toast-message .p-toast-icon-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0);
    transition: box-shadow 0.15s;
}

.p-toast .p-toast-message .p-toast-icon-close:hover {
    background: rgba(255, 255, 255, 0.3);
}

.p-toast .p-toast-message .p-toast-icon-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-toast .p-toast-message.p-toast-message-info {
    background: #cce5ff;
    border: solid #b8daff;
    border-width: 1px;
    color: #004085;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
    color: #004085;
}

.p-toast .p-toast-message.p-toast-message-success {
    background: #d4edda;
    border: solid #c3e6cb;
    border-width: 1px;
    color: #155724;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    color: #155724;
}

.p-toast .p-toast-message.p-toast-message-warn {
    background: #fff3cd;
    border: solid #ffeeba;
    border-width: 1px;
    color: #856404;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
    color: #856404;
}

.p-toast .p-toast-message.p-toast-message-error {
    background: #f8d7da;
    border: solid #f5c6cb;
    border-width: 1px;
    color: #721c24;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: #721c24;
}

.p-galleria .p-galleria-close {
    margin: 0.5rem;
    background: rgba(0, 0, 0, 0);
    color: #efefef;
    width: 4rem;
    height: 4rem;
    transition: box-shadow 0.15s;
    border-radius: 4px;
}

.p-galleria .p-galleria-close .p-galleria-close-icon {
    font-size: 2rem;
}

.p-galleria .p-galleria-close .p-galleria-close-icon.p-icon {
    width: 2rem;
    height: 2rem;
}

.p-galleria .p-galleria-close:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #efefef;
}

.p-galleria .p-galleria-item-nav {
    background: rgba(0, 0, 0, 0);
    color: #efefef;
    width: 4rem;
    height: 4rem;
    transition: box-shadow 0.15s;
    border-radius: 4px;
    margin: 0 0.5rem;
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 2rem;
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon.p-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon.p-icon {
    width: 2rem;
    height: 2rem;
}

.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.1);
    color: #efefef;
}

.p-galleria .p-galleria-caption {
    background: rgba(0, 0, 0, 0.5);
    color: #efefef;
    padding: 1rem;
}

.p-galleria .p-galleria-indicators {
    padding: 1rem;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: #e9ecef;
    width: 1rem;
    height: 1rem;
    transition: box-shadow 0.15s;
    border-radius: 4px;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
    background: #dee2e6;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: var(--primary-color);
    color: #fff;
}

.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
.p-galleria.p-galleria-indicators-top .p-galleria-indicator {
    margin-right: 0.5rem;
}

.p-galleria.p-galleria-indicators-left .p-galleria-indicator,
.p-galleria.p-galleria-indicators-right .p-galleria-indicator {
    margin-bottom: 0.5rem;
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: rgba(0, 0, 0, 0.5);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
    background: rgba(255, 255, 255, 0.4);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
    background: rgba(255, 255, 255, 0.6);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: var(--primary-color);
    color: #fff;
}

.p-galleria .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, 0.9);
    padding: 1rem 0.25rem;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    margin: 0.5rem;
    background-color: rgba(0, 0, 0, 0);
    color: #efefef;
    width: 2rem;
    height: 2rem;
    transition: box-shadow 0.15s;
    border-radius: 4px;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #efefef;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
    transition: box-shadow 0.15s;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-galleria-mask {
    --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-mask {
    --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-preview-indicator {
    background-color: rgba(0, 0, 0, 0);
    color: #f8f9fa;
    transition: box-shadow 0.15s;
}

.p-image-preview-indicator .p-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.p-image-preview-container:hover>.p-image-preview-indicator {
    background-color: rgba(0, 0, 0, 0.5);
}

.p-image-toolbar {
    padding: 1rem;
}

.p-image-action.p-link {
    color: #f8f9fa;
    background-color: rgba(0, 0, 0, 0);
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    transition: box-shadow 0.15s;
    margin-right: 0.5rem;
}

.p-image-action.p-link:last-child {
    margin-right: 0;
}

.p-image-action.p-link:hover {
    color: #f8f9fa;
    background-color: rgba(255, 255, 255, 0.1);
}

.p-image-action.p-link span {
    font-size: 1.5rem;
}

.p-image-action.p-link .p-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.p-avatar {
    background-color: #dee2e6;
    border-radius: 4px;
}

.p-avatar.p-avatar-lg {
    width: 1rem;
    height: 1rem;
    font-size: 1.5rem;
}

.p-avatar.p-avatar-lg .p-avatar-icon {
    font-size: 1.5rem;
}

.p-avatar.p-avatar-xl {
    width: 2rem;
    height: 2rem;
    font-size: 2rem;
}

.p-avatar.p-avatar-xl .p-avatar-icon {
    font-size: 1rem;
}

.p-avatar-group .p-avatar {
    border: 2px solid #fff;
}

.p-chip {
    background-color: #dee2e6;
    color: #212529;
    border-radius: 16px;
    padding: 0 0.75rem;
}

.p-chip .p-chip-text {
    line-height: 1.5;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.p-chip .p-chip-icon {
    margin-right: 0.5rem;
}

.p-chip img {
    width: 2rem;
    height: 2rem;
    margin-left: -0.75rem;
    margin-right: 0.5rem;
}

.p-chip .p-chip-remove-icon {
    border-radius: 4px;
    transition: box-shadow 0.15s;
    margin-left: 0.5rem;
}

.p-chip .p-chip-remove-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-scrolltop {
    width: 3rem;
    height: 3rem;
    border-radius: 4px;
    box-shadow: none;
    transition: box-shadow 0.15s;
}

.p-scrolltop.p-link {
    background: rgba(0, 0, 0, 0.7);
}

.p-scrolltop.p-link:hover {
    background: rgba(0, 0, 0, 0.8);
}

.p-scrolltop .p-scrolltop-icon {
    font-size: 1.5rem;
    color: #efefef;
}

.p-scrolltop .p-scrolltop-icon.p-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.p-skeleton {
    background-color: #e9ecef;
    border-radius: 4px;
}

.p-skeleton:after {
    background: linear-gradient(90deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.4),
            rgba(255, 255, 255, 0));
}

.p-tag {
    background: var(--primary-color);
    color: #fff;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 4px;
}

.p-tag.p-tag-success {
    background-color: #28a745;
    color: #fff;
}

.p-tag.p-tag-info {
    background-color: #17a2b8;
    color: #fff;
}

.p-tag.p-tag-warning {
    background-color: #ffc107;
    color: #212529;
}

.p-tag.p-tag-danger {
    background-color: #dc3545;
    color: #fff;
}

.p-tag .p-tag-icon {
    margin-right: 0.25rem;
    font-size: 0.75rem;
}

.p-tag .p-tag-icon.p-icon {
    width: 0.75rem;
    height: 0.75rem;
}

.p-inplace .p-inplace-display {
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: #e9ecef;
    color: #212529;
}

.p-inplace .p-inplace-display:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-progressbar {
    border: 0 none;
    height: 1.5rem;
    background: #e9ecef;
    border-radius: 4px;
}

.p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: var(--primary-color);
}

.p-progressbar .p-progressbar-label {
    color: #fff;
    line-height: 1.5rem;
}

.p-terminal {
    background: #fff;
    color: #212529;
    border: 1px solid #dee2e6;
    padding: 1.25rem;
}

.p-terminal .p-terminal-input {}

.p-badge {
    background: var(--primary-color);
    color: #fff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
}

.p-badge.p-badge-secondary {
    background-color: #6c757d;
    color: #fff;
}

.p-badge.p-badge-success {
    background-color: #28a745;
    color: #fff;
}

.p-badge.p-badge-info {
    background-color: #17a2b8;
    color: #fff;
}

.p-badge.p-badge-warning {
    background-color: #ffc107;
    color: #212529;
}

.p-badge.p-badge-danger {
    background-color: #dc3545;
    color: #fff;
}

.p-badge.p-badge-lg {
    font-size: 1.125rem;
    min-width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
}

.p-badge.p-badge-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem;
}

.p-tag {
    background: var(--primary-color);
    color: #fff;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 4px;
}

.p-tag.p-tag-success {
    background-color: #28a745;
    color: #fff;
}

.p-tag.p-tag-info {
    background-color: #17a2b8;
    color: #fff;
}

.p-tag.p-tag-warning {
    background-color: #ffc107;
    color: #212529;
}

.p-tag.p-tag-danger {
    background-color: #dc3545;
    color: #fff;
}

.p-breadcrumb .p-breadcrumb-chevron {
    margin-left: 12px;

}

.p-breadcrumb .p-breadcrumb-chevron:before {
    content: "/";
}

.sm\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.p-panelmenu .p-panelmenu-header>a .p-panelmenu-icon {
    margin-right: 0.7rem;
}

.p-panelmenu .p-panelmenu-header>a .p-menuitem-icon {
    margin-right: 0.7rem;
}

.p-panelmenu .p-panelmenu-header>a:focus {
    outline: 0 none;
    outline-offset: 0;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a:hover {
    background: var(--primary-color);
    color: var(--clr-heading);
}

.p-panelmenu .p-panelmenu-header.p-highlight>a {
    background: var(--primary-color);
    color: var(--clr-heading);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled)>a:hover {
    background: var(--primary-color);
    color: var(--clr-text);
}

.p-panelmenu-icon {
    order: 1;
    margin-right: 20px;
    width: 6%;
    float: right;
    color: var(--clr-heading);
}


.p-panelmenu .p-panelmenu-header>a {
    padding: .9rem;
    border: 1px solid #dee2e6;
    color: var(--clr-text);
    font-weight: 500;
    border-radius: 6px;
    transition: box-shadow 0.2s;

}

.p-panelmenu .p-panelmenu-header>a .p-panelmenu-icon {
    margin-right: 0.25rem;
    width: 10px;

}

.p-panelmenu .p-panelmenu-header>a .p-menuitem-icon {
    margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header>a:focus {
    outline: 0 none;
    outline-offset: 0;
    /* box-shadow: 0 0 0 0.2rem #C7D2FE; */
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a:hover {
    background: var(--primary-color);
    /* border-color: #dee2e6; */
    color: var(--clr-heading);
}

.p-panelmenu .p-panelmenu-header.p-highlight {
    margin-bottom: 0;
}

.p-panelmenu .p-menuitem-link {
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    text-decoration: none;
    color: var(--clr-text);
}

.p-panelmenu .p-panelmenu-content {
    padding: 0 0 0 3em;
    background: var(--primary-color);
    color: var(--clr-text);
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
    padding: 13px 0px 13px 0px;
    /* color: var(--clr-text); */
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;

}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text:active {
    color: red;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;

}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
    color: var(--clr-text);
    margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
    color: black;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
    background: var(--primary-color);

}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: var(--primary-color-text);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: black;

}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: blue;

}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    color: var(--primary-color-text);
    /* background-color: var(--primary-light); */
    box-shadow: inset 0 0 0 0.15rem var(--primary-color);
}


.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
    margin-right: 1rem;
    width: 10px;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
    padding: 0 0 0 1rem;

}

.p-panelmenu .p-menuitem-link:focus {
    color: var(--primary-color-text);
}

.p-panelmenu .p-menuitem-link:active {
    color: var(--primary-color-text);
}

.p-panelmenu p-panelmenu-content .p-menuitem-link .ul .li .p-menuitem-text:active {
    color: var(--primary-color-text);
}

.p-panelmenu p-panelmenu-content .p-menuitem-link .ul .li .p-menuitem-text:focus {
    color: var(--primary-color-text);
}

.p-panelmenu .p-panelmenu-panel {
    margin-bottom: 2px;
}

.p-toggleable-content-enter {
    max-height: 0;

}

.p-toggleable-content-enter-active {
    overflow: hidden;
    max-height: 1000px;
    transition: max-height 1s ease-in-out;


}

.p-toggleable-content-enter-done {
    transform: none
}

.p-toggleable-content-exit {
    max-height: 1000px
}

.p-toggleable-content-exit-active {
    overflow: hidden;
    max-height: 0;
    transition: max-height .45s cubic-bezier(0, 1, 0, 1)
}

.p-menuitem-text {
    flex-grow: 1;
    margin-left: 12px;
}




.layout-topbar-icons button {
    margin-left: 20px;
}



.field>label {
    display: inline-block;
    margin-bottom: 0.2rem;
}

.layout-topbar .layout-topbar-icons {
    float: right;
    font-size: 15px;
    display: block;
    animation-duration: 0.5s;
    padding-top: 16px;
}

.layout-topbar .layout-topbar-icons .pi {
    font-size: 18px;
}

p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even) {
    background: var(--gray-100);
}

.p-datatable .p-datatable-tbody>tr {
    background: var(--primary-color-text);
}

.p-toolbar-group-end p-toolbar-group-right {
    margin-left: 10px;
}

.p-datatable .p-column-header-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.layout-profile img {
    border-radius: 5px;
    max-height: 100px;
}

.profile-branch {
    text-align: center;
}

.profile-branch>.p-link {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: var(--clr-heading);
    margin-bottom: 5px;
}



/* General */
/* Menu Common */
/* Menu Light */
/* Menu Dark */
/* Topbar */
/* Footer */
.splash-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    background: var(--primary-color);
    background: linear-gradient(to right, var(--primary-color) 0%, var(--primary-light) 100%);
}

.splash-screen .splash-container {
    width: 40px;
    height: 40px;
    margin: 0px auto;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
}

.splash-screen .splash-double-bounce1,
.splash-screen .splash-double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: splash-bounce 2s infinite ease-in-out;
}

.splash-screen .splash-double-bounce2 {
    animation-delay: -1s;
}

@keyframes splash-bounce {

    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}



html {
    height: 100%;
}

body {
    font-family: "Open Sans", Helvetica Neue, sans-serif;
    font-size: 12.5px;
    color: var(--clr-heading);
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
}

a {
    text-decoration: none;
}

.layout-wrapper {
    padding: 0;
    min-height: 100vh;
}

.p-dropdown-label {
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 13px !important;
}

.p-dropdown-item {
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 13px !important;
    z-index: 1000001;
}

.datatable-header {
    border-color: 1px #dedede !important;
}

.p-datatable .p-sortable-column-icon {
    color: var(--clr-text) !important;
}

.p-datatable table {
    min-width: 150px;
}

.p-datatable-header {
    display: inline-block;
    width: 100%;
    margin-bottom: -4px;
}

.p-datatable-header h3 {
    font-weight: 500;
    padding-top: 0px;
    margin: 1px;
    font-size: 15px;
    text-align: center !important;
}

.p-treetable .p-sortable-column-icon {
    vertical-align: middle;
    color: var(--clr-text) !important;
}

.p-treetable-footer {
    background-color: #fdfefe !important;
    min-height: 42px;
    vertical-align: middle;
}

.swal2-container {
    z-index: 100005;
}

.p-dialog .p-dialog-titlebar {
    padding: 0.5em 0.5em !important;
    border: 0;
    padding-left: 0px;
}

.p-dialog {
    position: absolute !important;
    padding: 0 !important;
    padding-left: -40px !important;
    z-index: 100001;
}

.p-dialog-footer {
    padding: 0em !important;
    border-width: 0 0 0 0;
    text-align: right;
}

.p-dialog .p-dialog-content {
    position: relative;
    border: 0;
    padding: -10px !important;
    zoom: 1;
    overflow-y: auto;
}

.p-tree {
    width: 100%;
    height: 100%;
    text-align: left !important;
    font-size: 12px !important;
}

.tree-selection-scroll-panel {
    width: 100%;
    height: 300px;
}

.p-inputgroup {
    padding: 0px;
    margin-bottom: -1px !important;
    float: left;
}

/* .p-toolbar-group-left {
  width: 100%;
} */
.p-toolbar-group-left h2 {
    padding: 4px 2px 0px 2px;
    vertical-align: bottom !important;
    font-size: 15px;
}

.p-toolbar-group-right {
    float: right;
}

.vnheader {
    display: flex;
}

.p-datepicker .p-datepicker-header {
    position: relative;
    padding: 0.5em !important;
}

.p-datepicker .p-datepicker-month {
    position: relative;
    padding: 0.75em !important;
}

.p-datepicker .p-datepicker-year {
    position: relative;
    padding: 0.25em !important;
    margin-left: 0.25em !important;
    width: 80px;
    border-color: #d8d8d8;
}

.p-datepicker th {
    padding: 0.2em !important;
    text-align: center;
    border: 0;
}

.p-timepicker {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.5em 0;
    font-size: 11px !important;
}

.p-timepicker>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0.25em;
    min-width: 1.5em;
}

.p-menubar {
    padding: 6px;
    margin: -1px;
    background-color: #edf0f5 !important;
}

.p-menubar .p-menuitem-text {
    vertical-align: middle;
    font-size: 12.5px;
    font-weight: 550;
    padding: 0%;
}

.p-menubar .p-menubar-root-list {
    display: inline-block;
    position: relative;
}

.p-menubar .p-submenu-list {
    display: none;
    position: absolute;
    min-width: 15em;
    padding: 0px !important;
}

.p-menubar .p-submenu-list .p-menuitem {
    margin-top: -1px !important;
    margin-bottom: -4px !important;
    position: relative;
    padding-top: 0px !important;
}

.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 0px !important;
    padding-left: 6px !important;
    max-width: 64px !important;
    height: 18px;
    cursor: pointer;
    white-space: nowrap;
    display: block;
    font-size: 11px;
}

.p-fieldset {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 2px;
    margin-bottom: 4px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 0px;
}

.p-fieldset .p-fieldset-legend {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.cs-panel {
    width: 100%;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: -5px;
}

.cs-panel-section {
    overflow-y: auto;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: -5px;
}

.cs-tab-menu {
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 2px;
}

.cs-tab-menu-item {
    font-weight: bold;
    background-color: blue !important;
}

.cs-tab-menu-item-disabled {
    background-color: #fefefe;
}

.cs-dialog {
    z-index: 100001;
    max-width: 1050px;
    max-height: 675px;
}

.cs-dialog-1 {
    min-height: 200px;
}

.cs-dialog-form-item {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    width: 100%;
    margin-top: -5px;
    margin-left: -5px;
    margin-right: -5px;
    border-radius: 3px;
}

.cs-details-section-1 {
    position: relative;
    justify-items: center;
    width: 100%;
    min-width: 350px !important;
    min-height: 100px;
    padding-top: 15px;
    padding-left: 5px;
    padding-right: 5px;
}

.cs-details-section {
    justify-items: center;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    border-top: 0.5px solid #d8d8d8;
    border-bottom: 0.5px solid #d8d8d8;
    border-left: 0.5px solid #d8d8d8;
    border-right: 0.5px solid #d8d8d8;
    background-color: #fafafa;
}

.cs-details-first-section {
    justify-items: center;
    width: 25%;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    border-top: 0.5px solid #d8d8d8;
    border-bottom: 0.5px solid #d8d8d8;
    border-left: 0.5px solid #d8d8d8;
    background-color: #fafafa;
}

.cs-details-even-section {
    justify-items: center;
    width: 25%;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    border-top: 0.5px solid #d8d8d8;
    border-bottom: 0.5px solid #d8d8d8;
    border-left: 0.5px solid #d8d8d8;
    background-color: white;
}

.cs-details-odd-section {
    justify-items: center;
    width: 25%;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    border-top: 0.5px solid #d8d8d8;
    border-bottom: 0.5px solid #d8d8d8;
    border-left: 0.5px solid #d8d8d8;
    background-color: #fafafa;
}

.cs-details-last-section {
    width: 25%;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    border-top: 0.5px solid #d8d8d8;
    border-bottom: 0.5px solid #d8d8d8;
    border-left: 0.5px solid #d8d8d8;
    border-right: 0.5px solid #d8d8d8;
    background-color: white;
}

.cs-details-section-control {
    width: 95%;
    min-width: 170px;
    height: 35px;
    text-align: left;
    margin-top: 0.15em;
    margin-bottom: 0.15em;
    margin-left: 0em;
    margin-right: 0em;
    font-size: 13px !important;
}

.cs-details-section-control .p-dropdown-panel {
    font-size: 13px !important;
    z-index: 1000001;
}

.cs-details-section-control .p-dropdown-item {
    position: relative;
    font-weight: normal;
    font-size: 13px !important;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    border: 0 none;
    cursor: pointer;
    margin: 1px 0;
    padding: 0.125em 0.15em;
    z-index: 1000001;
}

.cs-details-section-control .p-dropdown-label {
    display: block;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    font-weight: normal;
    font-size: 13px !important;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    width: 100%;
    padding-right: 1.5em;
}

.cs-details-section-control-1 {
    width: 100%;
    min-width: 250px;
    height: 35px;
    text-align: left;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    margin-left: 0em;
    margin-right: 0em;
    font-size: 13px !important;
}

.cs-details-section-control-1 .p-dropdown-panel {
    font-size: 13px !important;
    z-index: 1000001;
}

.cs-details-section-control-1 .p-dropdown-item {
    position: relative;
    font-weight: normal;
    font-size: 13px !important;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    border: 0 none;
    cursor: pointer;
    margin: 1px 0;
    padding: 0.125em 0.15em;
    z-index: 1000001;
}

.cs-details-section-control-1 .p-dropdown-label {
    display: block;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    font-weight: normal;
    font-size: 13px !important;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    width: 100%;
    padding-right: 1.5em;
}

.cs-details-section-text-area {
    width: 95%;
    height: 99px;
    resize: none;
}

.p-inputtextarea {
    font-size: 13px !important;
    font-family: "Open Sans", Helvetica Neue, sans-serif;
}

.cs-details-section-label {
    height: 15px;
    width: 100%;
    text-align: left;
    padding-left: 10px;
}

.cs-dialog-footer {
    width: 100%;
    align-content: right;
    padding: 4px;
    background-color: #f1f1f1;
}

.cs-cli-script-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.cs-cli-script {
    width: 40% !important;
    height: 100%;
    min-height: 300px;
    overflow-y: auto;
    padding: 10px;
    background-color: #f1f1f1;
    margin-right: 10px;
    resize: none;
}

.cs-cli-criteria {
    width: 60%;
    height: 100%;
    overflow-y: auto;
}

.cs-query-group {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    width: 100%;
}

.cs-query {
    width: 40%;
    height: 100%;
    overflow-y: auto;
    margin-right: 10px;
}

.cs-query-script {
    width: 100% !important;
    height: 100%;
    min-height: 270px;
    overflow-y: auto;
    padding: 10px;
    background-color: #f1f1f1;
    resize: none;
}

.cs-query-criteria {
    width: 60%;
    height: 100%;
    overflow-y: auto;
}

.cs-query-form-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    border-top: 0.5px solid #d8d8d8;
    border-right: 0.5px solid #d8d8d8;
    border-left: 0.5px solid #d8d8d8;
    background-color: #fafafa;
}

.cs-query-section-label {
    height: 15px;
    text-align: left;
    margin-right: 10px;
}

.cs-query-section-control {
    width: 220px;
    height: 32px;
}

.cs-details-auto-complete {
    width: 220px !important;
}

.cs-query-form-item-group {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 10px;
    border-top: 0.5px solid #d8d8d8;
    border-right: 0.5px solid #d8d8d8;
    border-left: 0.5px solid #d8d8d8;
    background-color: #fafafa;
}

.cs-dao-form-item-group {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 10px;
    border-top: 0.5px solid #d8d8d8;
    border-right: 0.5px solid #d8d8d8;
    border-left: 0.5px solid #d8d8d8;
    background-color: #fafafa;
}

.cs-dao-form-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cs-dao-section-label {
    height: 15px;
    padding-right: 10px;
    text-align: left;
    padding-left: 15px;
}

.p-multiselect {
    display: inline-block;
    position: relative;
    width: auto;
    cursor: pointer;
    z-index: 1000001;
}

.p-multiselect .p-multiselect-label {
    display: block;
    padding: 0.25em 2em 0.25em 0.25em;
    border: none;
    font-weight: normal;
    font-size: 12px;
    width: 100%;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    z-index: 1000001;
}

.p-multiselect-header {
    margin-bottom: 0.3em;
    padding: 0.25em;
    position: relative;
    text-align: left;
    z-index: 1000001;
}

.p-accordion {
    width: 100%;
}

.p-accordion .p-accordion-header-text {
    vertical-align: middle;
    font-size: 12.5px;
    margin-left: 1px !important;
    margin-right: 1px !important;
}

.p-accordion .p-accordion-toggle-icon {
    vertical-align: middle;
    margin-left: -10px;
}

.p-accordion .p-accordion-content {
    width: 100%;
    padding: 0em;
    border-top: 0;
    zoom: 1;
}

.layout-topbar {
    position: fixed;
    height: 58px;
    top: 0;
    left: 0;
    padding: 0.4em 1.5em 0em 1.5em;
    color: var(--clr-text);
    z-index: 999;
    right: 0;
    background-image: linear-gradient(to left, var(--primary-color), var(--primary-light));
    transition: left 0.2s;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);

}

.layout-topbar:after {
    content: "";
    display: table;
    clear: both;
}

.layout-topbar .layout-navigation {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    width: calc(100% - 190px);
}

.layout-topbar .layout-navigation span.layout-topbar-item-text {
    padding-left: 7px;
    font-size: 24px;
    margin-left: 5px;
    margin-top: -4px;
}

.layout-topbar .layout-topbar-icons {
    float: right;
    display: block;
    animation-duration: 0.5s;
    padding-top: 16px;
    width: 170px;
}

.layout-topbar .layout-topbar-icons button {
    position: relative;
    color: var(--clr-text);
    display: inline-block;
    text-decoration: none;
    overflow: visible;
    transition: color 0.2s;
}

.layout-topbar .layout-topbar-icons button:hover {
    color: var(--clr-heading);
}

.layout-topbar .layout-topbar-icons button span.layout-topbar-icon {
    font-size: 26px;
}

.layout-topbar .layout-topbar-icons button span.layout-topbar-item-text {
    padding-bottom: 5px;
    vertical-align: top;
    font-size: 14px;
    display: none;
}

.layout-topbar .layout-topbar-icons button span.layout-topbar-badge {
    position: absolute;
    font-size: 10px;
    right: -5px;
    top: -5px;
    width: 12px;
    height: 13px;
    text-align: center;
    line-height: 12px;
    color: #ffffff;
    background-color: #ef6262;
    border-radius: 50%;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search {
    padding: 0;
    position: relative;
    display: inline-block;
    top: -4px;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search input {
    display: inline-block;
    border: 0 none;
    font-size: 13px;
    background: transparent;
    border-bottom: 2px solid #ffffff;
    outline: 0 none;
    box-shadow: none;
    color: #ffffff;
    width: 100px;
    padding: 1px 20px 1px 1px;
    margin: 0px;
    border-radius: 2px;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search input::-webkit-input-placeholder {
    color: #ffffff;
    opacity: 0.7;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search input:-moz-placeholder {
    color: #ffffff;
    opacity: 0.7;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search input::-moz-placeholder {
    color: #ffffff;
    opacity: 0.7;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search input:-ms-input-placeholder {
    color: #ffffff;
    opacity: 0.7;
    -ms-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search .layout-topbar-search-icon {
    font-size: 18px;
    position: absolute;
    top: -1px;
    right: 0px;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search:hover input {
    border-bottom-color: #c3e8fb;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search:hover input::-webkit-input-placeholder {
    opacity: 1;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search:hover input:-moz-placeholder {
    opacity: 1;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search:hover input::-moz-placeholder {
    opacity: 1;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search:hover input:-ms-input-placeholder {
    opacity: 1;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search:hover .layout-topbar-search-icon {
    color: #c3e8fb;
}

.layout-topbar .layout-menu-button {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: var(--clr-text);
    transition: color 0.2s;
}

.layout-topbar .layout-menu-button span {
    vertical-align: middle;
    font-size: 18px;
}

.layout-topbar .layout-menu-button:hover {
    color: #c3e8fb;
}

.layout-topbar button {
    cursor: pointer;
}

.layout-sidebar {
    position: fixed;
    width: 250px;
    height: 100%;
    z-index: 999;
    overflow-y: auto;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    transition: left 0.2s;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

.layout-sidebar .layout-logo {
    text-align: center;
    margin-top: 24px;
}

.layout-sidebar .menuitem-badge {
    display: inline-block;
    margin-left: 4px;
    font-size: 10px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    color: rgb(59, 70, 223);
    background-color: #007be5;
    border-radius: 50%;
}

.layout-profile {
    text-align: center;
    padding-top: 7px;
    padding-right: 5px;
    padding-bottom: 2px;
}

.layout-profile img {
    max-height: 100px;
}

.layout-profile .layout-profile-link {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0.5em;
    transition: color 0.2s;
}

.layout-profile .layout-profile-link i {
    display: inline-block;
    font-size: 16px;
    vertical-align: middle;
}

.layout-profile ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    transition-property: max-height;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

.layout-profile ul.layout-profile-expanded {
    max-height: 500px;
}

.layout-profile ul li button {
    width: 100%;
    padding: 1em;
    border: 0 none;
    border-radius: 0;
    cursor: pointer;
    transition: color 0.2s;
}

.layout-profile ul li button:hover {
    color: #ffffff;
}

.layout-profile ul li button span {
    margin-left: 0.25em;
    vertical-align: middle;
}

.layout-profile ul li button i {
    vertical-align: middle;
}

.profile-user {
    width: 100%;
    text-align: center;
    padding-bottom: 5px;
    cursor: pointer;
}

.profile-user button {
    color: var(--clr-text);
    text-align: center;
    font-weight: bold !important;
}

.layout-sidebar-light .layout-profile .layout-profile-link {
    color: #000000;
}

.layout-sidebar-light .layout-profile .layout-profile-link:hover {
    color: #ffffff;
}

.layout-sidebar-light .layout-profile ul {
    background-color: var(--primary-color);
}

.layout-sidebar-light .layout-profile ul li button {
    color: #000000;
}

.layout-sidebar-light .layout-profile ul li button:hover {
    color: #ffffff;
}

.layout-sidebar-dark .layout-profile .layout-profile-link {
    color: #ffffff;
}

.layout-sidebar-dark .layout-profile .layout-profile-link:hover {
    color: var(--primary-color);
}

.layout-sidebar-dark .layout-profile ul {
    background-color: #2e3035;
}

.layout-sidebar-dark .layout-profile ul li button {
    color: #ffffff;
}

.layout-sidebar-dark .layout-profile ul li button:hover {
    color: var(--primary-color);
}

.layout-menu-container {
    padding-bottom: 40px;
}

.layout-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.layout-menu li a {
    cursor: pointer;
    position: relative;
    text-decoration: none;
    display: block;
    transition: color 0.2s;
}

.layout-menu li a i {
    font-size: 16px;
    vertical-align: middle;
}

.layout-menu li a span {
    margin-left: 0.25em;
    padding-left: 5px;
    vertical-align: middle;
    line-height: 16px;
    display: inline-block;
}

.layout-menu li a .menuitem-toggle-icon {
    position: absolute;
    top: 50%;
    right: 0.2em;
    margin-top: -9px;
}

.layout-menu li a.router-link-active {
    font-weight: 700;
}

.layout-menu li.active-menuitem>a .menuitem-toggle-icon:before {
    content: "\e933";
}

.layout-menu li.active-menuitem>ul {
    max-height: 500px;
}

.layout-menu>li>a {
    padding: 1em;
}

.layout-menu>li>a span {
    font-size: 12.5px;
}

.layout-menu>li:last-child>a {
    border-bottom: 1px solid #ffffff;
}

.layout-menu>li ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-left: 1.5em;
    max-height: 0;
    overflow: hidden;
    transition-property: max-height;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

.layout-menu>li ul li a {
    cursor: pointer;
    padding: 0.6em 0.6em;
    border-top: 0 none;
}

.layout-menu>li ul li a span {
    font-size: 12.5px;
}

.layout-menu>li ul li:last-child {
    padding-bottom: 0.6em;
}

.layout-sidebar-light {
    background: var(--primary-color, 50%);
    background: linear-gradient(to bottom, var(--primary-light) 25%, var(--primary-color));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#000000", GradientType=0);
    z-index: 9;
}

.layout-sidebar-light .layout-menu>li>a {
    border-top: 1px solid #ffffff;
}

.layout-sidebar-light .layout-menu>li:last-child>a {
    border-bottom: 1px solid #ffffff;
}

.layout-sidebar-light .layout-menu>li ul {
    background-color: rgb(59, 152, 223);
}

.layout-sidebar-light .layout-menu li a {
    color: #000000;
}

.layout-sidebar-light .layout-menu li a.router-link-active {
    color: #1fa1fc;
}

.layout-sidebar-light .layout-menu li a:hover {
    color: #ffffff;
}

.layout-sidebar-light .layout-menu li.active-menuitem>a {
    background-color: rgb(59, 152, 223);
    color: #ffffff;
}

.layout-sidebar-dark {
    background: #4d505b;
    background: linear-gradient(to bottom, #4d505b 0%, #3b3e47 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#000000", GradientType=0);
}

.layout-sidebar-dark .layout-menu>li>a {
    border-top: 1px solid rgba(52, 56, 65, 0.6);
}

.layout-sidebar-dark .layout-menu>li:last-child>a {
    border-bottom: 1px solid rgba(52, 56, 65, 0.6);
}

.layout-sidebar-dark .layout-menu>li ul {
    background-color: #2e3035;
}

.layout-sidebar-dark .layout-menu li a {
    color: #ffffff;
}

.layout-sidebar-dark .layout-menu li a.router-link-active {
    color: #1fa1fc;
}

.layout-sidebar-dark .layout-menu li a:hover {
    color: var(--primary-color);
}

.layout-sidebar-dark .layout-menu li.active-menuitem>a {
    background-color: #2e3035;
    color: var(--primary-color);
}

.layout-main {
    position: fixed;
    padding-left: 3px;
    padding-right: 3px !important;
    padding-bottom: 3px;
    top: 60px !important;
    left: 1px;
    right: 2px;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    height: 92%;
    min-width: calc(100% - 250px);
    background-color: #ffffff;
    transition: margin-left 0.2s;
}

.container {
    display: flex;
}

.header-photo {
    font-size: 8px;
    width: 18px !important;
    height: 16px;
    position: relative;
    margin: 1px !important;
    top: -60px;
    right: -5px;
}

.cs-image-box {
    border: 0.005ex solid #ababec !important;
    background-color: #f9f9f9;
    justify-content: center;
    align-items: center;
    max-height: 150;
    max-width: 150;
    display: flex;
    flex-direction: column;
}

.cs-image-buttonbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.cs-image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cs-image-container .header-photo-1 {
    width: 20px !important;
    height: 20px;
    margin: 5px;
}

.cs-image-container .header-photo-2 {
    width: 20px !important;
    height: 20px;
    margin: 5px;
}

.cs-image {
    width: 550px;
    height: 140px;
}

.form-layout {
    flex: 1;
    overflow-y: scroll;
}

.form-criteria {
    display: flex;
    background-color: #ffffff;
    padding: 2px;
    margin-top: 0px;
    margin-bottom: 0px;
    align-items: center !important;
    border-radius: 3px;
}

.form-item {
    /* margin: -7px; */
    padding: 1px !important;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    width: 100%;
    /* //    max-width       : 400px; */
    /* margin: auto; */
    border-radius: 3px;
}

.form-item-1 {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    border-radius: 3px;
}

.form-item-2 {
    margin-top: 0px !important;
    margin-bottom: -10px !important;
    margin-left: -10px !important;
    margin-right: -10px !important;
    padding: 2px;
    overflow: auto;
    height: 400px;
    min-width: 550px;
    display: flex;
    flex-direction: row;
    justify-content: center !important;
}

.form-item-3 {
    margin-top: -13px !important;
    margin-left: -4px !important;
    margin-right: -8px !important;
    margin-bottom: 0px !important;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    width: 100%;
    margin: auto;
    border-radius: 3px;
}

.form-item-4 {
    margin-left: 0px !important;
    margin-bottom: 0px !important;
    margin-top: 5px !important;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    width: 100% !important;
    border-radius: 3px;
}

.form-item-5 {
    margin-left: -2px;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    width: 100% !important;
    border-radius: 3px;
}

.form-item-6 {
    margin-top: 5px !important;
    margin-left: 3px !important;
    margin-right: 1px !important;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    width: 100% !important;
    min-width: 300px;
    border-radius: 3px;
}

.form-item-7 {
    padding: 2px;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    width: 100% !important;
    border-radius: 3px;
}

.form-item-8 {
    padding: 2px;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    max-width: 269px;
    width: 100% !important;
    border-radius: 3px;
}

.form-item-9 {
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    width: 100% !important;
    height: 90% !important;
    border-radius: 3px;
}

.cs-grid-data {
    font-size: 12.5px !important;
    /* overflow-x: auto !important; */
}

.cs-grid-data .p-datatable-scrollable-body {
    padding-bottom: 0px !important;
    /* overflow-x: auto !important; */
    /* overflow-y: auto !important; */
}

.cs-grid-data .p-datatable-frozenlayout-left {
    width: 150px !important;
}

.cs-grid-data .p-datatable-footer {
    height: 45px !important;
    background-color: #edf0f5 !important;
}

.accordion-tab {
    overflow-y: auto;
    max-height: 300px;
    font-size: 11.5px;
}

.row {
    display: flex;
    z-index: 999;
}

.column {
    flex: 1;
    position: relative;
    min-width: 800px;
    min-height: 400px;
    z-index: 999;
}

#ID_ZOOM_CLIENT {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.form_control_label {
    display: block;
    font-size: 12.5px;
    font-weight: 500;
    text-align: left;
}

.form_section_label {
    display: block;
    font-size: 12.5px;
    font-weight: 500;
    text-align: right;
}

.form-item-row-3 {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    vertical-align: middle;
    justify-content: center;
}

.form-item-row {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    vertical-align: middle;
    max-width: 400px;
    justify-content: center;
    width: 100%;
}

.form-item-row-1 {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    width: 100%;
    max-width: 400px;
    justify-content: left;
}

.form-item-row-2 {
    margin-top: 0px;
    height: 35px !important;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    width: 100% !important;
    vertical-align: top !important;
    justify-content: center;
}

/*
.form-label
{
    padding             : 0.25em;
    margin              : 0;
    display             : flexbox;
    flex-direction      : column;
    justify-content     : space-around;
}
*/
.cs-date-field {
    font-size: 12.5px;
    height: 34px;
    width: 100%;
    align-items: center;
}

.cs-df-input {
    width: 80%;
}

.cs-text-input {
    font-size: 12.5 px;
    height: 34px;
    width: 100%;
}

.cs-password {
    font-size: 12.5px;
    width: 100% !important;
}

.cs-text-area {
    font-size: 12.5px !important;
    width: 100%;
    color: #495057;
}

.cs-tel-font {
    font-family: "AnupamaMedium", serif;
    font-size: "20px";
}

.cs-drop-down {
    font-size: 12.5px;
    height: 34px;
    width: 100%;
}

.cs-multi-select {
    font-size: 12.5px;
    height: 34px;
    width: 100%;
}

.cs-time-field {
    font-size: 12.5px;
    height: 34px;
    width: 100%;
}

.cs-checkbox {
    font-size: 12.5px;
    height: 50px;
}

.cs-control-label {
    justify-content: left;
    margin: 0;
    display: flexbox;
    margin-bottom: 0.2rem;
}

.cs-control {
    margin: 0.25em;
    padding: 0.25em;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: left;
    align-items: center;
}

.form-vsection1 {
    align-items: center;
}

.criteria-action {
    width: 120px !important;
    justify-content: center;
    align-items: center;
}

.form-hsection {
    border: 1px solid var(--primary-color) !important;
    background-color: var(--primary-light);
    border-collapse: collapse !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    border-left: 0 !important;
    /* border-top: 0 !important;
  border-bottom: 0 !important;
  border-bottom: 0 !important; */
}

.form-vsection {
    background-color: var(--primary-light);
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size-adjust: inherit;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0;
    width: 100%;
    /* border-top: 0 !important;
  border-bottom: 0 !important;
  margin-bottom: 10px; */
}

.card {
    background: var(--surface-card);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.form-align {
    align-items: center;
    width: 100%;
    max-width: 350px;
}

.form-control {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 0px;
    padding-right: 0px;
}

.rc-control-1 {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 0px;
    padding-right: 0px;
    height: 70px;
}

.form-control {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 0px;
    padding-right: 0px;
}

.calendar-control {
    height: "28px";
    width: "100%";
    font-size: "13px";
    margin: "0.25em";
}

.calendar-icon {
    height: "28px";
    margin: "0.15em";
}

.form-label {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    text-align: left;
    align-items: center;
}

.background {
    background-color: #f8f8f8;
    margin: -7px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 1px;
    padding-right: 1px !important;
}

.login-form-item {
    border-radius: 3px;
    margin: 8px !important;
    /* padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 0px !important; */
    display: flex;
    vertical-align: middle !important;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.carousel-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    border: 1.5px solid #a2bbd1;
    padding-top: 10px;
    margin-top: 4px;
    margin-left: 3px;
    margin-right: 8px;
}

.login-ins-form {
    margin: .5em 0 0.5em 0;
    border-top: 1px solid #d5d5d5;
    padding: 0.1em;
    margin-top: 30px;
    padding-top: 10px;
}

.login-form {
    margin: 0;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-form h1 {
    margin: 1em 0 0.5em 0;
    border-bottom: 1px solid #d5d5d5;
    padding: 0.1em;
    font-size: 20px;
    text-align: center !important;
    height: 50px;
}

.login-form h1:first-child {
    margin: 0 0 0.5em 0;
}

.login-form h2 {
    margin: 15px;
    font-size: 17px;
    text-align: center !important;
    padding-bottom: 5px;
}

.login-form h3 {
    font-size: 13px;
    font-weight: normal;
    color: crimson;
    text-align: center !important;
    padding-top: 5px;
    padding-bottom: 0px;
}

.swal2-container {
    z-index: 100000000000 !important;
}

.swal2-container .swal2-icon {
    zoom: 0.8;
}

.swal2-container .swal2-popup {
    zoom: 0.9;
}

.swal2-container .swal2-title {
    font-size: 28px;
}

.swal2-container .swal2-content {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
}

.p-picklist .p-picklist-list {
    min-width: 300px !important;
}

.p-picklist .p-button {
    height: 28px;
    width: 60px !important;
    margin-bottom: 0.5em;
}

.invoice {
    position: relative;
    background-color: #FFF;
    min-height: 680px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    max-width: 22cm;
}

.invoice .row {
    padding: 2px;
    display: flex;
    flex-direction: row;
}

.invoice .company-details {
    text-align: left;
    width: 38%;
}

.invoice .company-details div {
    font-size: 13px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-display: initial;
}

.invoice .company-details .name {
    margin-top: 0;
    margin-bottom: 0;
}

.invoice .company-details .name h2 {
    color: var(--primary-color);
}

.invoice .company-contact {
    margin-left: 10px;
    padding-left: 10px;
    text-align: left;
    margin: auto;
    width: 35%;
}

.invoice .company-contact div {
    font-size: 13px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-display: initial;
}

.invoice .logo {
    margin-left: 10px;
    text-align: left;
    width: 25%;
    margin: auto;
}

.invoice header {
    padding: 10px 0;
    margin-bottom: 7px;
    border-bottom: 1px solid var(--primary-color);
}

.invoice .title {
    width: 100%;
    height: 50px;
    margin: auto;
    padding: 8px;
    text-align: center;
    background-color: #fafafa;
    border-bottom: 1px solid #c2dff5;
}

.invoice .title h1 {
    font-weight: bold;
    color: var(--primary-color);
}

.invoice .summary {
    margin: auto;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #c2dff5;
    background-color: #fafafa !important;
}

.invoice .customer-details {
    text-align: left;
    padding: 10px;
    width: 40%;
    background-color: #fafafa !important;
}

.invoice .customer-details div {
    font-size: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    justify-content: left;
}

.invoice .challan-details {
    text-align: left;
    padding: 10px;
    width: 40%;
    background-color: #ffffff;
}

.invoice .challan-details div {
    font-size: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    justify-content: left;
}

.invoice .customer-name {
    margin-top: 2px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    border-bottom: solid 1px lavender;
}

.invoice .challan-name {
    margin-top: 2px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    border-bottom: solid 1px lavender;
}

.qrcode {
    padding-top: 17px;
    padding-bottom: 17px;
    display: grid;
    justify-items: center;
    align-items: center;
}

.invoice .qrcode {
    margin: auto;
    width: 20%;
    height: 100% !important;
    display: grid;
    justify-items: center;
}

.invoice .terms-conditions {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #c2dff5;
    padding-top: 10px;
    margin-top: 10px;
}

.invoice .terms {
    padding: 10px;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    width: 70%;
}

.invoice .terms div {
    font-size: 13px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-display: initial;
}

.invoice .conditions {
    padding: 10px;
    border-right: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    width: 30%;
    display: grid;
    align-items: center;
    font-size: 13px;
}

.invoice .terms-heading {
    margin-top: 2px;
    margin-bottom: 5px;
    color: var(--primary-color);
}

.invoice .items {
    width: 100%;
    border-collapse: collapse;
}

.invoice .items th {
    background-color: #b1cfe6;
    border-bottom: 1px solid #c8d8d8;
    border-left: 1px solid #c8d8d8;
    border-top: 1px solid #c8d8d8;
    border-right: 1px solid #c8d8d8;
    padding-left: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    height: 40px;
}

.invoice .items td {
    border-bottom: 1px solid #c8d8d8;
    border-left: 1px solid #c8d8d8;
    border-top: 1px solid #c8d8d8;
    border-right: 1px solid #c8d8d8;
    padding-left: 7px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
}

.invoice .items tr:nth-child(even) {
    background-color: #f2f2f2;
}

.invoice footer {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    color: #777;
    border-top: 1px solid #aaa;
    padding: 8px 0;
    font-size: 13px;
}

.ms {
    position: relative;
    background-color: #FFF;
    min-height: 680px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    width: 22cm;
}

.ms .column {
    padding: 2px;
    display: flex;
    flex-direction: column;
}

.ms .row {
    padding: 2px;
    display: flex;
    flex-direction: row;
}

.ms .logo {
    text-align: left;
    width: 18%;
    margin: auto;
}

.ms header {
    padding: 5px 0;
    margin-bottom: 7px;
    border-bottom: 1px solid var(--primary-color);
}

.ms .header-heading {
    width: 100%;
    height: 40px;
    margin: auto;
    padding: 2px;
    text-align: center;
}

.ms .header-heading h1 {
    font-weight: bold;
    font-size: 24px;
}

.ms marks-details {
    width: 100%;
    border-collapse: collapse;
    padding-bottom: 20px !important;
}

.ms .title {
    padding-top: 10px;
    -moz-text-align-last: center;
    text-align-last: center;
    height: 40px;
}

.ms .title h1 {
    font-weight: bold;
    font-size: 20px;
    height: 18px;
}

.ms .customer-details {
    text-align: center;
    width: 100%;
}

.ms .customer-details div {
    font-size: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    justify-content: left;
}

.ms .items {
    margin-top: 15px;
    width: 100%;
    border-collapse: collapse;
}

.ms .items th {
    background-color: #f9fcff;
    border-bottom: 1px solid #c8d8d8;
    border-left: 1px solid #c8d8d8;
    border-top: 1px solid #c8d8d8;
    border-right: 1px solid #c8d8d8;
    padding-left: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    height: 40px;
}

.ms .items td {
    border-bottom: 1px solid #c8d8d8;
    border-left: 1px solid #c8d8d8;
    border-top: 1px solid #c8d8d8;
    border-right: 1px solid #c8d8d8;
    padding-left: 7px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
}

.ms .items tr:nth-child(even) {
    background-color: #f2f2f2;
}

.ms footer {
    margin-top: 50px;
    width: 100%;
    text-align: center;
    color: #777;
    border-top: 0.5px solid #aaa;
    padding: 8px 0;
    font-size: 13px;
}

.report-title {
    padding-top: 20px;
    font-size: 18px !important;
    font-weight: bold;
    justify-content: center;
    width: 100%;
    height: 60px;
    text-align: center;
    background-color: #fafafa;
    border-bottom: 1px solid #c2dff5;
}

.report-table {
    width: 98%;
    border-collapse: collapse;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.report-table th {
    background-color: #b1cfe6;
    border-bottom: 1px solid #c8d8d8;
    border-left: 1px solid #c8d8d8;
    border-top: 1px solid #c8d8d8;
    border-right: 1px solid #c8d8d8;
    padding-left: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    height: 40px;
}

.report-table td {
    border-bottom: 1px solid #c8d8d8;
    border-left: 1px solid #c8d8d8;
    border-top: 1px solid #c8d8d8;
    border-right: 1px solid #c8d8d8;
    padding-left: 7px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

report-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.so1 {
    width: 21.2cm;
    height: 29.7cm;
    align-items: center;
    position: relative;
    background-color: #FFF;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}

.so1 footer {
    padding-top: 10px;
    padding-bottom: 10px;
}

.so1 .row {
    padding: 2px;
    display: flex;
    flex-direction: row;
}

.so1 .company-details {
    text-align: left;
    width: 70%;
}

.so1 .company-details div {
    font-size: 13px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-display: initial;
}

.so1 .company-details .name {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--clr-text);
}

.so1 .company-contact {
    margin-left: 10px;
    padding-left: 10px;
    text-align: left;
    margin: auto;
    width: 35%;
}

.so1 .company-contact div {
    font-size: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-display: initial;
}

.so1 .logo {
    margin-left: 10px;
    text-align: left;
    width: 25%;
    margin: auto;
}

.so1 header {
    padding: 10px 0;
    margin-bottom: 7px;
    border-bottom: 1px solid var(--primary-color);
}

.so1 .title {
    width: 100%;
    height: 50px;
    margin: auto;
    padding: 8px;
    text-align: center;
    border-bottom: 1px solid #c2dff5;
}

.so1 .title h1 {
    font-weight: bold;
    font-size: 18px;
    color: var(--clr-text);
}

.so1 .sum-1 {
    margin: auto;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #c2dff5;
    background-color: #fafafa !important;
}

.so1 .sum-1 .summary-1 {
    width: 70%;
    font-size: 12.5px;
}

.so1 .sum-1 .summary-2 {
    width: 100%;
    font-size: 12.5px;
}

.so1 .sum-1 .form-item-row {
    height: 40px;
}

.so1 .sum-2 {
    margin: auto;
    margin-bottom: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--primary-color);
    background-color: #fafafa !important;
}

.so1 .sum-2 .summary-1 {
    width: 70%;
    font-size: 12.5px;
}

.so1 .sum-2 .summary-1 .form-item-row {
    vertical-align: top;
    font-size: 12.5px;
    height: 40px;
    width: 600px;
}

.so1 .sum-2 .summary-2 {
    width: 100%;
    font-size: 13px;
}

.so1 .sum-2 .summary-2 .form-item-row {
    height: 40px;
}

.so1 .order-details {
    padding-bottom: 20px;
    border-bottom: 1px solid #c2dff5;
}

.so1 .order-details .items {
    width: 100%;
    border-collapse: collapse;
}

.so1 .items th {
    background-color: var(--primary-color);
    border-bottom: 1px solid #c8d8d8;
    border-left: 1px solid #c8d8d8;
    border-top: 1px solid #c8d8d8;
    border-right: 1px solid #c8d8d8;
    padding-left: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12.5px;
    height: 36px;
}

.so1 .items td {
    border-bottom: 1px solid #c8d8d8;
    border-left: 1px solid #c8d8d8;
    border-top: 1px solid #c8d8d8;
    border-right: 1px solid #c8d8d8;
    padding-left: 7px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12.5px;
    height: 30px;
}

.so1 .items tr:nth-child(even) {
    background-color: var(--primary-light);
}

.so1 .terms {
    padding-top: 10px;
    padding-bottom: 10px;
    border-collapse: collapse;
    width: 100%;
}

.so1 .terms .terms-heading {
    font-size: 14px;
}

.so1 .terms .terms-seq {
    border-bottom: 1px solid #c8d8d8;
    border-left: 1px solid #c8d8d8;
    border-top: 1px solid #c8d8d8;
    border-right: 1px solid #c8d8d8;
    width: 40px;
    height: 30px;
    text-align: center;
    padding-top: 5px;
}

.so1 .terms .terms-det {
    border-bottom: 1px solid #c8d8d8;
    border-left: 1px solid #c8d8d8;
    border-top: 1px solid #c8d8d8;
    border-right: 1px solid #c8d8d8;
    height: 30px;
    width: 95%;
    text-align: left;
    padding-top: 5px;
    padding-left: 5px;
}

.po1 {
    width: 21.2cm;
    height: 29.7cm;
    align-items: center;
    position: relative;
    background-color: var(--primary-color);
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}

.po1 footer {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.po1 .row {
    padding: 2px;
    display: flex;
    flex-direction: row;
}

.po1 .company-details {
    text-align: center;
    width: 70%;
}

.po1 .company-details div {
    font-size: 13px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-display: initial;
}

.po1 .company-details .name {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--clr-text);
}

.po1 .company-contact {
    margin-left: 10px;
    padding-left: 10px;
    text-align: left;
    margin: auto;
    width: 35%;
}

.po1 .company-contact div {
    font-size: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-display: initial;
}

.po1 .logo {
    margin-left: 10px;
    text-align: left;
    width: 25%;
    margin: auto;
}

.po1 header {
    padding: 10px 0;
    margin-bottom: 7px;
    border-bottom: 1px solid var(--primary-color);
}

.po1 .title {
    width: 100%;
    height: 50px;
    margin: auto;
    padding: 8px;
    text-align: center;
    background-color: #fafafa;
    border-bottom: 1px solid #c2dff5;
}

.po1 .title h1 {
    font-weight: bold;
    font-size: 18px;
    color: var(--primary-color);
}

.po1 .order-details-title {
    text-align: center;
}

.po1 .sum-1 {
    margin: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #c2dff5;
    background-color: #fafafa !important;
}

.po1 .sum-1 .summary-1 {
    width: 50%;
    font-size: 12.5px;
    justify-content: center;
    align-items: center;
}

.po1 .sum-1 .summary-1 .summary-title {
    text-align: center;
    text-decoration: underline;
}

.po1 .sum-1 .summary-2 {
    width: 50%;
    font-size: 12.5px;
    justify-content: center;
    align-items: center;
}

.po1 .sum-1 .summary-2 .summary-title {
    text-align: center;
    text-decoration: underline;
}

.po1 .sum-1 .form-item-row {
    height: 30px;
    justify-content: flex-start;
}

.po1 .sum-2 {
    margin: auto;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #c2dff5;
    background-color: #fafafa !important;
}

.po1 .sum-2 .summary-1 {
    font-size: 12.5px;
}

.po1 .sum-2 .summary-1 .form-item-row {
    height: 40px;
    width: 600px;
    font-size: 12.5px;
}

.po1 .sum-2 .summary-2 {
    width: 50%;
    font-size: 12.5px;
}

.po1 .sum-2 .summary-2 .form-item-row {
    height: 40px;
}

.po1 .order-details {
    padding-bottom: 20px;
    border-bottom: 1px solid #c2dff5;
}

.po1 .order-details .items {
    width: 100%;
    border-collapse: collapse;
}

.po1 .items th {
    background-color: #d6e7f4;
    border-bottom: 1px solid #c8d8d8;
    border-left: 1px solid #c8d8d8;
    border-top: 1px solid #c8d8d8;
    border-right: 1px solid #c8d8d8;
    padding-left: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12.5px;
    height: 36px;
}

.po1 .items td {
    border-bottom: 1px solid #c8d8d8;
    border-left: 1px solid #c8d8d8;
    border-top: 1px solid #c8d8d8;
    border-right: 1px solid #c8d8d8;
    padding-left: 7px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12.5px;
    height: 30px;
}

.po1 .items tr:nth-child(even) {
    background-color: #f2f2f2;
}

.po1 .terms {
    padding-top: 10px;
    padding-bottom: 10px;
    border-collapse: collapse;
    width: 100%;
}

.po1 .terms .terms-heading {
    font-size: 14px;
}

.po1 .terms .terms-seq {
    border-bottom: 1px solid #c8d8d8;
    border-left: 1px solid #c8d8d8;
    border-top: 1px solid #c8d8d8;
    border-right: 1px solid #c8d8d8;
    width: 40px;
    height: 30px;
    text-align: center;
    padding-top: 5px;
}

.po1 .terms .terms-det {
    border-bottom: 1px solid #c8d8d8;
    border-left: 1px solid #c8d8d8;
    border-top: 1px solid #c8d8d8;
    border-right: 1px solid #c8d8d8;
    height: 30px;
    width: 95%;
    text-align: left;
    padding-top: 5px;
    padding-left: 5px;
}

.dc {
    position: relative;
    background-color: #FFF;
    min-height: 680px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    max-width: 22cm;
}

.dc .row {
    padding: 2px;
    display: flex;
    flex-direction: row;
}

.dc .company-details {
    text-align: left;
    width: 70%;
}

.dc .company-details div {
    font-size: 13px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-display: initial;
}

.dc .company-details .name {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--clr-text);
}

.dc .company-contact {
    margin-left: 10px;
    padding-left: 10px;
    text-align: left;
    margin: auto;
    width: 35%;
}

.dc .company-contact div {
    font-size: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-display: initial;
}

.dc .logo {
    margin-left: 10px;
    text-align: left;
    width: 25%;
    margin: auto;
}

.dc header {
    padding: 10px 0;
    margin-bottom: 7px;
    border-bottom: 1px solid var(--primary-color);
}

.dc .title {
    width: 100%;
    height: 50px;
    margin: auto;
    padding: 8px;
    text-align: center;
    background-color: #fafafa;
    border-bottom: 1px solid #c2dff5;
}

.dc .title h1 {
    font-weight: bold;
    font-size: 17px;
    color: var(--primary-color);
}

.dc .summary {
    margin: auto;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #c2dff5;
    background-color: #fafafa !important;
}

.dc .customer-details {
    text-align: left;
    padding: 10px;
    width: 45%;
    background-color: #fafafa !important;
}

.dc .customer-details div {
    font-size: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
    justify-content: left;
}

.dc .challan-details {
    text-align: left;
    padding: 10px;
    width: 40%;
    background-color: #ffffff;
}

.dc .challan-details div {
    font-size: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
    justify-content: left;
}

.dc .package-contents-title {
    color: var(--primary-color);
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 16px;
}

.dc .items .package-challan-no-title {
    margin-top: 0px;
    padding-top: -10px;
    height: 20px;
}

.dc .customer-name {
    margin-top: 2px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    border-bottom: solid 1px lavender;
}

.dc .challan-name {
    margin-top: 2px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    border-bottom: solid 1px lavender;
}

.dc .qrcode {
    margin: auto;
    width: 15%;
    height: 100% !important;
    display: grid;
    justify-items: center;
}

.dc .terms-conditions {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #c2dff5;
    padding-top: 10px;
    margin-top: 10px;
    border-collapse: collapse;
}

.dc .terms {
    padding: 10px;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    width: 70%;
}

.dc .terms div {
    font-size: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-display: initial;
}

.dc .conditions {
    padding: 10px;
    border-right: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    width: 30%;
    display: grid;
    align-items: center;
    font-size: 12px;
}

.dc .terms-heading {
    margin-top: 2px;
    margin-bottom: 5px;
    color: var(--primary-color);
}

.dc .items {
    width: 100%;
    border-collapse: collapse;
}

.dc .shipment-contents {
    padding-bottom: 20px;
    border-bottom: 1px solid #c2dff5;
}

.dc .items th {
    background-color: #d6e7f4;
    border-bottom: 1px solid #c8d8d8;
    border-left: 1px solid #c8d8d8;
    border-top: 1px solid #c8d8d8;
    border-right: 1px solid #c8d8d8;
    padding-left: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    height: 32px;
}

.dc .items td {
    border-bottom: 1px solid #c8d8d8;
    border-left: 1px solid #c8d8d8;
    border-top: 1px solid #c8d8d8;
    border-right: 1px solid #c8d8d8;
    padding-left: 7px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
}

.dc .items tr:nth-child(even) {
    background-color: #f2f2f2;
}

.dc footer {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    color: #777;
    border-top: 0.5px solid #aaa;
    padding: 8px 0;
    font-size: 12px;
}

.rf {
    position: relative;
    min-height: 600px;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1.25px solid #a8a8a8;
}

.rf .row {
    padding: 2px;
    display: flex;
    flex-direction: row;
}

.rf .title {
    width: 100%;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 5px;
    text-align: center;
}

.rf .title h1 {
    font-size: 17px;
    font-weight: bold;
}

.rf .title h2 {
    padding-left: 18%;
    padding-right: 18%;
    font-size: 12px;
}

.rf .top {
    flex-direction: row;
    padding-top: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    display: flex;
}

.rf .top .ins-name {
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    padding-left: 120px;
    margin-left: 10px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
}

.rf .top .heading-copy {
    font-size: 12px;
    height: 22px;
    right: 0;
    margin-bottom: 0;
    width: 120px;
}

.rf .summary {
    margin-top: 10px;
    border-top: 1px solid #a8a8a8;
    border-bottom: 1px solid #a8a8a8;
    display: flex;
    flex-direction: row;
    align-items: center !important;
}

.rf .student-details {
    text-align: left;
    width: 41%;
    padding-left: 5px;
    align-items: center !important;
}

.rf .student-details div {
    font-size: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-display: left;
}

.rf .receipt-details {
    text-align: left;
    vertical-align: middle;
    width: 41%;
    padding-top: 8px;
    padding-bottom: 7px;
    border-left: 1px solid #a8a8a8;
    border-right: 1px solid #a8a8a8;
    padding-left: 5px;
}

.rf .receipt-details div {
    font-size: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
    justify-content: left;
}

.rf .receipt-details form-item-row {
    padding-top: 5px;
    padding-bottom: 5px;
}

.rf .qrcode {
    width: 18%;
    padding-top: 12px;
    padding-bottom: 12px;
    display: grid;
    justify-items: center;
}

.rf .payment-details {
    padding-top: 15px;
    padding-bottom: 15px;
}

.rf .payment-details h2 {
    font-size: 14px;
    font-weight: bold;
    height: 30px;
    text-align: center;
}

.rf .pmt-amt {
    padding-top: 10px;
    padding-bottom: 10px;
}

.rf .pmt-amt .pmt-words {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    vertical-align: middle;
    max-width: 400px;
    justify-content: left;
    font-size: 12.5px;
    width: 100%;
}

.rf .items {
    width: 100%;
    border-collapse: collapse;
}

.rf .items th {
    background-color: #efefef;
    border-bottom: 1px solid #a8a8a8;
    border-left: 1px solid #a8a8a8;
    border-top: 1px solid #a8a8a8;
    border-right: 1px solid #a8a8a8;
    padding-left: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;
    height: 25px;
}

.rf .items td {
    border-bottom: 1px solid #a8a8a8;
    border-left: 1px solid #a8a8a8;
    border-top: 1px solid #a8a8a8;
    border-right: 1px solid #a8a8a8;
    padding-left: 7px;
    padding-right: 5px;
    height: 26px;
    font-size: 12px;
}

.rf .items tr:nth-child(even) {
    background-color: #f2f2f2;
}

.rf footer {
    margin-top: 5px;
    width: 100%;
    text-align: center;
    border-top: 0.5px solid #aaa;
    padding: 6px 0;
    font-size: 13px;
}

.rf .payment-info {
    display: flex;
    flex-direction: row;
    padding-top: 3px;
    margin-top: 3px;
    border-collapse: collapse;
}

.rf .pi-item {
    padding: 2px;
    border-left: 1px solid #a8a8a8;
    border-top: 1px solid #a8a8a8;
    border-bottom: 1px solid #a8a8a8;
    text-align: center;
    font-size: 12px;
    width: 19%;
    border-collapse: collapse;
}

.rf .pi-item div {
    font-size: 12px;
}

.rf .pi-item .pi-item-heading {
    font-size: 12px;
    font-weight: bold;
    height: 20px;
    margin-bottom: 2px;
}

.rf .pi-item-accountant {
    border-left: 1px solid #a8a8a8;
    padding-left: 20px;
    width: 24%;
}

.rf .pi-item-accountant .pi-item-heading {
    position: relative;
    width: 24%;
    font-size: 12px;
    font-weight: bold;
    position: static;
    margin-top: 38px;
    margin-left: 20px;
}

.trn_note {
    position: relative;
    background-color: #FFF;
    min-height: 680px;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.trn_note .row {
    padding: 2px;
    display: flex;
    flex-direction: row;
}

.trn_note .title {
    width: 100%;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 5px;
    text-align: center;
    border-bottom: 2px solid #d8d8d8;
}

.trn_note .title h1 {
    font-size: 17px;
    font-weight: bold;
    color: #777;
}

.trn_note .title h2 {
    padding-left: 18%;
    padding-right: 18%;
    font-size: 12px;
    color: #777;
}

.trn_note .top {
    flex-direction: row;
    padding-top: 0px;
    margin-top: 5px;
    text-align: center;
    display: flex;
}

.trn_note .top .ins-name {
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    padding-left: 120px;
    margin-left: 10px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    color: #777;
}

.trn_note .top .heading-copy {
    font-size: 12px;
    height: 22px;
    right: 0;
    margin-bottom: 0;
    color: #777;
    width: 120px;
}

.trn_note .summary {
    background-color: #fafafa;
    border-bottom: 2px solid #d8d8d8;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center !important;
}

.trn_note .student-details {
    text-align: left;
    width: 50%;
    padding-left: 2px;
}

.trn_note .student-details div {
    font-size: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-display: left;
}

.trn_note .receipt-details {
    text-align: left;
    vertical-align: middle;
    width: 41%;
    padding-top: 8px;
    padding-bottom: 7px;
    padding-left: 2px;
    background-color: #ffffff;
}

.trn_note .receipt-details div {
    font-size: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
    justify-content: left;
}

.trn_note .receipt-details form-item-row {
    padding-top: 5px;
    padding-bottom: 5px;
}

.trn_note .qrcode {
    width: 18%;
    padding-top: 12px;
    padding-bottom: 12px;
    display: grid;
    justify-items: center;
}

.trn_note .payment-details {
    padding-top: 15px;
    padding-bottom: 15px;
}

.trn_note .payment-details h2 {
    font-size: 14px;
    font-weight: bold;
    height: 30px;
    text-align: center;
}

.trn_note .pmt-amt {
    padding-top: 10px;
    padding-bottom: 10px;
}

.trn_note .pmt-amt .pmt-words {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    vertical-align: middle;
    max-width: 400px;
    justify-content: left;
    font-size: 12.5px;
    width: 100%;
}

.trn_note .items {
    width: 100%;
    border-collapse: collapse;
}

.trn_note .items th {
    background-color: #efefef;
    border-bottom: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    padding-left: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;
    height: 25px;
}

.trn_note .items td {
    border-bottom: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    padding-left: 7px;
    padding-right: 5px;
    height: 26px;
    font-size: 12px;
}

.trn_note .items tr:nth-child(even) {
    background-color: #f2f2f2;
}

.trn_note footer {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    color: #777;
    border-top: 0.5px solid #aaa;
    padding: 6px 0;
    font-size: 13px;
}

.trn_note .payment-info {
    display: flex;
    flex-direction: row;
    padding-top: 3px;
    margin-top: 3px;
    border-collapse: collapse;
}

.trn_note .pi-item {
    padding: 2px;
    border-left: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    text-align: center;
    font-size: 12px;
    width: 19%;
    border-collapse: collapse;
}

.trn_note .pi-item div {
    font-size: 12px;
}

.trn_note .pi-item .pi-item-heading {
    font-size: 12px;
    font-weight: bold;
    height: 20px;
    margin-bottom: 2px;
}

.trn_note .pi-item-accountant {
    border-left: 1px solid #d8d8d8;
    padding-left: 20px;
    width: 24%;
}

.trn_note .pi-item-accountant .pi-item-heading {
    position: relative;
    width: 24%;
    font-size: 12px;
    font-weight: bold;
    position: static;
    margin-top: 38px;
    margin-left: 20px;
}

.rf5 {
    position: relative;
    min-height: 600px;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
    border: 1.25px solid #a8a8a8;
}

.rf5 .form-title {
    text-align: center;
}

.rf5 .form-title h2 {
    padding-top: 15px;
    font-size: 13px;
    font-weight: bold;
}

.rf5 .row {
    padding: 2px;
    display: flex;
    flex-direction: row;
}

.rf5 .title {
    width: 100%;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 5px;
    text-align: center;
    border-bottom: 2px solid #a8a8a8;
}

.rf5 .title .ins-name {
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    width: 100%;
}

.rf5 .title h2 {
    font-size: 12.5px;
}

.rf5 .top {
    flex-direction: row;
    padding-top: 0px;
    margin-top: 5px;
    text-align: center;
    display: flex;
}

.rf5 .top .ins-name {
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    padding-left: 120px;
    margin-left: 10px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
}

.rf5 .top .heading-copy {
    font-size: 12px;
    height: 22px;
    right: 0;
    margin-bottom: 0;
    width: 120px;
}

.rf5 .summary {
    border-bottom: 2px solid #a8a8a8;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center !important;
}

.rf5 .summary-1 {
    text-align: left;
    width: 41%;
    padding-left: 2px;
    align-items: center !important;
}

.rf5 .summary-1 div {
    font-size: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-display: left;
}

.rf5 .summary-2 {
    text-align: left;
    vertical-align: middle;
    width: 41%;
    padding-top: 8px;
    padding-bottom: 7px;
    padding-left: 2px;
}

.rf5 .summary-2 div {
    font-size: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
    justify-content: left;
}

.rf5 .summary-2 form-item-row {
    padding-top: 5px;
    padding-bottom: 5px;
}

.rf5 .qrcode {
    width: 18%;
    padding-top: 12px;
    padding-bottom: 12px;
    display: grid;
    justify-items: center;
}

.rf5 .pmt-amt {
    padding-top: 10px;
    padding-bottom: 10px;
}

.rf5 .pmt-amt .pmt-words {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    vertical-align: middle;
    max-width: 400px;
    justify-content: left;
    font-size: 12.5px;
    width: 100%;
}

.rf5 .payment-details {
    padding-top: 15px;
    padding-bottom: 15px;
}

.rf5 .payment-details h2 {
    font-size: 14px;
    font-weight: bold;
    height: 30px;
    text-align: center;
}

.rf5 .items {
    width: 100%;
    border-collapse: collapse;
}

.rf5 .items th {
    background-color: #efefef;
    border-bottom: 1px solid #a8a8a8;
    border-left: 1px solid #a8a8a8;
    border-top: 1px solid #a8a8a8;
    border-right: 1px solid #a8a8a8;
    padding-left: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;
    height: 25px;
}

.rf5 .items td {
    border-bottom: 1px solid #a8a8a8;
    border-left: 1px solid #a8a8a8;
    border-top: 1px solid #a8a8a8;
    border-right: 1px solid #a8a8a8;
    padding-left: 7px;
    padding-right: 5px;
    height: 26px;
    font-size: 12px;
}

.rf5 .items tr:nth-child(even) {
    background-color: #f2f2f2;
}

.rf5 footer {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    border-top: 0.5px solid #aaa;
    padding: 6px 0;
    font-size: 13px;
}

.rf5 .payment-info {
    display: flex;
    flex-direction: row;
    padding-top: 3px;
    margin-top: 3px;
    border-collapse: collapse;
}

.rf5 .pi-item {
    padding: 2px;
    border-left: 1px solid #a8a8a8;
    border-top: 1px solid #a8a8a8;
    border-bottom: 1px solid #a8a8a8;
    text-align: center;
    font-size: 12px;
    width: 19%;
    border-collapse: collapse;
}

.rf5 .pi-item div {
    font-size: 12px;
}

.rf5 .pi-item .pi-item-heading {
    font-size: 12px;
    font-weight: bold;
    height: 20px;
    margin-bottom: 2px;
}

.rf5 .pi-item-accountant {
    border-left: 1px solid #a8a8a8;
    padding-left: 20px;
    width: 24%;
}

.rf5 .pi-item-accountant .pi-item-heading {
    position: relative;
    width: 24%;
    font-size: 12px;
    font-weight: bold;
    position: static;
    margin-top: 38px;
    margin-left: 20px;
}

.bc1 {
    width: 29.7cm;
    height: 20.8cm;
    align-items: center;
}

.bc1 .std-photo {
    position: absolute;
    right: 155px;
    top: 140px;
}

.bc1 .p0 {
    position: absolute;
    right: 200px;
    top: 285px;
    font-weight: bold;
    font-size: 16px;
}

.bc1 .p1 {
    position: absolute;
    left: 550px;
    top: 340px;
    font-size: 16px;
    font-weight: bold;
    height: 20px;
}

.bc1 .p2 {
    position: absolute;
    left: 400px;
    top: 405px;
    font-size: 16px;
    font-weight: bold;
    height: 20px;
}

.bc1 .p3 {
    position: absolute;
    right: 220px;
    top: 470px;
    font-size: 16px;
    font-weight: bold;
    height: 20px;
}

.bc1 .p4 {
    position: absolute;
    left: 325px;
    top: 537px;
    font-size: 16px;
    font-weight: bold;
    height: 20px;
}

.bc1 .p5 {
    position: absolute;
    left: 280px;
    top: 605px;
    font-size: 16px;
    font-weight: bold;
    height: 20px;
}

.bc1 .p6 {
    position: absolute;
    left: 115px;
    top: 180px;
    font-size: 16px;
    font-weight: bold;
    height: 20px;
}

.bc2 {
    width: 29.7cm;
    height: 21.2cm;
    align-items: center;
}

.bc2 .std-photo {
    position: absolute;
    right: 155px;
    top: 110px;
}

.bc2 .p0 {
    position: absolute;
    right: 150px;
    top: 257px;
    font-weight: bold;
    font-size: 16px;
}

.bc2 .p1 {
    position: absolute;
    left: 550px;
    top: 307px;
    font-size: 16px;
    font-weight: bold;
    height: 20px;
}

.bc2 .p2 {
    position: absolute;
    left: 350px;
    top: 370px;
    font-size: 16px;
    font-weight: bold;
    height: 20px;
}

.bc2 .p3 {
    position: absolute;
    right: 220px;
    top: 435px;
    font-size: 16px;
    font-weight: bold;
    height: 20px;
}

.bc2 .p4 {
    position: absolute;
    left: 325px;
    top: 500px;
    font-size: 16px;
    font-weight: bold;
    height: 20px;
}

.bc2 .p5 {
    position: absolute;
    left: 290px;
    top: 565px;
    font-size: 16px;
    font-weight: bold;
    height: 20px;
}

.bc2 .p6 {
    position: absolute;
    left: 115px;
    top: 180px;
    font-size: 16px;
    font-weight: bold;
    height: 20px;
}

.bc2 .p7 {
    position: absolute;
    right: 400px;
    top: 565px;
    font-size: 16px;
    font-weight: bold;
    height: 20px;
}

.rs1 {
    width: 29.7cm;
    height: 21.2cm;
    display: flex;
    margin-top: -5px;
}

.rs1 .std-photo {
    position: absolute;
    right: 110px;
    top: 180px;
}

.rs1 .p0 {
    position: absolute;
    left: 460px;
    top: 121px;
    font-size: 12px;
    font-weight: bold;
    height: 20px;
}

.rs1 .p1 {
    position: absolute;
    left: 460px;
    top: 141px;
    font-size: 12px;
    font-weight: bold;
    height: 20px;
}

.rs1 .p2 {
    position: absolute;
    left: 460px;
    top: 161px;
    font-size: 12px;
    font-weight: bold;
    height: 20px;
}

.rs1 .p3 {
    position: absolute;
    left: 460px;
    top: 181px;
    font-size: 12px;
    font-weight: bold;
    height: 20px;
}

.rs1 .p4 {
    position: absolute;
    left: 460px;
    top: 201px;
    font-size: 12px;
    font-weight: bold;
    height: 20px;
}

.rs1 .p5 {
    position: absolute;
    left: 460px;
    top: 221px;
    font-size: 12px;
    font-weight: bold;
    height: 20px;
}

.rs1 .p6 {
    position: absolute;
    left: 460px;
    top: 259px;
    font-size: 12px;
    font-weight: bold;
    height: 20px;
}

.rs1 .p7 {
    position: absolute;
    left: 460px;
    top: 279px;
    font-size: 12px;
    font-weight: bold;
    height: 20px;
}

.rs1 .p8 {
    position: absolute;
    left: 460px;
    top: 299px;
    font-size: 12px;
    font-weight: bold;
    height: 20px;
}

.rs1 .p9 {
    position: absolute;
    left: 460px;
    top: 319px;
    font-size: 12px;
    font-weight: bold;
    height: 20px;
}

.rs1 .p10 {
    position: absolute;
    left: 460px;
    top: 339px;
    font-size: 12px;
    font-weight: bold;
    height: 20px;
}

.rs1 .p11 {
    position: absolute;
    left: 50px;
    top: 540px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
    width: 80px;
    text-align: justify;
}

.rs1 .p12 {
    position: absolute;
    left: 130px;
    top: 550px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
}

.rs1 .p13 {
    position: absolute;
    left: 190px;
    top: 550px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
}

.rs1 .p14 {
    position: absolute;
    left: 243px;
    top: 550px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
}

.rs1 .p15 {
    position: absolute;
    left: 296px;
    top: 550px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
}

.rs1 .p16 {
    position: absolute;
    left: 350px;
    top: 550px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
}

.rs1 .p17 {
    position: absolute;
    left: 403px;
    top: 550px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
}

.rs1 .p18 {
    position: absolute;
    left: 456px;
    top: 550px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
}

.rs1 .p19 {
    position: absolute;
    left: 510px;
    top: 550px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
}

.rs1 .p20 {
    position: absolute;
    left: 563px;
    top: 550px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
}

.rs1 .p21 {
    position: absolute;
    left: 616px;
    top: 550px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
}

.rs1 .p22 {
    position: absolute;
    left: 670px;
    top: 550px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
}

.rs1 .p23 {
    position: absolute;
    left: 723px;
    top: 550px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
}

.rs1 .p24 {
    position: absolute;
    left: 776px;
    top: 550px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
}

.rs1 .p25 {
    position: absolute;
    left: 776px;
    top: 550px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
}

.rs1 .p26 {
    position: absolute;
    left: 776px;
    top: 550px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
}

.rs1 .p27 {
    position: absolute;
    right: 175px;
    top: 535px;
    font-size: 12px;
    transform: rotate(-90deg);
    font-weight: bold;
    height: 20px;
}

.vf0 {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.vf0 .form-title {
    text-align: center;
}

.vf0 .form-title h2 {
    text-transform: uppercase;
    -webkit-text-decoration: double underline;
    text-decoration: double underline;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
}

.vf0 .row {
    padding: 2px;
    display: flex;
    flex-direction: row;
}

.vf0 .title {
    width: 100%;
    margin-top: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    border-bottom: 2px solid #d8d8d8;
}

.vf0 .title .ins-name {
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    width: 100%;
    color: #777;
}

.vf0 .title .sub-title h2 {
    padding-left: 18%;
    padding-right: 18%;
    font-size: 12px;
    color: #777;
    text-align: center;
}

.vf0 .title h2 {
    font-size: 12.5px;
    color: #777;
}

.vf0 .top {
    flex-direction: row;
    padding-top: 0px;
    margin-top: 5px;
    text-align: center;
    display: flex;
}

.vf0 .top .ins-name {
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    padding-left: 120px;
    margin-left: 10px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    color: #777;
}

.vf0 .top .heading-copy {
    font-size: 12px;
    height: 22px;
    right: 0;
    margin-bottom: 0;
    color: #777;
    width: 120px;
}

.vf0 .summary {
    background-color: #fafafa;
    border-bottom: 2px solid #d8d8d8;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center !important;
}

.vf0 .summary-1 {
    text-align: left;
    width: 50%;
    padding-left: 2px;
    align-items: center !important;
}

.vf0 .summary-1 div {
    font-size: 13px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-display: left;
}

.vf0 .summary-2 {
    text-align: left;
    vertical-align: middle;
    width: 50%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 2px;
    background-color: #ffffff;
}

.vf0 .summary-2 div {
    font-size: 13px;
    padding-top: 3px;
    padding-bottom: 3px;
    justify-content: left;
}

.vf0 .summary-2 form-item-row {
    padding-top: 5px;
    padding-bottom: 5px;
}

.vf0 .qrcode {
    width: 18%;
    padding-top: 12px;
    padding-bottom: 12px;
    display: grid;
    justify-items: center;
}

.vf0 .payment-details {
    padding-top: 15px;
    padding-bottom: 15px;
}

.vf0 .payment-details h2 {
    font-size: 14px;
    font-weight: bold;
    height: 30px;
    text-align: center;
}

.vf0 .items {
    width: 100%;
    border-collapse: collapse;
}

.vf0 .items th {
    background-color: #efefef;
    border-bottom: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    padding-left: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;
    height: 25px;
}

.vf0 .items td {
    border-bottom: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    padding-left: 7px;
    padding-right: 5px;
    height: 26px;
    font-size: 14px;
}

.vf0 .items tr:nth-child(even) {
    background-color: #f2f2f2;
}

.vf0 footer {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    color: #777;
    border-top: 0.5px solid #aaa;
    padding: 6px 0;
    font-size: 13px;
}

.vf0 .payment-info {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    margin-top: 10px;
    border-collapse: collapse;
}

.vf0 .pi-item {
    padding: 2px;
    border: 1px solid #d8d8d8;
    height: 60px;
    text-align: center;
    font-size: 12px;
    width: 25%;
    border-collapse: collapse;
}

.vf0 .pi-item div {
    font-size: 14px;
}

.vf0 .pi-item .pi-item-heading {
    font-size: 14px;
    font-weight: bold;
    margin-top: 35px;
    margin-bottom: 2px;
}

.vf0 .pi-item-accountant {
    border-left: 1px solid #d8d8d8;
    padding-left: 20px;
    width: 24%;
}

.vf0 .pi-item-accountant .pi-item-heading {
    position: relative;
    width: 24%;
    font-size: 12px;
    font-weight: bold;
    position: static;
    margin-top: 38px;
    margin-left: 20px;
}

.vf0 .pmt-amt {
    padding-top: 10px;
    padding-bottom: 10px;
}

.vf0 .pmt-words {
    display: flex;
    padding-top: 20px;
    padding-left: 5px;
    font-size: 13px;
    height: 30px;
    flex-direction: row;
    align-items: center !important;
    vertical-align: middle;
    justify-content: left;
}

.cheque-realization {
    height: 25px;
    display: flex;
    font-size: 12px;
    padding-top: 25px;
    padding-bottom: 15px;
    flex-direction: row;
    align-items: center !important;
    justify-content: center;
}

.separator {
    border-bottom: 2px #a8a8a8 dotted !important;
    height: 22px;
    margin-bottom: 12px;
}

.timestamp {
    font-size: 10px;
    padding-top: 5px;
}

.rf1 {
    position: relative;
    background-color: #FFF;
    min-height: 680px;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.rf1 .row {
    padding: 2px;
    display: flex;
    flex-direction: row;
}

.rf1 .form-title {
    align-content: center;
    padding: 5px;
}

.rf1 .form-title h2 {
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    height: 30px;
    text-align: center;
    justify-content: center;
}

.rf1 .title-summary {
    background-color: #fafafa;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    padding-bottom: 5px;
    border-bottom: 2px solid #d8d8d8;
}

.rf1 .title-summary .tax-info {
    width: 25%;
    font-size: 12px;
}

.rf1 .title-summary .title {
    width: 50%;
    margin-top: 5px;
    padding-top: 5px;
}

.rf1 .title-summary .title h1 {
    font-size: 17px;
    font-weight: bold;
    color: #777;
    height: 24px;
    text-align: center;
}

.rf1 .title-summary .title .sub-title h2 {
    padding-left: 18%;
    padding-right: 18%;
    font-size: 12px;
    color: #777;
    text-align: center;
}

.rf1 .top {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    padding-top: 0px;
    justify-content: center;
    text-align: center;
    display: flex;
}

.rf1 .top .ins-name {
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    padding-left: 120px;
    margin-left: 10px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    color: #777;
}

.rf1 .top .heading-copy {
    font-size: 12px;
    height: 22px;
    right: 0;
    margin-bottom: 0;
    color: #777;
    text-align: center;
    width: 120px;
}

.rf1 .top-1 {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    margin-top: 5px;
    padding-top: 5px;
    justify-content: center;
    text-align: center;
    display: flex;
}

.rf1 .top-1 .ins-name {
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    padding-left: 120px;
    margin-left: 10px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    color: #777;
}

.rf1 .top-1 .heading-copy {
    font-size: 12px;
    height: 22px;
    right: 0;
    margin-bottom: 0;
    color: #777;
    text-align: center;
    width: 120px;
}

.rf1 .summary {
    margin-top: 5px;
    background-color: #fafafa;
    border-bottom: 1px solid #d8d8d8;
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    flex-direction: row;
    align-items: center !important;
}

.rf1 .student-details {
    text-align: left;
    width: 60%;
    padding-left: 2px;
    align-items: center !important;
}

.rf1 .student-details div {
    font-size: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-display: left;
}

.rf1 .receipt-details {
    text-align: left;
    vertical-align: middle;
    width: 40%;
    padding-left: 2px;
}

.rf1 .receipt-details div {
    font-size: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
    justify-content: left;
}

.rf1 .receipt-details form-item-row {
    padding-top: 5px;
    padding-bottom: 5px;
}

.rf1 .payment-details {
    padding-top: 15px;
    padding-bottom: 15px;
}

.rf1 .payment-details h2 {
    font-size: 14px;
    font-weight: bold;
    height: 30px;
    text-align: center;
}

.rf1 .items {
    width: 100%;
    border-collapse: collapse;
}

.rf1 .items th {
    background-color: #efefef;
    border-bottom: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    padding-left: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;
    height: 25px;
}

.rf1 .items td {
    border-bottom: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    padding-left: 7px;
    padding-right: 5px;
    height: 26px;
    font-size: 12px;
}

.rf1 .items tr:nth-child(even) {
    background-color: #f2f2f2;
}

.rf1 footer {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    color: #777;
    border-top: 0.5px solid #aaa;
    padding: 6px 0;
    font-size: 13px;
}

.rf1 .payment-info {
    display: flex;
    flex-direction: row;
    padding-top: 3px;
    margin-top: 3px;
    border-collapse: collapse;
}

.rf1 .pmt-amt {
    padding-top: 10px;
    padding-bottom: 10px;
}

.rf1 .pmt-amt .pmt-words {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    vertical-align: middle;
    max-width: 400px;
    justify-content: left;
    font-size: 12.5px;
    width: 100%;
}

.rf1 .pi-item {
    padding: 2px;
    border-left: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    text-align: center;
    font-size: 12px;
    width: 19%;
    border-collapse: collapse;
}

.rf1 .pi-item div {
    font-size: 12px;
}

.rf1 .pi-item .pi-item-heading {
    font-size: 12px;
    font-weight: bold;
    height: 20px;
    margin-bottom: 2px;
}

.rf1 .pi-item-accountant {
    border-left: 1px solid #d8d8d8;
    padding-left: 20px;
    width: 24%;
}

.rf1 .pi-item-accountant .pi-item-heading {
    position: relative;
    width: 24%;
    font-size: 12px;
    font-weight: bold;
    position: static;
    margin-top: 38px;
    margin-left: 20px;
}

.layout-footer {
    transition: margin-left 0.2s;
    background-color: #c3e8fb;
    padding: 1em 2em;
}

.layout-footer img {
    vertical-align: middle;
}

.layout-footer .footer-text {
    vertical-align: middle;
}

/* Typography */
h1 {
    font-weight: normal;
    margin: 0;
    font-size: 24px;
}

h2 {
    font-size: 20px;
    font-weight: normal;
    margin: 0;
}

.div-2 {
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 3px;
    margin-right: 8px;
    background-color: #fefefe;
    border: 1.5px solid #a2bbd1;
}

.card {
    padding: 0.25em;
    margin-bottom: 3px;
    border-radius: 3px;
}

.card.card-w-title {
    justify-content: center;
    text-align: center;
    padding-bottom: 1.25em;
}

.card.card-w-heading {
    padding-bottom: 0.25em;
}

.card h1 {
    margin: 1em 0 0.5em 0;
    border-bottom: 1px solid #d5d5d5;
    padding: 0.1em;
    font-size: 20px;
}

.card h1:first-child {
    margin: 0 0 0.5em 0;
}

.card h2 {
    margin: 0.2em 0.2em 0.2em 0.2em;
    border-bottom: 1px solid #d5d5d5;
    padding: 0.1em;
    font-size: 16px;
}

.card h3 {
    margin: 0.25em 0.25em 0.25em 0.25em;
    border-bottom: 1px solid #d5d5d5;
    padding: 0.05em;
    font-size: 14px;
    font-weight: bolder;
}

.label {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #000000;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    text-align: center;
}

.label-1 {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #000000;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    text-align: center;
    padding-top: 2px;
}

.label-1 .header-1 {
    display: flex;
    align-items: flex-end;
    font-size: 12.5px;
    justify-content: center;
    padding-bottom: 4px;
}

.label-1 .header-1 h1 {
    font-size: 12.5px;
    font-weight: bold;
    text-align: center;
}

.label-1 .header-1 h2 {
    font-size: 11.5px;
    font-weight: bold;
    text-align: center;
}

.label-1 .header-1 .logo {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 45px;
    height: 45px;
}

.label-1 .header-1 .seal {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 50px;
    height: 50px;
}

.label-1 .form-item-row {
    padding-left: 10px;
    padding-right: 10px;
    justify-content: start;
    font-size: 12px;
    width: 100%;
}

.label-1 .footer-1 {
    padding-left: 10px;
    padding-right: 10px;
    height: 38px;
    display: flex;
    align-items: flex-end;
    font-size: 12px;
}

.label-2 {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #000000;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    text-align: center;
    padding-top: 2px;
}

.label-2 .qrcode {
    padding: 6px;
    transform: translateY(10%);
}

.label-2 .form-item-row {
    padding-left: 10px;
    padding-right: 10px;
    justify-content: start;
    font-size: 12px;
    height: 28px;
    width: 100%;
}

.label-top-inline-text {
    position: absolute;
    left: 0px;
    right: 0px;
    margin-top: -8px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    text-align: center;
    font-size: 8px;
    font-weight: bold;
    color: #666666;
    background: white;
    border: hidden;
    max-width: 75px;
    text-align: center;
}

.label-top-inner-text {
    position: absolute;
    left: 0px;
    right: 0px;
    margin-top: 4px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    text-align: center;
    font-size: 8px;
    font-weight: bold;
    color: #666666;
    background: white;
    border: hidden;
    max-width: 120px;
}

.label-top-outer-text {
    position: absolute;
    left: 0px;
    right: 0px;
    margin-top: -20px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    text-align: center;
    font-size: 8px;
    font-weight: bold;
    color: #666666;
    background: white;
    border: hidden;
    max-width: 120px;
}

.label-bottom-inline-text {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -5px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-size: 8px;
    font-weight: bold;
    color: #666666;
    background: white;
    max-width: 75px;
}

.label-bottom-inner-text {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-size: 8px;
    font-weight: bold;
    color: #666666;
    background: white;
    max-width: 120px;
}

.label-bottom-outer-text {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -20px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-size: 8px;
    font-weight: bold;
    color: #666666;
    background: white;
    max-width: 120px;
}

.label-left-inline-text {
    position: absolute;
    display: block;
    margin-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 10px;
    padding-right: 10px;
    top: 0px;
    bottom: 0px;
    text-align: center;
    background: white;
    border: hidden;
    font-size: 8px;
    font-weight: bold;
    color: #666666;
    transform: rotate(270deg);
    height: 80px;
    width: 45px;
}

.label-left-inner-text {
    position: absolute;
    display: block;
    margin-left: 15px;
    margin-right: 2px;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 20px;
    padding-right: 20px;
    top: 0px;
    bottom: 0px;
    text-align: center;
    background: white;
    border: hidden;
    font-size: 7.5px;
    font-weight: bold;
    color: #666666;
    transform: rotate(270deg);
    height: 90px;
}

.label-left-outer-text {
    position: absolute;
    margin-left: -20px;
    margin-top: auto;
    margin-bottom: auto;
    top: 0px;
    bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    background: white;
    border: hidden;
    font-size: 8px;
    font-weight: bold;
    color: #666666;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    max-height: 100px;
}

.label-right-inline-text {
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: -5px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: white;
    border: hidden;
    text-align: center;
    font-size: 8px;
    font-weight: bold;
    color: #666666;
    writing-mode: vertical-rl;
    max-height: 50px;
}

.label-right-inner-text {
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 8px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
    border: hidden;
    text-align: center;
    font-size: 8px;
    font-weight: bold;
    color: #666666;
    writing-mode: vertical-rl;
    max-height: 100px;
}

.label-right-outer-text {
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: -20px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
    border: hidden;
    text-align: center;
    font-size: 8px;
    font-weight: bold;
    color: #666666;
    writing-mode: vertical-rl;
    max-height: 100px;
}

.label-qrcode {
    position: absolute;
    padding: 5px;
    right: 8px;
    bottom: 12px;
    transform: translateY(10%);
}

.label-items {
    transform: translateY(10%);
}

.label-section-1 {
    display: block;
    text-align: left;
    margin-top: 5px;
    margin-left: 25px;
    font-size: 9px;
}

.label-section-2 {
    display: block;
    text-align: left;
    margin-left: 25px;
    margin-top: 2px;
    font-size: 9px;
}

.label-section-3 {
    display: block;
    text-align: left;
    margin-left: 25px;
    margin-top: 2px;
    font-size: 9px;
}

.label-section-4 {
    display: block;
    text-align: left;
    margin-left: 25px;
    margin-top: 2px;
    font-size: 9px;
}

.label-section-5 {
    display: block;
    text-align: left;
    margin-left: 25px;
    margin-top: 2px;
    font-size: 9px;
}

.label-section-6 {
    display: block;
    text-align: left;
    margin-left: 25px;
    margin-top: 2px;
    font-size: 9px;
}

.menu-card {
    background-color: #ffffff;
    padding: 0.25em;
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 3px;
    display: flex;
}

.menu-card.card-w-title {
    padding-bottom: 1.25em;
}

.menu-card.card-w-heading {
    padding-bottom: 0.25em;
}

.menu-card h3 {
    margin: 0.25em 0.25em 0.25em 0.25em;
    padding: 0.05em;
    font-size: 14px;
    font-weight: bolder;
}

.report-card {
    background-color: #ffffff !important;
    padding: 0.25em;
    margin-bottom: 4px;
    margin-top: 4px;
    border-radius: 3px;
}

.report-card.report-card-content {
    height: 100% !important;
}

.report-criteria {
    background-color: #ffffff;
    padding: 0.1em;
    margin-bottom: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.report-criteria-1 {
    padding: 0.1em;
    margin-bottom: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.report-criteria-1 .report-criteria-section {
    margin: auto;
    width: 100%;
}

.report-criteria-title {
    background-color: #f8f8f8;
    border: 1px solid #D8D8D8;
    width: 100%;
    text-align: center;
}

.report-criteria-action {
    background-color: #f8f8f8;
    border: 1px solid #D8D8D8;
    width: 100%;
    height: 60px !important;
    text-align: center;
    padding-top: 8px;
}

.p-g {
    flex-wrap: wrap;
}

.parentMenu.p-tabmenu,
.parentMenu.p-tabmenu-nav {
    margin-top: -2px !important;
}

.parentMenu.p-tabmenu,
.parentMenu.p-tabmenu-nav,
.parentMenu.p-tabmenuitem {
    list-style: none;
    float: left;
    position: relative;
    white-space: nowrap;
    display: block;
    top: 0px;
    font-size: 12.5px;
}

.parentMenu .p-tabmenu .p-tabmenu-nav .p-tabmenuitem a {
    float: left;
    font-weight: bold;
    text-decoration: underline !important;
}

.childMenu.p-tabmenu,
.childMenu.p-tabmenu-nav {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
    padding-right: -10px !important;
    list-style: none;
    position: relative;
    white-space: nowrap;
    display: block;
    font-size: 12px;
}

.childMenu.p-tabmenu,
.childMenu.p-tabmenu-nav a {
    padding-left: -2px;
    padding-top: 3px;
}

.childMenu.p-tabmenu,
.childMenu.p-menuitem-text {
    vertical-align: middle;
}

.container {
    display: flex;
    height: 100vh;
}

.section_with_scroll {
    flex: 1;
    background: #aaa;
    overflow-y: scroll;
}

.p-tabmenu-item {
    background: #A8C8EE !important;
}

/* Dashboard */
.dashboard .summary {
    position: relative;
}

.dashboard .summary .title {
    font-size: 20px;
}

.dashboard .summary .detail {
    color: #000000;
    display: block;
    margin-top: 10px;
}

.dashboard .summary .count {
    color: #ffffff;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    padding: 7px 14px;
    border-radius: 3px;
}

.dashboard .summary .count.visitors {
    background-color: #20d077;
}

.dashboard .summary .count.purchases {
    background-color: #f9c851;
}

.dashboard .summary .count.revenue {
    background-color: var(--primary-color);
}

.dashboard .highlight-box {
    height: 100px;
}

.dashboard .highlight-box:after {
    content: "";
    display: table;
    clear: both;
}

.dashboard .highlight-box .initials {
    height: 100%;
    float: left;
    width: 50%;
    text-align: center;
    padding: 1em;
}

.dashboard .highlight-box .initials>span {
    font-size: 48px;
}

.dashboard .highlight-box .highlight-details {
    height: 100%;
    background-color: #ffffff;
    float: left;
    width: 50%;
    padding: 1em;
}

.dashboard .highlight-box .highlight-details i {
    font-size: 24px;
    vertical-align: middle;
    margin-right: 0.25em;
}

.dashboard .highlight-box .highlight-details .count {
    color: #000000;
    font-size: 36px;
    display: block;
}

.dashboard .task-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.dashboard .task-list li {
    padding: 0.5em 0.25em;
    border-bottom: 1px solid #e3e3e3;
}

.dashboard .task-list li:after {
    content: "";
    display: table;
    clear: both;
}

.dashboard .task-list .p-checkbox {
    vertical-align: middle;
    margin-right: 0.5em;
}

.dashboard .task-list .task-name {
    vertical-align: middle;
}

.dashboard .task-list i {
    float: right;
    font-size: 24px;
    color: #000000;
}

.dashboard .task-list .p-panel-content {
    min-height: 256px;
}

.dashboard .contact-form .p-panel-content {
    min-height: 256px;
}

.dashboard .contacts ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dashboard .contacts ul li {
    border-bottom: 1px solid #e3e3e3;
}

.dashboard .contacts ul li button {
    padding: 9px;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    position: relative;
    display: block;
    border-radius: 2px;
    transition: background-color 0.2s;
}

.dashboard .contacts ul li button .name {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 18px;
}

.dashboard .contacts ul li button .email {
    position: absolute;
    right: 10px;
    top: 30px;
    font-size: 14px;
    color: #000000;
}

.dashboard .contacts ul li button:hover {
    cursor: pointer;
    background-color: #eeeeee;
}

.dashboard .contacts ul li:last-child {
    border: 0;
}

.dashboard .contacts .p-panel-content {
    min-height: 256px;
}

.dashboard .activity-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dashboard .activity-list li {
    border-bottom: 1px solid #e3e3e3;
    padding: 16px 8px;
}

.dashboard .activity-list li .count {
    font-size: 24px;
    color: #ffffff;
    background-color: var(--primary-color);
    font-weight: 700;
    display: inline-block;
    padding: 0.25em 0.5em;
    border-radius: 3px;
}

.dashboard .activity-list li:first-child {
    border-top: 1px solid #e3e3e3;
}

.dashboard .activity-list li:last-child {
    border: 0;
}

.dashboard .activity-list li .p-g-6:first-child {
    font-size: 18px;
    padding-left: 0;
}

.dashboard .activity-list li .p-g-6:last-child {
    text-align: right;
    color: #000000;
}

.grid-header-style {

    font-size: 13px;
    font-weight: normal;
    color: #ffffff;
    background-color: var(--primary-color)
}

.grid-colomn-header-style {
    /* width               : (lColumn.width_ === '-1') ? this.state.mColumnWidth : lColumn.width_ ; */
    /* display             : lVisible; */
    text-align: center;
    border-right: .5px solid var(--primary-light);
    font-weight: normal;
    color: #ffffff;
    background-color: var(--primary-color);

}

.p-datatable.p-datatable-sm .p-datatable-thead>tr>th {
    padding: 0.5rem 0.5rem;
    text-align: center;
    border-right: .5px solid var(--primary-light);
    font-weight: normal;
    color: var(--clr-text);
    background-color: var(--primary-color) !important;
}

.text-primary {
    color: var(--clr-heading) !important;
}

.border-round {
    border-radius: 5px !important;
}

.border-1 {
    border: 1px solid var(--clr-text);
}

.Carouselimg {
    width: 80px;
    height: 120px;
    padding: 10px;
}

.p-sidebar-right .p-sidebar {
    width: 3rem !important;
    height: 100%;
    top: 58px;
    background-color: var(--primary-color);
}

.siedbar-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.p-sidebar-close p-sidebar-icon p-link {
    text-align: center !important;
}

.fc table {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 13px;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: 20px;
    min-width: 60px;
    position: relative;
}

.fc .fc-button-primary:disabled {
    background-color: var(--primary-color);
    border: none !important;
    color: var(--fc-button-text-color);
}

.fc .fc-button-primary {
    background-color: var(--primary-color);
    border: none !important;
    color: var(--clr-text);
}

.fc .fc-button-primary:focus {
    box-shadow: none;
}

.fc-direction-ltr {
    direction: ltr;
    text-align: left;
    padding: 200px;
}

.sidebar-icons .p-3 {
    padding: 3px !important;
}

.sidebar-icons {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.surface-ground {
    background-color: #f8f9fa;
}

.box-shadow {
    box-shadow: 0 0 0 1px var(--clr-text);
}

body .p-button.p-button-icon-only .p-button-text {
    padding: 0.429em;
    width: 32px;
    height: 32px;
}

.fa,
.fas {
    font-weight: 900;
}

.p-inputgroup .p-inputtext,
.p-fluid .p-inputgroup .p-inputtext,
.p-inputgroup .p-inputwrapper,
.p-fluid .p-inputgroup .p-input {
    flex: .5 !important;
    width: 1%;
}

body .p-component-overlay {
    background-color: rgba(0, 0, 0, .4);
}

div.p-dialog-mask {
    pointer-events: auto;
}

.float-right {
    float: right;
}

.layout-profile .pi {
    font-size: 50px !important;
}

.surface-border {
    border-color: var(--clr-text) !important;
}

#scroll-container {
    /* border-radius: 5px; */
    overflow: hidden;
}

#scroll-text {
    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);

    -moz-animation: my-animation 50s linear infinite;
    -webkit-animation: my-animation 50s linear infinite;
    animation: my-animation 50s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
    from {
        -moz-transform: translateX(100%);
    }

    to {
        -moz-transform: translateX(-100%);
    }
}

/* for Chrome */
@-webkit-keyframes my-animation {
    from {
        -webkit-transform: translateX(100%);
    }

    to {
        -webkit-transform: translateX(-100%);
    }
}

@keyframes my-animation {
    from {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }

    to {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

.bg-clr-text {
    background-color: var(--clr-text);
}

.cssidebar {
    height: 'calc(100% -8px)' !important;
}

.bg-primary-light {
    background-color: var(--primary-light);
}

.complianceCardData .p-dataview.p-dataview-list .p-dataview-content>.p-grid>div {
    border: 3px solid #dee2e6;
    border-width: 0px;
}

.right-1 {
    right: 15px;
}

.DashboardLayout2 {
    height: calc(100vh);
}

.border-bottom-px {
    border-bottom-width: 0.5px;
}

.borderpx {
    border-width: 1px solid #40e0d0 !important;
}

.inline-content {
    display: contents;
}

.-mt-9 {
    margin-top: -9rem;
}

.sidebarbutton:focus {
    background: white !important;
    border-color: var(--clr-text);
}

.p-rowgroup-header .p-icon {
    width: 9px;
    height: 9px;
}

.m-1 {
    width: 100%;
    align-items: center;
}

.af {
    position: relative;
    background-color: #FFF;
    min-height: 680px;
    max-width: 21cm;
    padding-left: 20px;
    padding-right: 20px;
}

.af_page {
    width: 210mm;
    min-height: 297mm;
    margin: 10mm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

}

.af_netfee-width {
    width: 117px;
}

.af subpage {
    padding: 0.1cm 0.5cm 0.5cm 0.5cm;
    height: 287mm;
    outline: 15px #dce0e0 solid;
}

.af col-10 {
    padding: 0px !important;
}

.af col-12 {
    padding: 3px !important;
}

.af col-7 {
    padding: 2px !important;
}

.af col-6 {
    padding: 2px !important;
}

.af col-5 {
    padding: 2px !important;
}

.af col-4 {
    padding: 2px !important;
}

.af col-3 {
    padding: 2px !important;
}

.af col-2 {
    padding: 2px !important;
}

.af w-35rem {
    width: 29rem;
}

.af w-40rem {
    width: 40rem;
}

.af w-20rem {
    width: 20rem;
}

.af table {
    border-collapse: collapse !important;
    width: 708px;
}

.af_pad {
    padding: 2px !important;
}

.af th {
    border: 0.2px solid #a6a6a6;
    text-align: center;
    padding: 4px;
    font-weight: 600;
    font-size: 9px;
}

.af td {
    padding: 8px !important;
    border: 0.2px solid #a6a6a6;
    text-align: left;
    padding: 4px;
    font-size: 9px;
}

.page .p-inputtext {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 12px !important;
    color: #4b5563;
    background: #ffffff;
    padding: 1px 6px 1px 6px !important;
    border: 1px solid #a6a6a6;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    appearance: none;
    border-radius: 3px;
    font-weight: 600;
}

.af li {
    padding-bottom: 5px;
}

.af w-39 {
    width: 41rem;
}

.af p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.2rem transparent !important;
}

.af .bdashed {
    border-bottom: dashed 1px !important;
}

.af w-35rem {
    width: 477px;
}

.af w-40rem {
    width: 40rem;
}

.table-btn-icon .p-treetable .p-treetable-tbody>tr>td.p-cell-editing .p-component {
    width: auto;
}

.cs-icon-calendar .p-icon {
    width: 20px;
    height: 20px;
}

.cs-icon-calendar .p-button {
    width: 80%;
    height: 80%;
}