.vnlogo {
    width: 200px;
    height: 100px;
}


.box {
    width: 28.8px;
    height: 36px;
    border: 1px solid rgb(223, 255, 209);
    text-align: center;
    border-radius: 5px;
}

.box_b {
    width: 28.8px;
    height: 36px;
    background: greenyellow;
    border: 1px solid black;
    text-align: center;
    border-radius: 5px;
}

.new {
    display: flex;
    font-size: 10px;
}

.B11C2 {
    margin-left: 430px;
    margin-top: 30px;
}

.B12C2 {
    margin-left: 430px;
}

.B21C1 {
    margin-top: 20px;
    margin-left: 150px;
}

.B21C2 {
    margin-top: 20px;
    margin-left: 29px;
}

.B22C1 {
    margin-left: 150px;
}

.B22C2 {
    margin-left: 30px;
}

.B31C1 {
    margin-top: 20px;
    margin-left: 150px;
}

.B31C2 {
    margin-top: 20px;
    margin-left: 30px;
}

.B32C1 {
    margin-left: 150px;
}

.B32C2 {
    margin-left: 30px;
}

.B41C1 {
    margin-top: 20px;
    margin-left: 60px;
}

.B41C2 {
    margin-top: 20px;
    margin-left: 198px;
}

.B41C3 {
    margin-top: 20px;
    margin-left: 30px;
}

.B42C1 {
    margin-left: 60px;
}

.B42C2 {
    margin-left: 198px;
}

.B42C3 {
    margin-left: 30px;
}

.B51C1 {
    margin-top: 20px;
    margin-left: 60px;
}

.B51C2 {
    margin-top: 20px;
    margin-left: 198px;
}

.B51C3 {
    margin-top: 20px;
    margin-left: 30px;
}

.B52C1 {
    margin-left: 60px;
}

.B52C2 {
    margin-left: 198px;
}

.B52C3 {
    margin-left: 30px;
}

.B61C1 {
    margin-top: 20px;
    margin-left: 60px;
}

.B61C2 {
    margin-top: 20px;
    margin-left: 33px;
}

.B61C3 {
    margin-top: 20px;
    margin-left: 26px;
}

.B62C1 {
    margin-left: 60px;
}

.B62C2 {
    margin-left: 33px;
}

.B62C3 {
    margin-left: 26px;
}

.B71C1 {
    margin-top: 20px;
    margin-left: 60px;
}

.B71C3 {
    margin-top: 20px;
    margin-left: 26px;
}

.b4 {
    width: 50px;
}

.dblock {
    width: 36px;
    height: 55px;
}

.d1block {
    width: 38px;
    height: 55px;
}

.F207 {
    width: 35.5px;
    height: 73px;
}

.F208 {
    width: 33.7px;
    height: 55px;
}

.E107 {
    width: 58px;
}

.E108 {
    width: 44px;
}

.E109 {
    width: 44px;
}

.E110 {
    width: 44px;
}

.E111 {
    width: 44px;
}

.E112 {
    width: 44px;
}

.E113 {
    width: 53px;
}

.space {
    width: 46px;
    height: 62px;
}

.first {
    display: flex;
}

.F225 {
    width: 48px;
    height: 55px;
}

.F226 {
    width: 37px;
    height: 35px;
}

.B31C3 {
    margin-left: 50px;
}

.default{
    background:#A8D8D8;
    color: #FFFFFF;
    font-weight: bold;
}

.available {
    background:linear-gradient(25deg, #59B300 2.9%, #72cf15 90.3%);
    color: #FFFFFF;
    font-weight: bold;
}

.soldout {
    background: linear-gradient(to right, #868f96 0%, #596164 100%);
    color: antiquewhite;
    font-weight: bold;
}

.mortgage {
    background:linear-gradient(0deg, rgb(255, 67, 5) 11.1%, rgb(245, 135, 0) 95.3%);
    color: antiquewhite;
    font-weight: bold;
}

.booked {
    background: linear-gradient( 0deg, rgb(243, 207, 6) 0%, rgb(245, 188, 65) 90%);
    color: black;
    font-weight: bold;
}

.legend {
    width: 60px;
    margin-left: 5px;
    text-align: center;
    position: relative;
}

.availability-report-name {
    text-align: center;
    font-size: 14px;
}